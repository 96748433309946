// organizationPaths
export function organization_path(orgId, extraPath = "") {
  return `/organizations/${orgId}${extraPath}`;
}

export function edit_organization_path(orgId, extraPath = "") {
  return `/organizations/${orgId}/edit${extraPath}`;
}

// Event paths
export function events_path() {
  return `/events`;
}

export function organization_event_path(orgId, eventId, extraPath = "") {
  return `/${orgId}/events/${eventId}${extraPath}`;
}

export function edit_organization_event_path(orgId, eventId, extraPath = "") {
  return `/${orgId}/events/${eventId}/edit${extraPath}`;
}

// phases
export function organization_event_phases_path(orgId, eventId, extraPath = "") {
  return `/${orgId}/events/${eventId}/phases${extraPath}`;
}

export function organization_event_phase_path(
  orgId,
  eventId,
  phaseId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/phases/${phaseId}${extraPath}`;
}

export function edit_organization_event_phase_path(
  orgId,
  eventId,
  phaseId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/phases/${phaseId}/edit${extraPath}`;
}

// ticket_types
export function organization_event_ticket_types_path(
  orgId,
  eventId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/ticket_types${extraPath}`;
}

export function edit_organization_event_ticket_type_path(
  orgId,
  eventId,
  ticketTypeId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/ticket_types/${ticketTypeId}/edit${extraPath}`;
}

// products
export function organization_event_products_path(
  orgId,
  eventId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/products${extraPath}`;
}

export function edit_organization_event_product_path(
  orgId,
  eventId,
  productId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/products/${productId}/edit${extraPath}`;
}

// coupons
export function organization_event_coupons_path(
  orgId,
  eventId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/coupons${extraPath}`;
}

export function edit_organization_event_coupon_path(
  orgId,
  eventId,
  couponId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/coupons/${couponId}/edit${extraPath}`;
}
export function new_organization_event_coupon_path(
  orgId,
  eventId
) {
  return `/${orgId}/events/${eventId}/coupons/new`;
}

// courtesies
export function organization_event_courtesy_ticket_types_path(
  orgId,
  eventId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/courtesy_ticket_types${extraPath}`;
}

export function edit_organization_event_courtesy_ticket_type_path(
  orgId,
  eventId,
  ticketTypeId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/ticket_types/${ticketTypeId}/edit${extraPath}`;
}


export function organization_event_courtesies_path(
  orgId,
  eventId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/courtesies${extraPath}`;
}

export function edit_organization_event_courtesy_path(
  orgId,
  eventId,
  couponId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/courtesies/${couponId}/edit${extraPath}`;
}
export function new_organization_event_courtesy_path(
  orgId,
  eventId
) {
  return `/${orgId}/events/${eventId}/courtesies/new`;
}

// batch_orders

export function organization_event_batch_orders_path(
  orgId,
  eventId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/batch_orders${extraPath}`;
}

export function edit_organization_batch_order_path(
  orgId,
  eventId,
  orderId,
  extraPath = ""
) {
  return `/${orgId}/events/${eventId}/batch_orders/${orderId}/edit${extraPath}`;
}
export function new_organization_event_batch_order_path(
  orgId,
  eventId
) {
  return `/${orgId}/events/${eventId}/batch_orders/new`;
}


export function activeLink(path, option = undefined) {
  const value = path.split("/")[4];
  if (value === option) return "activeLink";
}

export function forms_path() {
  return "/forms";
}

export function new_form_path() {
  return "/forms/new";
}

export function edit_form_path(formId) {
  return `/forms/${formId}/edit`;
}

export function order_path(orgId, eventId, id) {
  return `/${orgId}/${eventId}/orders/${id}`;
}


export function slider_items_path() {
  return '/slider_items';
}

export function edit_slider_item_path(id) {
  return `/slider_items/${id}/edit`;
}

export function new_slider_item_path() {
  return '/slider_items/new';
}


const eventSections = [
  { path: undefined, label: "Sales report" },
  { path:"checkin_report", label: "Check In Report" },
  { path:"edit", label: "General information" },
  { path:"Venue&Date", label: "Venue & Dates" },
  { path:"products", label: "Products & Services" },
  { path:"ticket_types", label: "Ticket types" },
  { path:"phases", label: "Phases" },
  { path:"courtesy_ticket_types", label: "Courtesy ticket types" },
  { path:"coupons", label: "Coupons" },
  { path:"batch_orders", label: "Tickets in batches" },
  { path:"check_staff", label: "Check in Staff" },
  { path:"Design", label: "Design" },
  { path:"pdf_settings", label: "PDF" },
]

export const getEventActiveSection = (currentPath) => {
  const value = currentPath.split("/")[4];
  const section = eventSections.find(section => section.path === value);
  return section;
}
