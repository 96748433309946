import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import PieChart from "../../components/PieChart";
import XYChart from "../../components/XYChart";
import RadarChart from "../../components/RadarChart";

import { withFirebase } from "../../components/Firebase";
import EventsContainer from "../../components/EventsContainer";
import { compose } from "recompose";
import { connect } from "react-redux";

import ordersService from "../../services/orders.service";
import TableEventReport from "../../containers/TableEventReport";

import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import eventsService from "../../services/events.service";
import DatePicker from "react-datepicker";
import "./salesreport.scss";
const Sugar = require("sugar");
am4core.useTheme(am4themes_animated);
const labels = [
  {
    type: "products",
    text: "Productos Adicionales",
  },
  {
    type: "tickets",
    text: "Tickets",
  },
];

const dateRanges = {
  today: {
    startsAt: Sugar.Date.beginningOfDay(new Date()),
    endsAt: Sugar.Date.endOfDay(new Date()),
  },
  week: {
    startsAt: Sugar.Date.beginningOfWeek(new Date()),
    endsAt: Sugar.Date.endOfWeek(new Date()),
  },
  month: {
    startsAt: Sugar.Date.beginningOfMonth(new Date()),
    endsAt: Sugar.Date.endOfMonth(new Date()),
  },
};
const SalesReport = ({ match }) => {
  const { organizationId, eventId } = match.params;
  const [totals, setTotals] = useState({});
  const [sales, setSales] = useState({});
  const [phases, setPhases] = useState({});
  const [products, setProducts] = useState({});
  const [total, setTotal] = useState(0);
  const [ticketsReport, setTicketsReport] = useState([]);
  const [productsReport, setProductsReport] = useState([]);
  const [radarChart, setRadarChart] = useState([]);
  const [currentDateFilter, setCurrentDateFilter] = useState("all");
  const [currentFilters, setCurrenFilters] = useState([]);
  const [startAt, setStartAt] = useState(dateRanges.week.startsAt);
  const [endAt, setEndAt] = useState(dateRanges.week.endsAt);
  const [activeFilter, setActiveFilter] = useState({});
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(activeFilter.accessStartsAt_between){
      const dates = activeFilter.accessStartsAt_between;
      const start = Date.create(dates[0], { setUTC: true });
      const finish = Date.create(dates[1], { setUTC: true });
      const filter = `q[accessStartsAt_between]=${Sugar.Date.format(start,
        "%Y-%m-%d %H:%M")}..${Sugar.Date.format(finish, "%Y-%m-%d %H:%M")}`
      getTicketsSalesReport([filter])
    }else{
      getTicketsSalesReport([])
    }
  }, [activeFilter]);

  const getTicketsSalesReport = (filters)=>{
    ordersService
    .ticketSalesReport({
      filters: [...filters, `q[eventId_eq]=${eventId}`, "q[orderStatus_eq]=PAID"],
    })
    .then(({ data: { ticketsReport, productsReport } }) => {
      setTicketsReport(ticketsReport);
      setProductsReport(productsReport);
      const data = ticketsReport.map((item) => ({
        capacity: item.phaseQuantity,
        soldCount: item.quantity,
        value: (item.quantity * 100) / item.phaseQuantity,
        category: item.name,
      }));
      setRadarChart(data);
    });
  }
  const getData = async () => {
    try {
      const filters = [`q[eventId_eq]=${eventId}`];
      const { sales, totals, phases, products } =
        await eventsService.chartsData({
          filters,
        });
      setTotal(totals.tickets.subtotal + totals.products.subtotal);
      setTotals(totals);
      setSales(sales);
      setPhases(phases);
      setProducts(products);
    } catch (error) {
      console.log(error);
    }
  };
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function changeCurrentDateFilter(dateFilter) {
    // console.log(dateFilter);
    setCurrentDateFilter(dateFilter);
    setCurrenFilters([]);
    if (["today", "week", "month"].includes(dateFilter)) {
      setStartAt(dateRanges[dateFilter].startsAt);
      setEndAt(dateRanges[dateFilter].endsAt);
      setActiveFilter({
        ...activeFilter,
        accessStartsAt_between: [
          dateRanges[dateFilter].startsAt,
          dateRanges[dateFilter].endsAt,
        ],
      });
    } else if (dateFilter === "all") {
      setActiveFilter({
        ...activeFilter,
        accessStartsAt_between: false,
      });
    }
  }

  function handleStartAtChange(date) {
    setStartAt(date);
    setActiveFilter({
      ...activeFilter,
      accessStartsAt_between: [date, endAt],
    });
  }
  function handleEndAtChange(date) {
    setEndAt(date);
    setActiveFilter({
      ...activeFilter,
      accessStartsAt_between: [startAt, date],
    });
  }

  return (
    <EventsContainer>
      <Row className="row-indicators d-md-flex">
        <Col xs="6" md="6" className="indicator section-title hideable-content">
          <h3>Sales</h3>
        </Col>
        <Col xs="12" md="2" className="indicator">
          <h3>{formatter.format(total)}</h3>
          <small>Sales</small>
        </Col>
        <Col xs="6" md="2" className="indicator">
          <h3>
            {formatter.format(totals.tickets ? totals.tickets.subtotal : 0)}
          </h3>
          <small>Tickets</small>
        </Col>
        <Col xs="6" md="2" className="text-right indicator">
          <h3>
            {formatter.format(totals.products ? totals.products.subtotal : 0)}
          </h3>
          <small>Products</small>
        </Col>
      </Row>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="chartsTop" style={{ display: "flex" }}>
          <XYChart data={sales} labels={labels} events={true} />
        </div>
        <div className="display-in-columns" style={{ display: "flex" }}>
          <div className="full-width" style={{ width: "50%" }}>
            <p className="graph-title">Avance de venta(Cantidad)</p>
            <RadarChart data={radarChart} />
          </div>
          <div className="full-width" style={{ width: "50%" }}>
            <p className="graph-title">Venta por Tipo de Boleto (Cantidad)</p>
            <PieChart
              id="pie-chart"
              className="full-width"
              data={phases}
              events={true}
              name="quantityChart"
              dataField="quantity"
            />
          </div>
        </div>
        <div className="display-in-columns" style={{ display: "flex" }}>
          <div className="full-width" style={{ width: "50%" }}>
            <p className="graph-title">Ingresos por tipo de Boleto (Dinero)</p>
            <PieChart
              className="full-width"
              data={phases}
              name="earnsChart"
              events={true}
              dataField="subtotal"
            />
          </div>
          <div className="full-width" style={{ width: "50%" }}>
            <p className="graph-title">Ingreso Por Productos (Dinero)</p>
            <PieChart
              className="full-width"
              data={products}
              name="earnsChart2"
              events={true}
              dataField="subtotal"
            />
          </div>
        </div>
        <Breadcrumb className="filter_event_view">
          <li className="date-filters pull-left">
            <button
              onClick={() => changeCurrentDateFilter("today")}
              className={`btn btn-filter ${
                currentDateFilter === "today" ? "active-filter" : ""
              }`}
            >
              Today
            </button>
            <button
              onClick={() => changeCurrentDateFilter("week")}
              className={`btn btn-filter ${
                currentDateFilter === "week" ? "active-filter" : ""
              }`}
            >
              Week
            </button>
            <button
              onClick={() => changeCurrentDateFilter("month")}
              className={`btn btn-filter ${
                currentDateFilter === "month" ? "active-filter" : ""
              }`}
            >
              Month
            </button>
            <button
              onClick={() => changeCurrentDateFilter("all")}
              className={`btn btn-filter ${
                currentDateFilter === "all" ? "active-filter" : ""
              }`}
            >
              All
            </button>
            <button
              onClick={() => changeCurrentDateFilter("range")}
              className={`btn btn-filter ${
                currentDateFilter === "range" ? "active-filter" : ""
              }`}
            >
              Range
            </button>
          </li>
          <li
            className={`orders-date-range ${
              currentDateFilter === "range" ? "" : "hidden"
            }`}
          >
            <DatePicker
              selected={startAt}
              onChange={handleStartAtChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd H:mm"
              popperPlacement="top-start"
              timeCaption="Time"
              showMonthDropdown
            />
            <DatePicker
              selected={endAt}
              onChange={handleEndAtChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd H:mm"
              timeCaption="Time"
              popperPlacement="top-end"
              showMonthDropdown
            />
          </li>
        </Breadcrumb>
        <TableEventReport
          ticketsReport={ticketsReport}
          productsReport={productsReport}
        />
      </div>
    </EventsContainer>
  );
};

export default compose(
  withFirebase,
  connect((state) => ({ currentUser: state.currentUser }))
)(SalesReport);
