import React from "react";
import { Link } from "react-router-dom";
import generatePassword from "password-generator";
import { Row, Col, Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import "./form.scss";
import UserRoles from "../../components/UserRoles";
import { withFirebase } from "../../components/Firebase";
import { getEventRefs } from "../../helpers/roles";
import "moment-timezone";
import axios from "axios";
import { compose } from "recompose";
import permissions from "../../helpers/permissions";
import { environment } from "../../components/Firebase/config";
import { Formik, Form } from "formik";
import * as yup from "yup";

import {
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  FormText,
  CheckBox,
  TextField
} from "../../components/Formica";

class UserForm extends React.Component {
  state = {
    isLoading: false,
    error: "",
    creating: false,
    userdata: "",
    automaticPassword: true,
    showPassword: true,
    id: "",
    permission: "Admin",
    count: 1,
    validationSchema: yup.object({
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      email: yup
        .string()
        .email()
        .required(),
      password: yup.string().required(),
      isAdmin: yup.boolean(),
      roles: yup
        .array()
        .min(1)
        .when("isAdmin", {
          is: false,
          otherwise: s => s.min(0)
        })
    })
  };

  // showPassword indicates if password will be shown on input
  showPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  // activeGeneratePassword generates a random password and set it on state
  activateGeneratePassword = props => {
    this.setState({
      automaticPassword: true
    });
    props.setFieldValue("password", generatePassword(8, false, /[\w\d\?\-]/));
  };
  // activeCustomPassword reset password and set automaticPassword boolean to false
  activateCustomPassword = props => {
    this.setState({ automaticPassword: false });
    props.setFieldValue("password", "");
  };
  // this function handles changes on inputs and set values on state, state name taken from input name
  handleUserInput = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCheckbox = e => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  handleSetRoles = (data, props) => {
    if (props.values.roles.length === 0) {
      props.setFieldValue("roles", [data]);
    } else {
      const roles = props.values.roles.map(role => {
        return role.id === data.id ? data : role;
      });
      props.values.roles.length - 1 < data.id && roles.push(data);
      props.setFieldValue("roles", roles);
    }
  };
  // registerNewUser registers a new user on user pool
  registerNewUser = async values => {
    this.setState({ isLoading: true });
    const { email, firstname, lastname, password, isAdmin, roles } = values;

    const payload = {
      firstName: firstname,
      lastName: lastname,
      email,
      password,
      isAdmin,
      roles: isAdmin ? [] : getEventRefs(roles)
    };

    axios
      .post(
        `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/app/users`,
        payload
      )
      .then(response => {
        console.log(response);
        this.props.history.push("/users");
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  handleAddNewRole = () => {
    this.setState(prevState => ({
      count: prevState.count + 1
    }));
  };
  handleRemoveRole = () => {
    this.setState(prevState => ({
      count: prevState.count - 1
    }));
  };

  render() {
    return (
      <Formik
        validationSchema={this.state.validationSchema}
        validateOnChange
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          roles: [],
          isAdmin: false,
          password: generatePassword(10, false, /[\w\d\?\-]/)
        }}
        onSubmit={values => this.registerNewUser(values)}
      >
        {props => {
          const children = [];
          for (let i = 0; i < this.state.count; i++) {
            children.push(
              <UserRoles
                key={i}
                id={i}
                setRoles={data => this.handleSetRoles(data, props)}
                disabled={props.values.isAdmin}
              />
            );
          }
          return (
            <React.Fragment>
              <div className="section-header">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/users">Users</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>New</BreadcrumbItem>
                </Breadcrumb>
              </div>

              <Form className="form-horizontal form-compact">
                <FormFields>
                  <Row>
                    <Col md="4">
                      <TextField label="First name" name="firstname" />
                    </Col>
                    <Col md="4">
                      <TextField label="Last name" name="lastname" />
                    </Col>
                    <Col md="4">
                      <TextField label="Email" name="email" />
                    </Col>
                  </Row>

                  <FormGroup className="permissions-row">
                    <div className="add-buttons">
                      <Button
                        className="btn"
                        type="button"
                        onClick={this.handleAddNewRole}
                        disabled={props.values.isAdmin}
                      >
                        +
                      </Button>
                      <Button
                        className="btn"
                        type="button"
                        onClick={this.handleRemoveRole}
                        disabled={props.values.isAdmin}
                      >
                        -
                      </Button>
                    </div>

                    {children}
                  </FormGroup>

                  <FormText id="rolesError">
                    {props.submitCount > 0 && props.errors.roles}
                  </FormText>

                  {permissions.adminPresent() && (
                    <React.Fragment>
                      <br />
                      <CheckBox
                        name="isAdmin"
                        onChange={e => {
                          props.setFieldValue("isAdmin", e.target.checked);
                        }}
                        checked={props.values.isAdmin}
                        label="Admin (As an admin, user can access all sections of theplatform)"
                      />
                    </React.Fragment>
                  )}

                  <FormGroup>
                    <Label className="control-label">User password</Label>
                    <div className="row-as-table">
                      <div className="random-pass-option">
                        <Label className="custom-control custom-radio">
                          <input
                            id="radio1"
                            name="radio"
                            type="radio"
                            checked={this.state.automaticPassword}
                            onChange={() =>
                              this.activateGeneratePassword(props)
                            }
                            className="custom-control-input"
                          />
                          <span className="custom-control-label">Random</span>
                        </Label>
                      </div>

                      <div className="custom-pass-option">
                        <Label className="custom-control custom-radio">
                          <Input
                            id="radio2"
                            name="radio"
                            type="radio"
                            checked={!this.state.automaticPassword}
                            onChange={() => this.activateCustomPassword(props)}
                            className="custom-control-input"
                          />

                          <span className="custom-control-label">
                            Personalized password
                          </span>
                        </Label>
                      </div>

                      <div className="pass-field">
                        <TextField name="password" />
                      </div>
                    </div>
                  </FormGroup>
                </FormFields>

                <FormActions
                  cancelPath="/users"
                  submitCaption="Save"
                  loading={this.state.isLoading}
                />
              </Form>
            </React.Fragment>
          );
        }}
      </Formik>
    );
  }
}

// export default connect(
//   state => ({ title: state.title }),
//   dispatch => bindActionCreators({ setTitle }, dispatch)
// )(UserForm);
export default compose(
  withFirebase,
  connect(state => ({
    currentUser: state.currentUser
  }))
)(UserForm);
