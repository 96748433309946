import React from "react";
import { Link } from "react-router-dom";

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge
} from "reactstrap";

import dotsIcon from "../../images/icons/dots.svg";
import permissions from "../../helpers/permissions";
import { edit_organization_event_courtesy_path } from "../../helpers/paths";

export default function Courtesy(props) {
  const { record, organizationId, eventId } = props;
  const {
    id,
    CourtesyTicketType,
    name,
    parentId,
    code,
    redeemedTickets,
    onHold,
    quantity,
    status
  } = record;

  return (
    <tr role="row" className="courtesy-row">
      <td role="cell">{CourtesyTicketType.name}</td>
      <td role="cell">
        {name} {parentId && <Badge color="secondary">from batch</Badge>}
      </td>
      <td className="text-danger coupon-code">{code}</td>
      <td role="cell">
        {redeemedTickets + onHold} / {quantity}
      </td>
      <td role="cell">{status}</td>

      <td role="cell">{name}</td>

      <td className="text-right full-width">
        <UncontrolledDropdown direction="left" className="row-options-trigger">
          <DropdownToggle tag="span">
            <img src={dotsIcon} alt="Options" />
          </DropdownToggle>
          <DropdownMenu>
            {permissions.can("courtesies", "edit") && (
              <DropdownItem
                tag={Link}
                to={edit_organization_event_courtesy_path(
                  organizationId,
                  eventId,
                  id
                )}
              >
                Edit
              </DropdownItem>
            )}

            <DropdownItem divider />

            {permissions.can("ticket_types", "delete") && (
              <DropdownItem
                className="text-danger"
                onClick={() =>
                  props.confirmDestroy(organizationId, eventId, id)
                }
              >
                Destroy
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}
