import React, { useState, useEffect } from "react";
import { centsToDecimal, decimalToCents } from "../../helpers/currency";

import { withRouter } from "react-router-dom";
import { withFirebase } from "../../components/Firebase";

import { CustomInput, Col, Row } from "reactstrap";

import EventsContainer from "../../components/EventsContainer";

import {
  organization_event_phases_path,
  organization_event_path,
} from "../../helpers/paths";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";

import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  FormFeedback,
  SelectFromCollection,
  SelectFromArray,
  DropzoneUploader,
  MarkdownInput,
  DateTimeInput,
  CurrencyInput,
} from "../../components/Formica";

import { useFormik } from "formik";
import * as yup from "yup";

import "./index.scss";

const validationSchema = yup.object({
  name: yup.string().required().label("Name"),
  ticketTypeId: yup.string().required().label("Ticket type"),
  quantity: yup.number().required().min(1).label("Quantity"),
  startsAt: yup.date().required().label("Start At"),
  endsAt: yup
    .date()
    .when("startsAt", (startsAt, schema) => {
      const validStartsAt = startsAt instanceof Date && !isNaN(startsAt);
      if (!validStartsAt) return;
      return startsAt && schema.min(startsAt);
    })
    .label("End At")
    .required(),
  accessStartsAt: yup.date().label("Access starts At"),
  accessEndsAt: yup
    .date()
    .when("accessStartsAt", (accessStartsAt, schema) => {
      const validAccessStartsAt =
        accessStartsAt instanceof Date && !isNaN(accessStartsAt);
      if (!validAccessStartsAt) return;
      return accessStartsAt && schema.required();
    })
    .label("Access ends at"),
});

const initialValues = {
  name: "",
  description: "",
  quantity: 0,
  soldCount: 0,
  fee: 0,
  boxOfficeFee: 0,
  price: 0,
  boxOfficePrice: 0,
  ticketTypeId: "",
  eventId: "",
  status: "ACTIVE",
  ticketHeaderImage: "",
  event: {},
  eventName: "",
  sortOrder: 0,
  multipleCheckIn: false,
  extraService: false,
  boxOffice: false
};

function PhaseForm(props) {
  const { organizationId, eventId, phaseId } = props.match.params;
  const [ticketTypes, setTicketTypes] = useState();
  const [currentUserId, setCurrentUserId] = useState(props.uid);

  const getTicketTypes = async () => {
    const ticketTypesCollection = await props.firebase
      .ticketTypes(organizationId, eventId)
      .get();

    setTicketTypes(
      ticketTypesCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  const handleFormatData = (values) => {
    let data = {
      name: values.name,
      ticketTypeId: values.ticketTypeId,
      description: values.description,
      startsAt: values.startsAt,
      endsAt: values.endsAt,
      ticketHeaderImage: values.ticketHeaderImage,
      quantity: values.quantity,
      price: decimalToCents(Number(values.price)),
      fee: decimalToCents(Number(values.fee)),
      boxOfficePrice: decimalToCents(Number(values.boxOfficePrice)),
      boxOfficeFee: decimalToCents(Number(values.boxOfficeFee)),
      status: values.status,
      multipleCheckIn: values.multipleCheckIn,
      extraService: values.extraService,
      boxOffice: values.boxOffice
    };
    if (values.accessStartsAt && values.accessEndsAt) {
      data.accessStartsAt = values.accessStartsAt;
      data.accessEndsAt = values.accessEndsAt;
    }
    return data;
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const now = new Date();
      let phase = handleFormatData(values);
      if (phaseId) {
        phase.updatedAt = now;
        console.log(phase, "PARSED VALUES");
        await props.firebase.updatePhase(
          organizationId,
          eventId,
          phaseId,
          phase
        );
      } else {
        phase.createdAt = now;
        phase.updatedAt = now;
        phase.authorId = currentUserId;
        phase.eventId = eventId;
        phase.organizationId = organizationId;
        phase.soldCount = 0;
        phase.onHold = 0;
        phase.sortOrder = 0;

        await props.firebase.createPhase(organizationId, eventId, phase);
      }
      props.history.push(
        organization_event_path(organizationId, eventId, "/phases")
      );
    },
    validationSchema,
  });

  useEffect(() => {
    getTicketTypes();
    if (phaseId) getPhase();
  }, []);

  const getPhase = async () => {
    const now = new Date();

    const phase = await props.firebase.db
      .doc(
        `/organizations/${organizationId}/events/${eventId}/phases/${phaseId}`
      )
      .get();

    const {
      name,
      description,
      startsAt,
      endsAt,
      ticketHeaderImage,
      quantity,
      price,
      fee,
      boxOfficePrice,
      boxOfficeFee,
      ticketTypeId,
      status,
      createdAt,
      updatedAt,
      authorId,
      multipleCheckIn,
      accessStartsAt,
      accessEndsAt,
      extraService,
      boxOffice
    } = phase.data();

    setValues({
      name,
      description,
      startsAt: startsAt.toDate(),
      endsAt: endsAt.toDate(),
      accessStartsAt: accessStartsAt && accessStartsAt.toDate(),
      accessEndsAt: accessEndsAt && accessEndsAt.toDate(),
      ticketHeaderImage,
      quantity,
      price: centsToDecimal(price),
      fee: centsToDecimal(fee),
      boxOfficePrice: centsToDecimal(boxOfficePrice),
      boxOfficeFee: centsToDecimal(boxOfficeFee),
      ticketTypeId,
      status,
      createdAt: createdAt || now,
      updatedAt: updatedAt || now,
      authorId: authorId || currentUserId,
      multipleCheckIn: multipleCheckIn || false,
      extraService: extraService || false,
      boxOffice: boxOffice || false
    });
  };

  // const (event, maskedVal, floatVal) => setFieldValue("", floatVal) = (event, maskedvalue, floatvalue) => {
  //   const e = {
  //     target: {
  //       name: event.target.name,
  //       value: floatvalue
  //     }
  //   };
  //   handleChange(e);
  // };
  return (
    <EventsContainer>
      <Form className="form-horizontal form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="ticketTypeId">Ticket Type</Label>
                <SelectFromCollection
                  name="ticketTypeId"
                  value={values.ticketTypeId}
                  onChange={handleChange}
                  placeholder="Form"
                  collection={ticketTypes}
                  optionLabel="name"
                  optionValue="id"
                  invalid={errors.ticketTypeId && true}
                  includeBlank
                />
                <FormFeedback>{errors.ticketTypeId}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="phaseName">Name</Label>
                <Input
                  type="text"
                  name="name"
                  value={values.name}
                  className="form-control"
                  onChange={handleChange}
                  invalid={errors.name && true}
                  onBlur={handleBlur}
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="eventDescription">Description</Label>
            <MarkdownInput
              value={values.description}
              onChange={(value) => {
                const e = {
                  target: {
                    name: "description",
                    value,
                  },
                };
                handleChange(e);
              }}
            />
          </FormGroup>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="phaseStartsAt">Start At</Label>
                <br />

                <DateTimeInput
                  selected={values.startsAt}
                  onChange={(date) => setFieldValue("startsAt", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  invalid={errors.startsAt && true}
                />
                <FormFeedback>{errors.startsAt}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="phaseEndAt">End At</Label>
                <br />
                <DateTimeInput
                  className="datetime-picker-input"
                  selected={values.endsAt}
                  onChange={(date) => setFieldValue("endsAt", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  invalid={errors.endsAt && true}
                />
                <FormFeedback>{errors.endsAt}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="phaseStartsAt">Access start At</Label>
                <br />

                <DateTimeInput
                  selected={values.accessStartsAt}
                  onChange={(date) => setFieldValue("accessStartsAt", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  invalid={errors.accessStartsAt && true}
                />
                <FormFeedback>{errors.accessStartsAt}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="phaseEndAt">Access ends at</Label>
                <br />
                <DateTimeInput
                  className="datetime-picker-input"
                  selected={values.accessEndsAt}
                  onChange={(date) => setFieldValue("accessEndsAt", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  invalid={errors.accessEndsAt && true}
                />
                <FormFeedback>{errors.accessEndsAt}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label className="label-block" for="eventheaderImage">
              Ticket header Image
            </Label>

            <DropzoneUploader
              path="phases/ticketHeaderImage/"
              name="ticketHeaderImage"
              onChange={(url) => setFieldValue("ticketHeaderImage", url)}
              image={values.ticketHeaderImage}
              legend="Recomended size|334x148"
              className="ticket-header-uploader"
              previewImage={values.ticketHeaderImage}
            />
          </FormGroup>

          <Row className="form-section-header">
            <Col md="4" className="px-0">
            <FormGroup>
            <CustomInput
              type="switch"
              checked={values.multipleCheckIn}
              id="multipleCheckIn"
              name="multipleCheckIn"
              label="Multiple Check In"
              onClick={() => {
                setFieldValue("multipleCheckIn", !values.multipleCheckIn);
              }}
            />
          </FormGroup>
            </Col>
            <Col md="4">
            <FormGroup>
            <CustomInput
              type="switch"
              checked={values.extraService}
              id="extraService"
              name="extraService"
              label="Extra service"
              onClick={() => {
                setFieldValue("extraService", !values.extraService);
              }}
            />
          </FormGroup>
            </Col>
            <Col md="4">
            <FormGroup>
            <CustomInput
              type="switch"
              checked={values.boxOffice}
              id="boxOffice"
              name="boxOffice"
              label="BoxOffice"
              onClick={() => {
                setFieldValue("boxOffice", !values.boxOffice);
              }}
            />
          </FormGroup>
            </Col>
          </Row>
          <Row className="form-section-header">
            <Col md="9" className="px-0">
              <h5>Availability & Prices</h5>
            </Col>
            <Col md="3">
              <FormGroup>
                <SelectFromArray
                  onChange={handleChange}
                  name="status"
                  value={values.status}
                  includeBlank="Select a status"
                  collection={["ACTIVE", "INACTIVE", "COMING_SOON"]}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="phaseQuantity">Quantity</Label>
                <Input
                  type="number"
                  name="quantity"
                  value={values.quantity}
                  onChange={handleChange}
                  id="phaseQuantity"
                  onBlur={handleBlur}
                  invalid={errors.quantity && true}
                />
                <FormFeedback>{errors.quantity}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Tickets sold</Label>
                <Input readOnly value={values.soldCount} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label for="phasePrice">Price</Label>
                <CurrencyInput
                  value={values.price}
                  name="price"
                  className="form-control"
                  prefix="$"
                  autoFocus={true}
                  onChangeEvent={(event, maskedVal, floatVal) =>
                    setFieldValue("price", floatVal)
                  }
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="phaseFee">Fee</Label>
                <CurrencyInput
                  value={values.fee}
                  name="fee"
                  className="form-control"
                  prefix="$"
                  autoFocus={true}
                  onChangeEvent={(event, maskedVal, floatVal) =>
                    setFieldValue("fee", floatVal)
                  }
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="phaseBoxOfficePrice">Boxoffice Price</Label>
                <CurrencyInput
                  value={values.boxOfficePrice}
                  name="boxOfficePrice"
                  className="form-control"
                  prefix="$"
                  autoFocus={true}
                  onChangeEvent={(event, maskedVal, floatVal) =>
                    setFieldValue("boxOfficePrice", floatVal)
                  }
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="FeeBoxOffice">Boxoffice Fee</Label>
                <CurrencyInput
                  value={values.boxOfficeFee}
                  name="boxOfficeFee"
                  className="form-control"
                  prefix="$"
                  autoFocus={true}
                  onChangeEvent={(event, maskedVal, floatVal) =>
                    setFieldValue("boxOfficeFee", floatVal)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </FormFields>
        <FormActions
          cancelPath={organization_event_phases_path(organizationId, eventId)}
          submitCaption="Save"
        />
      </Form>
    </EventsContainer>
  );
}
export default compose(
  withRouter,
  withFirebase,
  connect(
    (state) => ({ uid: state.currentUser.uid }),
    (dispatch) => bindActionCreators({}, dispatch)
  )
)(PhaseForm);
