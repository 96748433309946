import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import { compose } from "recompose";

import SliderItem from "./_slider_item";

import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";

import { new_slider_item_path } from "../../helpers/paths";

import { Table } from "reactstrap";

import "./index.scss";
import TableHead from "../../components/Admin/TableHead";

function SliderItems(props) {
  const { firebase } = props;
  const [sliderItems, setSliderItems] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [alert, setAlert] = useState(null);

  const getSliderItems = async () => {
    const sliderItemsCollection = await firebase
      .sliderItemsCollection()
      .orderBy("sortOrder", "asc")
      .get()
    setSliderItems(
      sliderItemsCollection.docs.map( doc => ({ id: doc.id, ...doc.data() }))
    )
  }

  useEffect(() => {
    getSliderItems();
  }, [])

  const destroy = async (id) => {
    await firebase.deleteSliderItem(id);
    setSliderItems(sliderItems.filter(sliderItem => sliderItem.id !== id))
    setAlert(null);
  };

  const confirmDestroy = (id) => {
    setAlert(
      <DestroyConfirmation
        onConfirm={() => destroy(id)}
        onCancel={() => setAlert(null)}
      />
    );
  };

  return (
    <React.Fragment>
      {alert}

      <div className="section-header">
        <div className="text-right table-actions pull-right">
          <Link to={new_slider_item_path()} className="btn btn-primary">
            Create slider item
          </Link>
        </div>

        <h5 className="section-title">Slider items</h5>
      </div>

      <Table className="responsive" role="table">
        <TableHead cols={["Image", "Content", "link", ""]} />
        <tbody>
          {sliderItems.map(record => (
            <SliderItem
              record={record}
              key={record.id}
              confirmDestroy={confirmDestroy}
            />
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(SliderItems);
