import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "firebase/firebase-firestore";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../../../components/Firebase";

import organizationsService from "../../../services/organizations.service";
import depositsService from "../../../services/deposits.service";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Form,
  FormGroup,
  Input,
  Col,
  Row,
  Table,
  Breadcrumb,
  BreadcrumbItem,
  Button
} from "reactstrap";

import TableHead from "../../../components/Admin/TableHead";
import Deposit from "./_deposit";
import "./styles.scss";
import TableLoadingRow from "../../../components/Admin/TableLoadingRow";

const Sugar = require("sugar");

const dateRanges = {
  today: {
    startsAt: Sugar.Date.beginningOfDay(new Date()),
    endsAt: Sugar.Date.endOfDay(new Date())
  },
  week: {
    startsAt: Sugar.Date.beginningOfWeek(new Date()),
    endsAt: Sugar.Date.endOfWeek(new Date())
  },
  month: {
    startsAt: Sugar.Date.beginningOfMonth(new Date()),
    endsAt: Sugar.Date.endOfMonth(new Date())
  }
};

const fileDownload = require("js-file-download");

function Deposits(props) {
  const [deposits, setDeposits] = useState([]);

  const [downloadButtonActive, setDownloadButtonActive] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(true);

  // Pagination
  const [queryLimit, setQueryLimit] = useState(50);
  const [pageSize, setPageSize] = useState([0]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [activeFilter, setActiveFilter] = useState({});
  const [currentDateFilter, setCurrentDateFilter] = useState("all");
  const [startAt, setStartAt] = useState(dateRanges.week.startsAt);
  const [endAt, setEndAt] = useState(dateRanges.week.endsAt);

  const [myOrgIds, setMyOrgIds] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const [typingTimeout, setTypingTimeout] = useState(false);
  const [currentFilters, setCurrenFilters] = useState([]);

  useEffect(() => {
    let filter = [];
    if (activeFilter.organizationId) {
      filter.push(`q[organizationId_eq]=${activeFilter.organizationId}`);
    }

    if (activeFilter.status) {
      filter.push(`q[status_eq]=${activeFilter.status}`);
    }

    if (activeFilter.findByTerm) {
      filter.push(`q[str_cont]=${activeFilter.findByTerm}`);
    }

    if (activeFilter.createdAt_between) {
      const dates = activeFilter.createdAt_between;
      filter.push(
        `q[createdAt_between]=${Sugar.Date.format(
          dates[0],
          "%Y-%m-%d %H:%M"
        )}..${Sugar.Date.format(dates[1], "%Y-%m-%d %H:%M")}`
      );
    }

    setCurrenFilters(filter);
    loadDeposits(currentPage, filter);
  }, [activeFilter]);

  function changeCurrentDateFilter(dateFilter) {
    setCurrentDateFilter(dateFilter);
    setCurrenFilters([]);
    if (["today", "week", "month"].includes(dateFilter)) {
      setStartAt(dateRanges[dateFilter].startsAt);
      setEndAt(dateRanges[dateFilter].endsAt);
      setActiveFilter({
        ...activeFilter,
        createdAt_between: [
          dateRanges[dateFilter].startsAt,
          dateRanges[dateFilter].endsAt
        ]
      });
    } else if (dateFilter === "all") {
      setActiveFilter({
        ...activeFilter,
        createdAt_between: false
      });
    }
  }

  async function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
    setLoading(true);
    await loadDeposits(pageNumber, currentFilters);
  }

  function searchTermChange(value) {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function() {
        setActiveFilter({
          ...activeFilter,
          findByTerm: value
        });
      }, 300)
    );
  }

  function handleStartAtChange(date) {
    setStartAt(date);
    setActiveFilter({
      ...activeFilter,
      createdAt_between: [date, endAt]
    });
  }
  function handleEndAtChange(date) {
    setEndAt(date);
    setActiveFilter({
      ...activeFilter,
      createdAt_between: [startAt, date]
    });
  }

  const loadOrganizations = async () => {
    const userDoc = await props.firebase.getUserData();
    const { isAdmin } = userDoc.data();
    organizationsService.all().then(res => {
      if (isAdmin) {
        setOrganizations(res);
      } else {
        setOrganizations(res.filter(org => myOrgIds.includes(org.id)));
      }
    });
  };

  function loadDeposits(page, filters) {
    setLoading(true);

    let offset = page === 1 ? 0 : (page - 1) * queryLimit;

    depositsService
      .all({ limit: queryLimit, offset: offset, filters })
      .then(({ deposits, recordsCount, pageSize }) => {
        setRecordsCount(recordsCount);
        setPageSize(pageSize);
        setDeposits(deposits);
        setLoading(false);
      });
  }

  const getAssociatedOrgIds = async () => {
    const myEventsCollection = await props.firebase
      .myEvents(props.firebase.auth.uid)
      .get();
    if (!myEventsCollection.empty) {
      let orgIds = [];
      myEventsCollection.docs.forEach(event => {
        orgIds.push(event.data().organizationId);
      });
      orgIds = [...new Set(orgIds)];
      setMyOrgIds(orgIds);
    }
  };

  useEffect(() => {
    getAssociatedOrgIds();
    loadOrganizations();
  }, []);

  async function downloadReport() {
    setDownloading(true);
    const depositsCSV = await depositsService.toCSV({ filters: currentFilters });
    fileDownload(depositsCSV.data, `deposits_${new Date().toLocaleString()}.csv`);
    setDownloading(false);
  }

  return (
    <React.Fragment>
      <div className="section-header">
        <div className="text-right table-actions pull-right top-search">
          <Link
            to="/deposits/new/"
            className="btn_add_deposit btn btn-secondary"
          >
            Add Deposit
          </Link>{" "}
          <Button
            color="primary"
            onClick={downloadReport}
          >
            {downloading ? "Downloading..." : "Export CSV"}
          </Button>
        </div>

        <Breadcrumb>
          <BreadcrumbItem active>Deposits</BreadcrumbItem>
          <li className="date-filters pull-left">
            <button
              onClick={() => changeCurrentDateFilter("today")}
              className={`btn btn-filter ${
                currentDateFilter === "today" ? "active-filter" : ""
              }`}
            >
              Today
            </button>
            <button
              onClick={() => changeCurrentDateFilter("week")}
              className={`btn btn-filter ${
                currentDateFilter === "week" ? "active-filter" : ""
              }`}
            >
              Week
            </button>
            <button
              onClick={() => changeCurrentDateFilter("month")}
              className={`btn btn-filter ${
                currentDateFilter === "month" ? "active-filter" : ""
              }`}
            >
              Month
            </button>
            <button
              onClick={() => changeCurrentDateFilter("all")}
              className={`btn btn-filter ${
                currentDateFilter === "all" ? "active-filter" : ""
              }`}
            >
              All
            </button>
            <button
              onClick={() => changeCurrentDateFilter("range")}
              className={`btn btn-filter ${
                currentDateFilter === "range" ? "active-filter" : ""
              }`}
            >
              Range
            </button>
          </li>
          <li
            className={`orders-date-range ${
              currentDateFilter === "range" ? "" : "hidden"
            }`}
          >
            <DatePicker
              selected={startAt}
              onChange={handleStartAtChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd H:mm"
              popperPlacement="top-start"
              timeCaption="Time"
              showMonthDropdown
            />
            <DatePicker
              selected={endAt}
              onChange={handleEndAtChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd H:mm"
              timeCaption="Time"
              popperPlacement="top-end"
              showMonthDropdown
            />
          </li>
        </Breadcrumb>

        <div className="text-right table-actions pull-right">
          <Form inline>

            <FormGroup className="down-search xs-full-width">
              <Link
                to="/deposits/new/"
                className="btn_add_deposit btn btn-secondary"
              >
                Add Deposit
              </Link>{" "}
              <Button
                color="primary"
                onClick={downloadReport}
              >
                {downloading ? "Downloading..." : "Export CSV"}
              </Button>
            </FormGroup>

            <FormGroup className="mb-2 mr-sm-2 mb-sm-0 xs-full-width">
              <Input
                type="text"
                placeholder="search"
                id="search-term"
                onChange={e => searchTermChange(e.target.value)}
              />
            </FormGroup>
          </Form>
        </div>
      </div>

      <Row form className="full-width">
        <Col sm="12" md="2">
          <FormGroup>
            <Input
              type="select"
              name="select"
              id="organization-selector"
              onChange={e =>
                setActiveFilter({
                  ...activeFilter,
                  organizationId: e && e.target.value
                })
              }
            >
              <option value="">organization</option>
              {organizations.map(organization => (
                <option key={organization.id} value={organization.id}>
                  {organization.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col sm="12" md="2">
          <FormGroup>
            <Input
              type="select"
              name="select"
              id="event-selector"
              onChange={e => {
                setDownloadButtonActive(e && e.target.value);
                setActiveFilter({
                  ...activeFilter,
                  status: e && e.target.value
                });
              }}
            >
              <option value="">Select an option</option>
              <option value="Pending">Pending</option>
              <option value="Complete">Complete</option>
              <option value="On Hold">On Hold</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>

      <Table className="responsive">
        <TableHead
          cols={[
            "Date",
            "Deposit ID",
            "Organization",
            "Status",
            "Type",
            "Bank",
            "Transaction Code",
            "Total"
          ]}
        />
        <tbody>
          {loading ? (
            <TableLoadingRow colspan="8" />
          ) : (
            deposits.map((record, i) => <Deposit key={i} deposit={record} />)
          )}
        </tbody>
      </Table>
      <div>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={queryLimit}
          totalItemsCount={recordsCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
        {pageSize > 0 && (
          <span>
            Listing {pageSize} of {recordsCount} records found.
          </span>
        )}
      </div>
    </React.Fragment>
  );
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(Deposits);
