import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default function PieChart({
  data,
  name,
  labels = [],
  events,
  dataField = "total"
}) {
  // const { data, name, labels } = props;
  useEffect(() => {
    const series = labels.map(label => {
      const serie = data.find(item => item.eventId == label.id);
      return {
        ...serie,
        name: label.event
      };
    });
    let salesPerEventChart = am4core.create(name, am4charts.PieChart);
    salesPerEventChart.fontSize = 11;

    let pieSeries = salesPerEventChart.series.push(new am4charts.PieSeries());
    pieSeries.colors.list = [
      am4core.color("#3f51b5"),
      am4core.color("#2196f3"),
      am4core.color("#9c27b0"),
      am4core.color("#673ab7")
    ];
    pieSeries.dataFields.value = dataField;
    pieSeries.dataFields.category = "name";
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-40);
    pieSeries.labels.template.fill = am4core.color("white");
    salesPerEventChart.legend = new am4charts.Legend();
    salesPerEventChart.data = events ? data : series;
  }, [data]);
  return <div id={name} style={{ height: "500px" }} />;
}
