import React, { useState, useEffect } from "react";
import { withFirebase } from "../../components/Firebase";
import { Col, Row } from "reactstrap";
import EventsContainer from "../../components/EventsContainer";
import { organization_event_courtesy_ticket_types_path } from "../../helpers/paths";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";

import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  FormFeedback
} from "../../components/Formica";

import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .required()
    .label("Name"),
  quantity: yup
    .number()
    .required()
    .min(1)
    .label("Quantity")
});

function CourtesyTicketTypesForm(props) {
  const {
    organizationId,
    eventId,
    courtesyTicketTypeId: id
  } = props.match.params;
  const [currentUserId, setCurrentUserId] = useState(props.uid);

  const initialValues = {
    name: "",
    quantity: 0,
    onHold: 0,
    active: true,
    redeemsCount: 0,
    status: "AVAILABLE"
  };

  const handleFormatData = values => {
    return {
      name: values.name,
      quantity: parseInt(values.quantity),
      redeemsCount: values.redeemsCount || 0,
      authorId: currentUserId,
      onHold: values.onHold || 0,
      active: values.active || true,
      status: values.status || "ACTIVE"
    };
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      const now = new Date();
      let formData = handleFormatData(values);
      if (id) {
        formData.updatedAt = now;

        await props.firebase.updateCourtesyTicketType(
          organizationId,
          eventId,
          id,
          formData
        );
      } else {
        formData.createdAt = now;
        formData.updatedAt = now;
        formData.authorId = currentUserId;
        formData.eventId = eventId;
        formData.organizationId = organizationId;
        formData.soldCount = 0;
        formData.sortOrder = 0;

        await props.firebase.createCourtesyTicketType(
          organizationId,
          eventId,
          formData
        );
      }
      props.history.push(
        organization_event_courtesy_ticket_types_path(organizationId, eventId)
      );
    },
    validationSchema
  });

  const getCourtesyTicketType = async () => {
    const courtesyTicketTypeDoc = await props.firebase.getCourtesyTicketType(
      organizationId,
      eventId,
      id
    );

    const {
      name,
      quantity,
      redeemsCount,
      onHold,
      active,
      status,
      authorId
    } = courtesyTicketTypeDoc.data();

    setValues({
      name,
      quantity,
      status,
      redeemsCount,
      onHold,
      active,
      authorId: authorId || currentUserId
    });
  };

  useEffect(() => {
    if (id) getCourtesyTicketType();
  }, []);

  return (
    <EventsContainer>
      <Form className="form-horizontal form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <FormGroup>
            <Label for="ticketTypeName">Name</Label>
            <Input
              type="text"
              name="name"
              value={values.name}
              className="form-control input-lg"
              id="ticketTypeName"
              onChange={handleChange}
              invalid={errors.name && true}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </FormGroup>

          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="number"
                  name="quantity"
                  value={values.quantity}
                  className="form-control"
                  id="quantity"
                  onChange={handleChange}
                  invalid={errors.quantity && true}
                />
                <FormFeedback>{errors.quantity}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="quantity">Redeems count</Label>
                <Input
                  type="number"
                  name="redeemsCount"
                  value={values.redeemsCount}
                  className="form-control"
                  id="redeemsCount"
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </FormFields>
        <FormActions
          cancelPath={organization_event_courtesy_ticket_types_path(
            organizationId,
            eventId
          )}
          submitCaption="Save"
        />
      </Form>
    </EventsContainer>
  );
}

export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({ uid: state.currentUser.uid }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(CourtesyTicketTypesForm);
