import axios from "axios";

const url =
  process.env.REACT_APP_ENV === "production"
    ? "https://us-central1-handypass-production.cloudfunctions.net/app"
    : "https://us-central1-handypass-dev.cloudfunctions.net/app";
// const url = "http://localhost:5000/handypass-dev/us-central1/app";

const couponssService = config => {
  const service = axios.create({
    baseURL: `${url}/replica/coupons`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": "d0358c74ffb389ad56235eda29aa8a378ba7a2a2909a234d8a"
    }
  });

  return service(config);
};

const all = ({ limit = 50, offset = 0, filters = [] }) =>
  couponssService({
    method: "GET",
    url: `?limit=${limit}&offset=${offset}${
      filters ? `&${filters.join("&")}` : ""
    }`
  }).then(({ data }) => data);

const report = ({ filters = [] }) =>
  couponssService({
    method: "GET",
    url: `${filters ? `?${filters.join("&")}` : ""}`
  }).then(({ data }) => data);

export default {
  all,
  report
};
