import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Input, Button, Table } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import EventsContainer from "../../components/EventsContainer";
import permissions from "../../helpers/permissions";
import axios from "axios";
import "moment-timezone";
import moment from "moment";

import ReactExport from "react-data-export";
import algoliasearch from "algoliasearch";
import Pagination from "react-js-pagination";
import TableHead from "../../components/Admin/TableHead";
import Coupon from "./_coupon";

import "./coupons.scss";
import CouponsService from "../../services/coupons.service";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function CouponsList({ match, currentUser, firebase }) {
  const { organizationId, eventId } = match.params;
  const [alert, setAlert] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [facetFilters, setFacetFilters] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [event, setEvent] = useState({});
  const [queryLimit, setQueryLimit] = useState(50);
  const [pageSize, setPageSize] = useState([0]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    getData(currentPage);
  }, []);
  const getData = async page => {
    let offset = page === 1 ? 0 : (page - 1) * queryLimit;
    const filters = [`q[eventId_eq]=${eventId}`];
    const response = await CouponsService.all({
      queryLimit,
      offset,
      filters
    });
    setRecordsCount(response.recordsCount);
    setPageSize(response.pageSize);
    setLoading(false);
    setCoupons(response.coupons);
  };

  const destroy = async (orgId, eventId, id) => {
    firebase.deleteCoupon(orgId, eventId, id).then(record => {
      setTimeout(() => {
        setRefresh(true);
        setRefresh(false);
        hideAlert();
      }, 5000);
    });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const cancelDestroy = () => {
    hideAlert();
  };

  const confirmDestroy = (orgId, eventId, id) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => destroy(orgId, eventId, id)}
        onCancel={() => cancelDestroy()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        showCancel
      >
        This action cannot be undone
      </ReactBSAlert>
    );
  };

  const downloadExcel = async () => {
    const filters = [`q[eventId_eq]=${eventId}`];
    const response = await CouponsService.report({ filters });
    setExcelData(response.coupons);
    setTriggerDownload(true);
    setTriggerDownload(false);
  };
  async function handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    setCurrentPage(pageNumber);
    setLoading(true);
    await getData(pageNumber);
  }
  return (
    <EventsContainer event={event}>
      {alert}
      <Row className="section-header">
        <Col md="2" xs="7">
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="search..."
          />
        </Col>
        <Col md="2" xs="5">
          <Button
            color="primary"
            className="btn-export-xls"
            onClick={downloadExcel}
          >
            Export .XLS
          </Button>
          {triggerDownload && (
            <ExcelFile
              hideElement
              filename={`Coupons-${moment().format("DD-MM-YY_HHmmss")}`}
            >
              <ExcelSheet data={excelData} name="coupons">
                <ExcelColumn label="COUPON" value="name" />
                <ExcelColumn label="CODE" value="code" />
                <ExcelColumn label="USED TIMES" value="usedTimes" />
                <ExcelColumn label="QUANTITY" value="quantity" />
                <ExcelColumn label="STATUS" value="status" />
                <ExcelColumn
                  label="EXPIRES AT"
                  value={col =>
                    col.expiresAt &&
                    moment(new Date(col.expiresAt)).format("YYYY/MM/DD HH:mm")
                  }
                />
              </ExcelSheet>
            </ExcelFile>
          )}
        </Col>
        <Col md="8">
          <div className="text-right table-actions pull-right">
            {permissions.can("coupons", "create") && (
              <Link
                to={`/${organizationId}/events/${eventId}/coupons/new`}
                className="btn btn-primary"
              >
                New coupon
              </Link>
            )}
          </div>
        </Col>
      </Row>
      <br />
      <Table>
        <TableHead cols={["Coupon", "Code", "Usage", "Status", ""]} />
        <tbody>
          {coupons.map((hit, index) => (
            <Coupon
              key={index}
              record={hit}
              organizationId={organizationId}
              eventId={eventId}
              confirmDestroy={confirmDestroy}
            />
          ))}
        </tbody>
      </Table>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={queryLimit}
        totalItemsCount={recordsCount}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
      {pageSize > 0 && (
        <span>
          Listing {pageSize} of {recordsCount} records found.
        </span>
      )}
    </EventsContainer>
  );
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(CouponsList);
