import React from "react";
import { Card } from "./Card";
import { Item } from "./Item";
import { centsToDecimal } from "../../helpers/currency";
export function Event({ ticket }) {
  return (
    <>
      <Card title="Event">
        <Item title="Phase Name" value={ticket.name} />
        <Item title="Quantity" value={ticket.quantity || 0} />
        <Item
          title="Unit Price"
          value={"$" + centsToDecimal(ticket.unitPrice || 0).toFixed(2)}
        />
        <Item title="Fee Per Price" value={`$ ${ticket.feePerUnit}`} />
        <Item
          title="Total"
          value={"$" + centsToDecimal(ticket.total).toFixed(2)}
        />
      </Card>
      <br />
    </>
  );
}
