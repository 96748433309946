import React, { useState } from "react";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import { ItemName } from "./ItemName";
import { ItemEmail } from "./ItemEmail";
export function Item(props) {
  const { title, value, editable, type } = props;
  if (type === "name" && editable) {
    return <ItemName {...props} />;
  } else if (type === "email" && editable) {
    return <ItemEmail {...props} />;
  }
  return (
    <Row>
      <Col className="col-2" md={2}>
        {title}
      </Col>
      <Col md={10} className="infoItem col-10">
        {value}
      </Col>
    </Row>
  );

  // if (type === "name" && editable) return <ItemName {...props} />;
  // if (type === "email" && editable) return null;
}
