import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge
} from "reactstrap";

import { useFormState } from "react-use-form-state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CustomTable from "../../../components/CustomTable";

import { withFirebase } from "../../../components/Firebase";

import "./index.css";
import ReactBSAlert from "react-bootstrap-sweetalert";

const FormsList = props => {
  const [formState, { text }] = useFormState();
  const [forms, setForms] = useState([]);
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [organizations, setOrganizations] = useState({});

  async function getForms() {
    let formsCollection = await props.firebase.formsCollectionGroup().get();
    var frms = [];
    formsCollection.docs.forEach(doc => {
      frms.push({
        id: doc.id,
        ...doc.data()
      });
    });
    setForms(frms);
    setLoading(false);
  }

  useEffect(() => {
    getForms();
  }, []);

  function navigate(to) {
    props.history.push(to);
  }

  // function openModal(form) {
  //   setSelectedForm(form);
  //   setModalIsOpen(true);
  // }

  // function closeModal() {
  //   setSelectedForm(null);
  //   setModalIsOpen(false);
  // }

  function destroy(form) {
    props.firebase.deleteForm(form.organizationId, form.id).then(() => {
      setAlert(null);
      setForms(forms.filter(f => f.id !== form.id));
    });
  }

  function cancelDestroy() {
    setAlert(null);
    console.log(alert, "alert");
  }

  function confirmDestroy(form) {
    console.log(form);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => destroy(form)}
        onCancel={cancelDestroy}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        showCancel
      >
        This action cannot be undone
      </ReactBSAlert>
    );
  }

  return (
    <>
      {alert}
      <Row>
        <Col xs={9}>
          <h2 className="page-title">Forms</h2>
        </Col>
        <Col xs={3}>
          <Button block color="primary" onClick={() => navigate("/forms/new")}>
            New Form
          </Button>
        </Col>
      </Row>

      <CustomTable
        headers={["Organization Name", "Form Name", ""]}
        loading={loading}
      >
        {forms.map(form => (
          <tr key={form.id}>
            <td>{form.organizationId}</td>
            <td>{form.title}</td>
            <td>
              <div className="branches-read__actions">
                <UncontrolledDropdown className="btn-form-options" direction="left">
                  <DropdownToggle tag="span">
                    <FontAwesomeIcon icon="ellipsis-h" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() =>
                        navigate(
                          `/${form.organizationId}/forms/${form.id}/edit`
                        )
                      }
                    >
                      Settings
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => confirmDestroy(form)}
                      className="text-danger"
                    >
                      Destroy
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};

export default withFirebase(FormsList);
