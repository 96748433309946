import React from "react";
import "./index.scss";

export default function TableEventReport({ ticketsReport, productsReport }) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  return (
    <table className="TableEventReport responsive">
      <tbody>
        <tr>
          <th role="columnheader">Tipo de Boleto General</th>
          <th role="columnheader">Aforo Disponible</th>
          <th role="columnheader">Cantidad Vendida</th>
          <th role="columnheader">Cantidad Disponible</th>
          <th role="columnheader">Precio Unitario</th>
          <th role="columnheader">Total</th>
        </tr>
        {ticketsReport.length > 0 &&
          ticketsReport.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <tr className="head-row">
                  <td role="cell" style={{ fontWeight: "bold" }}>{item.name}</td>
                  <td role="cell">{item.phaseQuantity}</td>
                  <td role="cell">{item.quantity}</td>
                  <td role="cell">{item.phaseQuantity - item.quantity}</td>
                  <td role="cell"></td>
                  <td role="cell">{formatter.format(item.subtotal / 100 || 0)}</td>
                </tr>
                {item.phases.map((element, j) => {
                  return (
                    <tr className="phase-row" key={j}>
                      <td role="cell">{element.phasename}</td>
                      <td role="cell">{element.phasequantity}</td>
                      <td role="cell">{element.quantity}</td>
                      <td role="cell">{element.phasequantity - element.quantity}</td>
                      <td role="cell">{formatter.format(element.phaseunitprice / 100)}</td>
                      <td role="cell">{formatter.format(element.subtotal / 100)}</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        {productsReport.length > 0 && (
          <React.Fragment>
            <tr className="product-head-row">
              <td role="cell" style={{ fontWeight: "bold" }}>{"Productos Adicionales"}</td>
              <td role="cell">
                {productsReport.reduce(
                  (current, value) => current + parseInt(value.productquantity),
                  0
                )}
              </td>
              <td role="cell">
                {productsReport.reduce(
                  (current, value) => current + parseInt(value.quantity),
                  0
                )}
              </td>
              <td role="cell">
                {productsReport.reduce(
                  (current, value) => current + parseInt(value.productquantity),
                  0
                ) -
                  productsReport.reduce(
                    (current, value) => current + parseInt(value.quantity),
                    0
                  )}
              </td>
              <td role="cell"></td>
              <td role="cell">
                {formatter.format(
                  productsReport.reduce(
                    (current, value) => current + parseInt(value.subtotal),
                    0
                  ) / 100
                )}
              </td>
            </tr>

            {productsReport.map((product, i) => (
              <tr className="product-row" key={i}>
                <td role="cell">{product.name}</td>
                <td role="cell">{parseInt(product.productquantity)}</td>
                <td role="cell">{parseInt(product.quantity)}</td>
                <td role="cell">
                  {parseInt(product.productquantity) -
                    parseInt(product.quantity)}
                </td>
                <td role="cell">
                  {formatter.format(parseInt(product.productunitprice) / 100) ||
                    0}
                </td>
                <td role="cell">
                  {formatter.format(parseInt(product.subtotal) / 100 || 0)}
                </td>
              </tr>
            ))}
          </React.Fragment>
        )}
      </tbody>
    </table>
  );
}
