import React from "react";
import { Badge } from "reactstrap";

export default function StatusBadge({status}) {
  const badgeColors = {
    ACTIVE: "success",
    PAID: "success",
    INACTIVE: "secondary",
    CANCELED: "danger",
    CANCELLED: "danger",
    VOID: "danger",
    EXPIRED: "warning",
    PENDING: "asphalt"
  };

  return (
    <Badge color={badgeColors[status]}>
      {status.toLowerCase().capitalize()}
    </Badge>
  );
}
