import React, { useState, useEffect } from "react";

import { withFirebase } from "../../components/Firebase";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";


import "moment-timezone";

import { centsToDecimal, decimalToCents } from "../../helpers/currency";

import { Row, Col } from "reactstrap";

import EventsContainer from "../../components/EventsContainer";

import { organization_event_coupons_path } from "../../helpers/paths";

import {
  generateShortCode,
  calculateFirebaseBatchPages
} from "../../helpers/utils";

import Select from "react-select";

import Pace from "react-pace-progress";

import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  FormFeedback,
  SelectFromArray,
  DateTimeInput,
  CurrencyInput,
  CheckBox
} from "../../components/Formica";

import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .required()
    .label("Name"),
  discountType: yup
    .string()
    .required()
    .min(2)
    .label("Discount type"),
  code: yup
    .string()
    .required()
    .min(4)
    .label("Discount type"),
  quantity: yup
    .number()
    .required()
    .min(1)
    .label("Quantity"),
  minTickets: yup
    .number()
    .required()
    .min(1)
    .label("min tickets"),
  maxTickets: yup
    .number()
    .required()
    .min(1)
    .label("max tickets"),
  expiresAt: yup
    .date()
    .required()
    .label("expires At"),
  batchSize: yup
    .number()
    .min(1)
    .when("generateBatch", {
      is: true,
      otherwise: s => s.min(0)
    })
    .required()
    .when("generateBatch", {
      is: true,
      otherwise: s => s.notRequired()
    })
});

const initialValues = {
  name: "",
  description: "",
  fixedDiscount: 0,
  discountType: "VARIABLE",
  variableDiscount: 0,
  code: generateShortCode(),
  quantity: 1,
  expiresAt: new Date(),
  phaseIds: [],
  minTickets: 1,
  maxTickets: 10,
  active: true,
  phases: [],
  usedTimes: 0,
  generateBatch: false,
  status: "PENDING",
  isRoot: true,
  loading: false,
  couponExists: false,
  batchSize: 0
};

const handleFormatData = values => {
  return {
    name: values.name,
    description: values.description,
    fixedDiscount: decimalToCents(parseInt(values.fixedDiscount)),
    discountType: values.discountType,
    variableDiscount: values.variableDiscount,
    code: values.code,
    quantity: parseInt(values.quantity),
    expiresAt: values.expiresAt,
    phaseIds: values.phaseIds,
    minTickets: parseInt(values.minTickets),
    maxTickets: parseInt(values.maxTickets),
    active: values.active,
    usedTimes: parseInt(values.usedTimes) || 0,
    isRoot: values.isRoot,
    status: values.status,
    batchSize: values.batchSize
  };
};

function CouponForm(props) {
  const { organizationId, eventId, couponId } = props.match.params;
  const [phases, setPhases] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(props.uid);
  const [loading, setLoading] = useState(false);
  const [couponExists, setCouponExists] = useState(false);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    handleBlur,
    touched
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      const now = new Date();
      let coupon = handleFormatData(values);

      console.log(coupon);

      if (couponId) {
        coupon.updatedAt = now;
        await props.firebase.updateCoupon(
          organizationId,
          eventId,
          couponId,
          coupon
        );
      } else {
        // create coupon

        coupon.createdAt = now;
        coupon.updatedAt = now;
        coupon.authorId = currentUserId;
        coupon.eventId = eventId;
        coupon.organizationId = organizationId;
        coupon.usedTimes = 0;
        coupon.isRoot = true;

        const record = await props.firebase.createCoupon(organizationId, eventId, coupon);

        console.log(values.generateBatch, values.batchSize);
        if (values.generateBatch && parseInt(values.batchSize) > 0) {
          await generateBatch(record.id, coupon);
        }
      }

      props.history.push(
        organization_event_coupons_path(organizationId, eventId)
      );
    },
    validationSchema
  });


  const generateBatch = async (parentId, coupon) => {
    setLoading(true)
    let couponData = coupon;
    couponData.isRoot = false;
    couponData.parentId = parentId;
    const batchSize = parseInt(values.batchSize);
    const batchPages = calculateFirebaseBatchPages(batchSize);
    console.log(batchPages);

    let batches = [];
    batchPages.forEach((pageSize, i) => {
      let batch = props.firebase.db.batch();
      for (let i = 0; i < pageSize - 1; i++) {
        couponData.code = generateShortCode();
        let couponRef = props.firebase.couponsCollection(organizationId, eventId).doc();
        batch.set(couponRef, couponData);
      }
      batches.push(batch.commit());
    });
    await Promise.all(batches);
    setLoading(false);
    return "finished";
  };

  const getPhases = async () => {

    const phasesCollection = await props.firebase.db
      .collection("organizations")
      .doc(organizationId)
      .collection("events")
      .doc(eventId)
      .collection("phases")
      .get();

    const phaseDocs = phasesCollection.docs.map(doc => ({
      value: doc.id,
      label: doc.data().name
    }))

    setPhases(phaseDocs);
    return phaseDocs;
  };

  useEffect(() => {
    getPhases().then((result) => {
      if (couponId) getCoupon(result);
    })

  }, []);

  const getCoupon = async (_phases) => {
    const coupon = await props.firebase.getCoupon(
      organizationId,
      eventId,
      couponId
    );

    const {
      name,
      description,
      discountType,
      code,
      quantity,
      expiresAt,
      phaseIds,
      minTickets,
      maxTickets,
      eventId: couponEventId,
      active,
      fixedDiscount,
      variableDiscount,
      isRoot,
      status,
      batchSize,
    } = coupon.data();

    console.log(_phases);
    console.log(phaseIds);

    setValues({
      id: coupon.id,
      name,
      description,
      fixedDiscount: centsToDecimal(fixedDiscount),
      discountType,
      variableDiscount,
      code,
      phaseIds,
      eventId: couponEventId,
      active,
      quantity: parseInt(quantity),
      expiresAt: expiresAt.toDate(),
      minTickets: parseInt(minTickets),
      maxTickets: parseInt(maxTickets),
      selectedPhases: _phases.filter(p => phaseIds.includes(p.value)),
      isRoot: isRoot || false,
      status,
      batchSize
    });
  };


  const handleBatchGenerator = () => {
    this.setState({ generateBatch: !this.state.generateBatch });
  };

  const setSelectedPhase = vals => {
    console.log(vals);
    const selectedIds = vals.map(opt => opt.value);
    setFieldValue('phaseIds', selectedIds );

    setFieldValue('selectedPhases', phases.filter(p => selectedIds.includes(p.value)))
    console.log(values.phaseIds)
  };

  return (
    <EventsContainer>
      <Form className="form-horizontal form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <FormGroup>
            <Label for="phaseName">Name</Label>
            <Input
              type="text"
              name="name"
              value={values.name}
              className="form-control"
              onChange={handleChange}
              invalid={errors.name && true}
              onBlur={handleBlur}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              name="description"
              rows="5"
              value={values.description}
              placeholder="Coupon description"
              className="form-control"
              onChange={handleChange}
              invalid={errors.description && true}
              onBlur={handleBlur}
            />
            <FormFeedback>{errors.description}</FormFeedback>
          </FormGroup>

          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="discountType">Discount type</Label>
                <SelectFromArray
                  onChange={e => {
                    setFieldValue("discountType", e.target.value);
                    console.log(e.target.value);
                  }}
                  name="status"
                  value={values.discountType}
                  includeBlank="Select a discount type"
                  collection={["VARIABLE", "FIXED"]}
                  invalid={errors.discountType && true}
                />
                <FormFeedback>{errors.discountType}</FormFeedback>
              </FormGroup>
            </Col>
            {values.discountType === "FIXED" && (
              <Col md="6">
                <FormGroup>
                  <Label for="fixedDiscountDecimal">Fixed discount</Label>
                  <CurrencyInput
                    value={values.fixedDiscount}
                    name="fixedDiscount"
                    className="form-control"
                    prefix="$"
                    autoFocus={true}
                    onChangeEvent={(event, maskedVal, floatVal) =>
                      setFieldValue("fixedDiscount", floatVal)
                    }
                  />
                </FormGroup>
              </Col>
            )}
            {values.discountType === "VARIABLE" && (
              <Col md="6">
                <FormGroup>
                  <Label for="variableDiscount">Variable discount</Label>
                  <CurrencyInput
                    value={values.variableDiscount}
                    name="variableDiscount"
                    className="form-control"
                    suffix=" %"
                    precision="0"
                    autoFocus={true}
                    onChangeEvent={(event, maskedVal, floatVal) =>
                      setFieldValue("variableDiscount", floatVal)
                    }
                  />
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            <Col md="3">
              <FormGroup>
                <Label for="code">Code</Label>
                <Input
                  type="text"
                  name="code"
                  value={values.code}
                  className="text-danger"
                  onChange={handleChange}
                  invalid={errors.code && true}
                  onBlur={handleBlur}
                />
                <FormFeedback>{errors.code}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="number"
                  name="quantity"
                  value={values.quantity}
                  onChange={handleChange}
                  id="quantity"
                  onBlur={handleBlur}
                  invalid={errors.quantity && true}
                />
                <FormFeedback>{errors.quantity}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="minTickets">Min. tickets</Label>
                <Input
                  type="number"
                  name="minTickets"
                  value={values.minTickets}
                  onChange={handleChange}
                  id="minTickets"
                  onBlur={handleBlur}
                  invalid={errors.minTickets && true}
                />
                <FormFeedback>{errors.minTickets}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="maxTickets">Max. tickets</Label>
                <Input
                  type="number"
                  name="maxTickets"
                  value={values.maxTickets}
                  onChange={handleChange}
                  id="maxTickets"
                  onBlur={handleBlur}
                  invalid={errors.maxTickets && true}
                />
                <FormFeedback>{errors.maxTickets}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="phaseIds">Phases</Label>
            <Select
              isMulti
              value={values.selectedPhases}
              name="phaseIds"
              options={phases}
              onChange={value => setSelectedPhase(value)}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>

          <FormGroup>
            <Label for="expiresAt">Expires at</Label>
            <br />
            <DateTimeInput
              className="datetime-picker-input"
              selected={values.expiresAt}
              onChange={date => setFieldValue("expiresAt", date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeCaption="time"
              invalid={errors.expiresAt && true}
            />
            <FormFeedback>{errors.expiresAt}</FormFeedback>
          </FormGroup>
          {!couponId && (
            <section className="section-danger">
              <CheckBox
                name="generateBatch"
                onChange={() =>
                  setFieldValue("generateBatch", !values.generateBatch)
                }
                checked={values.generateBatch}
                label="Generate batch?"
              />
              {values.generateBatch && (
                <FormGroup>
                  <Label for="batchSize">Batch size</Label>
                  <Input
                    type="number"
                    name="batchSize"
                    value={values.batchSize}
                    onChange={handleChange}
                    id="batchSize"
                    onBlur={handleBlur}
                    invalid={errors.batchSize && true}
                  />
                  <FormFeedback>{errors.batchSize}</FormFeedback>

                  <small className="form-text text-muted">
                    Specify how many coupons you want to generate.
                  </small>
                </FormGroup>
              )}
            </section>
          )}
        </FormFields>

        <FormActions
          cancelPath={organization_event_coupons_path(organizationId, eventId)}
          submitCaption="Save"
        >
          {loading && (
            <span>
              <Pace color="#27ae60" />
              <br />
              <small>creating coupons...</small>
            </span>
          )}
        </FormActions>
      </Form>
    </EventsContainer>
  );
}

export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({ uid: state.currentUser.uid }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(CouponForm);

