import React from "react";
import { Button } from "reactstrap";
import Uppload from "uppload";
class UpploadReact extends React.Component {
  constructor(props) {
    super(props);
    this.Uppload = new Uppload({
      uploadFunction: this.props.handleUpload,
      services: ["upload"],
      allowedTypes: ["image/jpg", "image/png", "image/jpeg"]
    });

    this.Uppload.on("fileUploaded", this.props.getUrl);
  }

  handleClick = e => {
    this.Uppload.openModal();
    e.preventDefault();
  };

  render() {
    return (
      <div onClick={this.handleClick}>
        <Button color="secondary" size="sm">
          Select file
        </Button>
      </div>
    );
  }
}
export default UpploadReact;
