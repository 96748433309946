import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import EventsContainer from "../../components/EventsContainer";
import permissions from "../../helpers/permissions";

import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";

import { asyncForEach } from "../../helpers/utils";

import "moment-timezone";

import "./orders.scss";
import TableHead from "../../components/Admin/TableHead";
import dotsIcon from "../../images/icons/dots.svg";
import BatchOrder from "./_batchOrder";

function BatchOrderList({ match, firebase, currentUser, history }) {
  const { organizationId, eventId } = match.params;
  const [alert, setAlert] = useState(null);
  const [orders, setOrders] = useState([]);
  const [event, setEvent] = useState({});

  const getBatchOrders = async () => {
    const { organizationId, eventId } = match.params;

    let batchOrders = await firebase.db
      .collection(
        `organizations/${organizationId}/events/`
      )
      .doc(eventId)
      .collection("orders")
      .where("orderType", "==", "BATCH")
      .get();

    let ordersData = []
    await asyncForEach(batchOrders.docs, async doc => {
      let order = doc.data();
      order.id = doc.id;

      let orderTickets = await firebase.db
        .collection(`organizations/${organizationId}/events/`)
        .doc(eventId)
        .collection("orders")
        .doc(order.id)
        .collection("order_tickets")
        .get();
      const orderTicket = orderTickets.docs[0];
      order.phaseName = orderTicket ?  orderTicket.data().name : "foo";

      let ticketType = await firebase.db
        .collection(`organizations/${organizationId}/events/`)
        .doc(eventId)
        .collection("ticket_types")
        .doc(orderTicket.data().ticketTypeId).get()

      order.ticketTypeName = ticketType.data().name;

      ordersData.push(order);
    });
    setOrders(ordersData);
  };

  useEffect(() => {
    const getEvent = async () => {
      var event = await firebase.getEvent(organizationId, eventId);
      setEvent(event.data());
    };
    getEvent();
  }, []);

  useEffect(() => {
    getBatchOrders();
  }, []);

  const destroy = async (orgId, eventId, id) => {
    firebase.db
      .collection(`organizations/${organizationId}/events/${eventId}/orders`)
      .doc(id)
      .delete()
      .then(record => {
        const list = [
          ...orders.filter(r => {
            return r.id !== id;
          })
        ];
        setOrders(list);
        hideAlert();
      });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const cancelDestroy = () => {
    hideAlert();
  };

  const confirmDestroy = (orgId, eventId, id) => {
    setAlert(
      <DestroyConfirmation
        onConfirm={() => destroy(orgId, eventId, id)}
        onCancel={() => cancelDestroy()}
      />
    );
  };

  return (
    <EventsContainer event={event}>
      {alert}
      <div className="section-header">
        <div className="text-right table-actions pull-right">
          {permissions.can("orders", "create") && (
            <Link
              to={`/${organizationId}/events/${eventId}/batch_orders/new`}
              className="btn btn-primary"
            >
              New batch
            </Link>
          )}
        </div>
      </div>
      <br />
      <Table className="responsive" role="table">
        <TableHead cols={["Batch id", "Ticket type", "Phase", "Batch size", "Status", ""]} />
        <tbody>
          {orders.map((record, i) => (
            <BatchOrder
              record={record}
              key={i}
              organizationId={organizationId}
              eventId={eventId}
              history={history}
              confirmDestroy={confirmDestroy}
            />
          ))}
        </tbody>
      </Table>
    </EventsContainer>
  );
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(BatchOrderList);
