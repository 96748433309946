import React from "react";
import { Switch, Route } from "react-router-dom";

import Events from "../../../../pages/events/";

import EventsForm from "../../../../pages/events/form";
import EventShow from "../../../../pages/events/show";

import PhasesList from "../../../../pages/phases/";
import PhaseForm from "../../../../pages/phases/form";

import ProductsList from "../../../../pages/products/";
import ProductForm from "../../../../pages/products/form";

import TicketTypesList from "../../../../pages/ticket_types/index";
import TicketTypeForm from "../../../../pages/ticket_types/form";

import CouponsList from "../../../../pages/coupons/index";
import CouponForm from "../../../../pages/coupons/form";

import CourtesyTicketTypesList from "../../../../pages/courtesy_ticket_types/index";
import CourtesyTicketTypeForm from "../../../../pages/courtesy_ticket_types/form";

import CourtesiesList from "../../../../pages/courtesies/index";
import CourtesyForm from "../../../../pages/courtesies/form";

import BatchOrdersList from "../../../../pages/batch_orders";
import BatchOrderForm from "../../../../pages/batch_orders/form";
import BatchOrderShow from "../../../../pages/batch_orders/show";

import EventVenue from "../../../../pages/events/venuedates";
import EventDesign from "../../../../pages/events/design";

import SalesReport from "../../../../pages/events/salesreport";

import PrivateRoute from "../../../../components/PrivateRoute";
import PdfSettings from "../../../../pages/events/pdfsettings";

import CheckInStaff from "../../../../pages/check_staff/index";
import CheckInStaffForm from "../../../../pages/check_staff/form";
import CheckInReport from "../../../../pages/events/CheckInReport";

export default function Application() {
  return (
    <Switch>
      <Route exact path="/events" component={Events} />
      <Route exact path="/events/new" component={EventsForm} />
      <Route
        exact
        path="/:organizationId/events/:eventId/phases"
        component={PhasesList}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/phases/new"
        component={PhaseForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/phases/:phaseId/edit"
        component={PhaseForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/products"
        component={ProductsList}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/products/new"
        component={ProductForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/products/:productId/edit"
        component={ProductForm}
      />

      <Route
        exact
        path="/:organizationId/events/:eventId/edit"
        component={EventsForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId"
        component={SalesReport}
      />

      <Route
        exact
        path="/:organizationId/events/:eventId/ticket_types"
        component={TicketTypesList}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/ticket_types/new"
        component={TicketTypeForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/ticket_types/:ticketTypeId/edit"
        component={TicketTypeForm}
      />

      <Route
        exact
        path="/:organizationId/events/:eventId/coupons"
        component={CouponsList}
      />

      <Route
        exact
        path="/:organizationId/events/:eventId/checkin_report"
        component={CheckInReport}
      />

      <Route
        exact
        path="/:organizationId/events/:eventId/coupons/new"
        component={CouponForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/coupons/:couponId/edit"
        component={CouponForm}
      />

      <Route
        exact
        path="/:organizationId/events/:eventId/batch_orders"
        component={BatchOrdersList}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/batch_orders/new"
        component={BatchOrderForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/batch_orders/:orderId"
        component={BatchOrderShow}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/batch_orders/:orderId/edit"
        component={BatchOrderForm}
      />

      <Route
        exact
        path="/:organizationId/events/:eventId/courtesy_ticket_types"
        component={CourtesyTicketTypesList}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/courtesy_ticket_types/new"
        component={CourtesyTicketTypeForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/courtesy_ticket_types/:courtesyTicketTypeId/edit"
        component={CourtesyTicketTypeForm}
      />

      <Route
        exact
        path="/:organizationId/events/:eventId/courtesies"
        component={CourtesiesList}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/courtesies/new"
        component={CourtesyForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/courtesies/:courtesyId/edit"
        component={CourtesyForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/pdf_settings"
        component={PdfSettings}
      />
      <PrivateRoute
        exact
        path="/:organizationId/events/:eventId/Venue&Date"
        component={EventVenue}
      />
      <PrivateRoute
        exact
        path="/:organizationId/events/:eventId/Design"
        component={EventDesign}
      />
      <PrivateRoute
        exact
        path="/:organizationId/events/:eventId/check_staff"
        component={CheckInStaff}
      />

      <PrivateRoute
        exact
        path="/:organizationId/events/:eventId/check_staff/new"
        component={CheckInStaffForm}
      />
      <Route
        exact
        path="/:organizationId/events/:eventId/check_staff/:associationId/edit"
        component={CheckInStaffForm}
      />
    </Switch>
  );
}
