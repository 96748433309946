import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Badge } from "reactstrap";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userSignedOut } from "../../../actions";
// firebase
import { withFirebase } from "../../Firebase";

import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import HandypassLogo from "../../../images/handypass_logo.svg";

import "./index.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CollapseMenu from "../CollapseMenu";

const Navigation = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userInitials, setUserInitials] = useState("");

  const toggle = () => setIsOpen(!isOpen);

  const signOut = async e => {
    e.preventDefault();
    props.firebase.doSignOut();
    props.userSignedOut();
    props.history.push("/");
  };

  useEffect(()=> {
    const initials = props.currentUser.displayName.split(" ");
    setUserInitials(initials.map(name => name[0]))
  }, []);

  return (
    <React.Fragment>
      <Navbar expand="md" className="handypass-navigation fixed-top">
        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon icon="bars" />
        </NavbarToggler>
        <NavbarBrand href="/">
          <img
            src={HandypassLogo}
            alt="Handypass"
            className="logo"
          />
        </NavbarBrand>
        <Nav className="mr-autom w-100 user-menu" navbar>
          <UncontrolledDropdown nav inNavbar className="ml-auto">
            <DropdownToggle nav className="text-right">
              <div className="user-badge">
                {userInitials}
              </div>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={signOut}>
                <i className="fa fa-power-off" /> Logout
                </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <CollapseMenu isOpen={isOpen} setIsOpen={setIsOpen}/>
      </Navbar>
    </React.Fragment>
  );
}

export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({ currentUser: state.currentUser }),
    dispatch => bindActionCreators({ userSignedOut }, dispatch)
  )
)(Navigation);;