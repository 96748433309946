export const environment =
  process.env.REACT_APP_ENV === "production"
    ? {
        firebase: {
          apiKey: "AIzaSyDXelhbFU4mE-40ghlta0ay3vL_oxZ6cxg",
          authDomain: "handypass-production.firebaseapp.com",
          databaseURL: "https://handypass-production.firebaseio.com",
          projectId: "handypass-production",
          storageBucket: "handypass-production.appspot.com",
          messagingSenderId: "472670961569",
          appId: "1:472670961569:web:76cab85e63b40eda"
        }
      }
    : {
        firebase: {
          apiKey: "AIzaSyC6qO-DWLpNpiBJzV1UMiiMDdyn-m7ph1k",
          authDomain: "handypass-dev.firebaseapp.com",
          databaseURL: "https://handypass-dev.firebaseio.com",
          projectId: "handypass-dev",
          storageBucket: "handypass-dev.appspot.com",
          messagingSenderId: "209742567686",
          appId: "1:209742567686:web:74e2a2e4831f0661"
        }
      };
