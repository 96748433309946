import React from "react";
import { Switch, Route } from "react-router-dom";

import SliderItems from "../../../../pages/slider_items";
import SliderItemForm from "../../../../pages/slider_items/form";

export default function Application() {
  return (
    <Switch>
      <Route exact path="/slider_items" component={SliderItems} />
      <Route exact path="/slider_items/new" component={SliderItemForm} />
      <Route
        exact
        path="/slider_items/:id/edit"
        component={SliderItemForm}
      />
    </Switch>
  );
}
