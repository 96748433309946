import React from "react";

export default function OrganizationSelect(props) {
  return (
    <select
      disabled={props.disabled}
      className="form-control"
      name="organization"
      value={props.Organization}
      onChange={props.handleSetOrganization}
    >
      <option>Select an organization</option>
      {props.Organizations.map((Org, i) => (
        <option key={i} value={Org.id}>
          {Org.name}
        </option>
      ))}
    </select>
  );
}
