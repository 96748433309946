import React from 'react'

export default function index(props) {
    return (
        <img
          src={props.imgUrl}
          style={props.theme}
          className="img-fluid"
          alt="Responsive"
        />
    )
}
