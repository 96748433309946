import Hashids from "hashids";

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const generateShortCode = (floor = 100, ceil = 999) => {
  var salt = new Date();
  salt = salt.valueOf().toString();
  var hashids = new Hashids(salt, 8, "ABCDEFGHIJKLMNPQRSTUVWXYZ1234567890");
  // var numbers = hashids.decode(id);
  return hashids.encode(
    getRndInteger(floor, ceil)
      .toString()
      .split("")
  );
};

export const getRndInteger = (min = 0, max = 999) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const times = x => f => {
  if (x > 0) {
    f();
    times(x - 1)(f);
  }
};

export const calculateFirebaseBatchPages = recordCount => {
  // If size is less than 500
  const batchSize = 500;
  let result = [];
  let pagesCount = parseInt(recordCount / batchSize);
  let mod = parseInt(recordCount % batchSize);
  if (pagesCount > 0) {
    times(pagesCount)(() => result.push(batchSize));
    if (mod > 0) result.push(mod);
  } else {
    result = [recordCount];
  }
  return result;
};
