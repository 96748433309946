import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../components/Firebase";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";

import EventsContainer from "../../components/EventsContainer";

import { organization_event_ticket_types_path, organization_event_path } from "../../helpers/paths";
// import FormActions from "../../components/Admin/FormActions";
// import SelectFromCollection from "../../components/Admin/SelectFromCollection";
// import FormFields from "../../components/Admin/FormFields";

import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  FormFeedback,
  SelectFromCollection
} from "../../components/Formica";


import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .required()
    .label("Name")
});

const initialValues = {
  name: "",
  eventId: "",
  formId: "",
  authorId: "",
};

function TicketTypeForm(props) {
  const { organizationId, eventId, ticketTypeId } = props.match.params;
  const [forms, setForms] = useState();
  const [currentUserId, setCurrentUserId] = useState(props.uid);

  const handleFormatData = values => {
    return {
      name: values.name,
      formId: values.formId,
    };
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      const now = new Date();
      let ticketType = handleFormatData(values);
      if (ticketTypeId) {
        ticketType.updatedAt = now;

        await props.firebase.updateTicketType(organizationId, eventId, ticketTypeId, ticketType);
      } else {
        ticketType.createdAt = now;
        ticketType.updatedAt = now;
        ticketType.authorId = currentUserId;
        ticketType.eventId = eventId;
        ticketType.organizationId = organizationId;

        props.firebase.createTicketType(organizationId, eventId, ticketType);
      }
      props.history.push(organization_event_path(organizationId, eventId, "/ticket_types"));
    },
    validationSchema
  });

  const getForms = async () => {
    const formsCollection = await props.firebase.formsCollection(organizationId).get();
    console.log(formsCollection.empty);
    setForms(
      formsCollection.docs.map(doc =>
        ({ id: doc.id, ...doc.data() })
      )
    );
  };

  const getTicketType = async () => {
    const now = new Date();

    const ticketType = await props.firebase.db.doc(`/organizations/${organizationId}/events/${eventId}/ticket_types/${ticketTypeId}`).get();

    const { name, formId, createdAt, updatedAt, authorId } = ticketType.data();

    setValues({
      name,
      formId,
      createdAt: createdAt || now,
      updatedAt: updatedAt || now,
      authorId: authorId || currentUserId
    })
  };

  useEffect(() => {
    getForms();
    if (ticketTypeId) getTicketType();
  }, [])

  return (
    <EventsContainer>
      <Form className="form-horizontal form-compact"  onSubmit={handleSubmit}>
        <FormFields>
          <FormGroup>
            <Label for="ticketTypeName">Name</Label>
            <Input
              type="text"
              name="name"
              value={values.name}
              className="form-control input-lg"
              id="ticketTypeName"
              onChange={handleChange}
              invalid={errors.name && true}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="formId">Associated Form</Label>
            <SelectFromCollection
              name="formId"
              value={values.formId}
              onChange={handleChange}
              placeholder="Form"
              collection={forms}
              optionLabel="title"
              optionValue="id"
              invalid={errors.formId && true}
              includeBlank
            />
            <FormFeedback>{errors.formId}</FormFeedback>
          </FormGroup>

        </FormFields>

        <FormActions
          cancelPath={organization_event_ticket_types_path(organizationId, eventId)}
          submitCaption="Save"
        />
      </Form>

    </EventsContainer>
  );

}
export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({ uid: state.currentUser.uid }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(TicketTypeForm);
