import { format, parseISO } from "date-fns";
const moment = require("moment");

export function fbTimestampToDate(timestamp) {
  return moment(timestamp * 1000).toDate();
}

export const formatTime = time => format(parseISO(time), "dd/MM/yyyy");

export const formatWithTime = time =>
  format(parseISO(time), "dd/MM/yyyy hh:mm:ss");

export const formatDate = time => format(parseISO(time), "dd/MM/yyyy");
