import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { slugify } from "../../helpers/string";

import Select from "react-select";
import { paymentGatewayOptions } from "../../helpers/events";
import EventsContainer from "../../components/EventsContainer";
import { events_path } from "../../helpers/paths";
import { centsToDecimal, decimalToCents } from "../../helpers/currency";
import { Col, Row } from "reactstrap";
import { withFirebase } from "../../components/Firebase";

import moment from "moment";
import "moment-timezone";
import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  FormFeedback,
  SelectFromCollection,
  MarkdownInput,
  CurrencyInput,
  CheckBox
} from "../../components/Formica";

import { useFormik } from "formik";
import * as yup from "yup";
const validationSchema = yup.object({
  organizationId: yup
    .string()
    .required()
    .label("Organization"),
  slug: yup
    .string()
    .required()
    .label("Slug"),
  name: yup
    .string()
    .required()
    .label("Event's Name"),
  maskedRateLimit: yup
    .string()
    .required()
    .label("Rate Limit")
});
const initialValues = {
  headerImage: "",
  logo: "",
  code: Math.random()
    .toString(12)
    .substr(2, 199),
  slug: "",
  name: "",
  location: "",
  description: "",
  startsAt: new Date(),
  endsAt: new Date(),
  eventStatus: "ACTIVE",
  organizationId: "",
  lat: 25.6714,
  lon: -100.309,
  organizations: [],
  paymentGateways: [],
  defaultPaymentGateway: "",
  rateLimit: 1000000,
  banorteExclusiveSale: false,
  maskedRateLimit: "10,000.00",
  donation: false,
  courtesies: false,
  disableSlugify: false,
  cashOrderExpirationTime: 2
};

const handleFormatData = values => {
  return {
    code: values.code,
    slug: values.slug,
    name: values.name,
    description: values.description,
    eventStatus: values.eventStatus,
    organizationId: values.organizationId,
    paymentGateways: values.paymentGateways,
    defaultPaymentGateway: values.defaultPaymentGateway,
    rateLimit: values.rateLimit,
    banorteExclusiveSale: values.banorteExclusiveSale || false,
    updatedAt: new Date(),
    donation: values.donation || false,
    courtesies: values.courtesies || false,
    cashOrderExpirationTime: parseInt(values.cashOrderExpirationTime) || 2
  };
};

function EventsForm(props) {
  const [reactMdeMode, setReactMdeMode] = useState("write");
  const [selectedPaymentGatewayOptions, setSelectedPaymentGateways] = useState(
    []
  );
  const [organizations, setOrganizations] = useState([]);
  const { organizationId, eventId } = props.match.params;
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    setTouched,
    setValues
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      const { eventId } = props.match.params;
      let eventData = handleFormatData(values);
      const eventRef = props.firebase.eventsCollection(values.organizationId);
      if (eventId) {
        await eventRef.doc(eventId).update(eventData);
        return window.location.reload();
      } else {
        eventData.logo = values.logo;
        eventData.startsAt = moment(values.startsAt).toDate();
        eventData.endsAt = moment(values.endsAt).toDate();
        eventData.location = values.location;
        eventData.lat = values.lat;
        eventData.lon = values.lon;
        eventData.createdAt = new Date();
        eventData.headerImage = values.headerImage;
        eventData.cashOrderExpirationTime = parseInt(values.cashOrderExpirationTime);
        const eventDoc = await eventRef.add(eventData);
        return props.history.push(
          `/${values.organizationId}/events/${eventDoc.id}/Venue&Date`
        );
      }
    },
    validationSchema
  });
  useEffect(() => {
    const getData = async () => {
      const organizationsCollection = await props.firebase.getOrganizationCollection();
      const organizationsDocs = await organizationsCollection.get();
      const orgs = organizationsDocs.docs.map(org => ({
        ...org.data(),
        id: org.id
      }));
      setOrganizations(orgs);
      if (eventId) {
        const eventsCollection = await props.firebase.eventsCollection(
          organizationId
        );
        const eventDoc = await eventsCollection.doc(eventId).get();
        const event = eventDoc.data();
        setValues(event);
        const maskedRateLimit = centsToDecimal(event.rateLimit || 0);
        setFieldValue("maskedRateLimit", maskedRateLimit);
        const test = paymentGatewayOptions.filter(payment =>
          event.paymentGateways.includes(payment.value)
        );
        setSelectedPaymentGateways(test);
      }
    };
    getData();
  }, []);

  const defaultPayment = selectedPaymentGatewayOptions.filter(
    p => p.value === values.defaultPaymentGateway
  )[0];
  return (
    <EventsContainer>
      <Form className="form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="eventName">Event's Name</Label>
                <Input
                  type="text"
                  name="name"
                  value={values.name}
                  className="form-control input-lg"
                  id="eventName"
                  onChange={handleChange}
                  placeholder="Event's name"
                  invalid={errors.name && true}
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="organizationId">Organization</Label>
                <Input
                  type="select"
                  name="organizationId"
                  value={values.organizationId}
                  id="organizationId"
                  onChange={handleChange}
                  placeholder="Organization"
                  disabled={values.organizationId && eventId ? true : false}
                  invalid={errors.organizationId && true}
                >
                  <option>Select an option</option>
                  {organizations.map((record, i) => (
                    <option
                      key={record.id}
                      value={record.id}
                      defaultValue={record.id === values.organizationId}
                    >
                      {record.name}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{errors.organizationId}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="eventDescription">Description</Label>
            <MarkdownInput
              value={values.description}
              onChange={value => {
                const e = {
                  target: {
                    name: "description",
                    value
                  }
                };
                handleChange(e);
              }}
            />
          </FormGroup>

          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="eventSlug">Slug</Label>
                <Input
                  type="text"
                  name="slug"
                  value={values.slug}
                  id="eventSlug"
                  onChange={handleChange}
                  placeholder="«friendly-id»"
                  invalid={errors.slug && true}
                />
                <FormFeedback>{errors.slug}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="maskedRateLimit">Rate limit</Label>
                <CurrencyInput
                  value={values.maskedRateLimit}
                  name="maskedRateLimit"
                  className={`form-control ${errors.maskedRateLimit &&
                    touched.maskedRateLimit &&
                    "is-invalid"}`}
                  prefix="$"
                  onChangeEvent={(e, maskedValue, floatValue) => {
                    console.log(maskedValue);
                    if (e.target.name === "maskedRateLimit") {
                      setFieldValue("rateLimit", decimalToCents(floatValue));
                      setFieldValue(
                        "maskedRateLimit",
                        maskedValue.remove(/[$MXN]/g)
                      );
                    }
                  }}
                />
                <FormFeedback>{errors.maskedRateLimit}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <CheckBox
            name="banorte"
            onChange={e => {
              setFieldValue(
                "banorteExclusiveSale",
                !values.banorteExclusiveSale
              );
            }}
            checked={values.banorteExclusiveSale}
            label="Banorte exclusive sale"
          />

          <FormGroup>
            <Label for="eventGmId">Payment Methods</Label>
            <Select
              isMulti
              value={selectedPaymentGatewayOptions}
              name="paymentGateways"
              options={paymentGatewayOptions}
              onChange={options => {
                setSelectedPaymentGateways(options);
                const values = options.map(opt => opt.value);
                setFieldValue("paymentGateways", values);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>

          <FormGroup>
            <Label for="eventGmId">Order expiration time (only for OXXO cash option)</Label>
            <Input
                  type="select"
                  onChange={handleChange}
                  id="cashOrderExpirationTime"
                  name="cashOrderExpirationTime"
                  className="form-control"
                  value={values.cashOrderExpirationTime}
                >
                  <option value="">Select a status</option>
                  <option value="1">1 hour</option>
                  <option value="2">2 hours</option>
                  <option value="3">3 hours</option>
                  <option value="4">4 hours</option>
                  <option value="6">6 hours</option>
                  <option value="8">8 hours</option>
                  <option value="12">12 hours</option>
                  <option value="24">24 hours (1 day)</option>
                  <option value="36">36 hours</option>
                  <option value="48">48 hours (2 days)</option>
            </Input>

            <FormFeedback>{errors.cashOrderExpirationTime}</FormFeedback>
          </FormGroup>

          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="eventGmId">Default payment method</Label>
                <Select
                  value={defaultPayment}
                  name="defaultPaymentGateway"
                  options={selectedPaymentGatewayOptions}
                  onChange={({ value }) => {
                    setFieldValue("defaultPaymentGateway", value);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup>
                <Label for="eventStatus">Status</Label>
                <Input
                  type="select"
                  onChange={handleChange}
                  id="eventStatus"
                  name="eventStatus"
                  className="form-control"
                  value={values.eventStatus}
                >
                  <option value="">Select a status</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                  <option value="CANCELED">Canceled</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <br />
          <CheckBox
            name="donation"
            onChange={e => {
              setFieldValue("donation", !values.donation);
            }}
            checked={values.donation}
            label="Enable donation?"
          />
          <br />
          <CheckBox
            name="courtesies"
            onChange={e => {
              setFieldValue("courtesies", !values.courtesies);
            }}
            checked={values.courtesies}
            label="Enable courtesies?"
          />
          <br />
        </FormFields>
        <FormActions cancelPath={events_path()} submitCaption="Save" />
      </Form>
    </EventsContainer>
  );
}

export default withFirebase(EventsForm);
