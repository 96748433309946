import React from "react";
import { Row, Col, Container, Button } from "reactstrap";
import "./style.scss";
export function Card({
  children,
  title,
  checkin,
  id,
  handleCheckIn,
  handleVoid,
  documentPath
}) {
  return (
    <Container className="orderResume">
      <Row className="tittleResume">
        <Col>{title}</Col>
        <Col className="btnCard">
          {checkin && (
            <>
              <Button
                className="btnSendTickets"
                color="primary"
                onClick={() => handleVoid(documentPath, id)}
              >
                Void
              </Button>{" "}
              <Button
                className="btnSendTickets"
                color="primary"
                onClick={handleCheckIn(id)}
              >
                Check In
              </Button>
            </>
          )}
        </Col>
      </Row>
      <div className="bodyResume">{children}</div>
    </Container>
  );
}
