import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import { compose } from "recompose";
import EventsContainer from "../../components/EventsContainer";
import { Alert } from "reactstrap";
import { asyncForEach } from "../../helpers/utils";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
// import FormActions from "../../components/Admin/FormActions/formik";
// import FormFields from "../../components/Admin/FormFields";

import {
  Form,
  FormGroup,
  Label,
  FormActions,
  FormFields,
  FormFeedback,
  SelectFromCollection
} from "../../components/Formica";


const validationSchema = yup.object({
  user_id: yup
    .string()
    .required()
    .label("User")
});

const initialValues = {
  user_id: "",
  selectedTicket: [],
  selectedProducts: [],
  selectedCourtesy: []
};
const handleFormatData = values => {
  const ticket_typeIds = values.selectedTicket.map(item => ({
    id: item.value,
    name: item.label
  }));
  const coutersys_ticketIds = values.selectedCourtesy.map(item => ({
    id: item.value,
    name: item.label
  }));
  const productIds = values.selectedProducts.map(item => ({
    id: item.value,
    name: item.label
  }));
  return {
    ticket_typeIds,
    coutersys_ticketIds,
    productIds,
    user_id: values.user_id
  };
};
function form(props) {
  const { organizationId, eventId, associationId } = props.match.params;
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [courtesyTickets, setCourtesyTickets] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCourtesy, setSelectedCourtesy] = useState([]);
  const [user, setUser] = useState("");
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    setTouched,
    setValues
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      let associationData = handleFormatData(values);
      associationData.eventId = eventId;
      if (associationId) {
        await props.firebase.updateUserEvents(
          values.user_id,
          associationId,
          associationData
        );
      } else {
        await Promise.all([
          props.firebase.createUserEvents(values.user_id, associationData),
          props.firebase.updateUser({ hasUserEvent: true }, values.user_id)
        ]);
      }
      props.history.push(`/${organizationId}/events/${eventId}/check_staff`);
    },
    validationSchema
  });

  const handleFetchData = async () => {
    const event = await props.firebase.getEvent(organizationId, eventId);
    const { auth_user_check_in_ids } = event.data();
    const userList = [];
    let users = [];
    if (auth_user_check_in_ids) {
      await asyncForEach(auth_user_check_in_ids, async userId => {
        const user = await props.firebase.getUser(userId);
        if (user) {
          const {
            hasUserEvent,
            name: { firstName, lastName }
          } = user;
          userList.push({ value: userId, label: `${firstName} ${lastName} ` });
          if (hasUserEvent) {
            const eventsCollections = await props.firebase
              .getUsersIdEvente(userId)
              .get();
            const myEvents = [];
            eventsCollections.docs.forEach(event => {
              myEvents.push(event.id);
            });
            await asyncForEach(myEvents, async myeventId => {
              if (myeventId === eventId) {
                const userEvent = await props.firebase
                  .getUserEventsCollection(userId)
                  .where("eventId", "==", myeventId)
                  .get();
                if (userEvent.empty) {
                  users.push({
                    value: userId,
                    label: `${firstName} ${lastName} `
                  });
                }
              }
            });
          } else {
            users.push({ value: userId, label: `${firstName} ${lastName} ` });
          }
        }
      });
    }
    setUsers(users);

    const courtesyTicketTypes = await props.firebase
      .courtesyTicketTypesCollection(organizationId, eventId)
      .get();

    const ticketsCollection = await props.firebase.ticketsCollection(
      organizationId,
      eventId
    );

    const productsCollection = await props.firebase
      .productsCollection(organizationId, eventId)
      .get();

    const products = productsCollection.docs.map(product => ({
      value: product.id,
      label: product.data().name
    }));
    const tickets = ticketsCollection.docs.map(ticket => ({
      value: ticket.id,
      label: ticket.data().name
    }));
    const courtesyTickets = courtesyTicketTypes.docs.map(courtesy => ({
      value: courtesy.id,
      label: courtesy.data().name
    }));
    setProducts(products);
    setTickets(tickets);
    setCourtesyTickets(courtesyTickets);

    try {
      // it is execute in Edit Mode
      if (associationId) {
        const { user_id } = props.location.state;
        if (!user_id)
          props.history.push(
            `/${organizationId}/events/${eventId}/check_staff`
          );
        const association = await props.firebase.getUserEvents(
          user_id,
          associationId
        );

        const {
          ticket_typeIds,
          coutersys_ticketIds,
          productIds
        } = association.data();
        const selectedTicket = ticket_typeIds.map(ticket => ({
          value: ticket.id,
          label: ticket.name
        }));
        const selectedCourtesy = coutersys_ticketIds.map(courtesy => ({
          value: courtesy.id,
          label: courtesy.name
        }));
        const selectedProducts = productIds.map(product => ({
          value: product.id,
          label: product.name
        }));
        const selectedUser = userList.find(({ value }) => value === user_id);
        setSelectedTicket(selectedTicket);
        setSelectedProducts(selectedProducts);
        setSelectedCourtesy(selectedCourtesy);
        setUser(selectedUser);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <EventsContainer>
        <Form className="form-horizontal form-compact" onSubmit={handleSubmit}>
          <FormFields>
          <FormGroup>
            <Label for="userIds">Staff Name</Label>
            <Select
              name="userIds"
              isDisabled={associationId ? true : false}
              value={user}
              options={users}
              onChange={e => {
                setUser(e);
                setFieldValue("user_id", e.value);
              }}
              className="basic-select"
              classNamePrefix="select"
            />
            {errors.user_id && <Alert color="danger">{errors.user_id} </Alert>}
          </FormGroup>
          <FormGroup>
            <Label for="ticketIds">Ticket types</Label>
            <Select
              isMulti
              value={selectedTicket}
              name="ticketIds"
              options={tickets}
              onChange={e => {
                setSelectedTicket(e);
                setFieldValue("selectedTicket", e);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
          <FormGroup>
            <Label for="cortesyIds">Courtesy ticket types</Label>
            <Select
              isMulti
              name="cortesyIds"
              value={selectedCourtesy}
              options={courtesyTickets}
              onChange={e => {
                setSelectedCourtesy(e);
                setFieldValue("selectedCourtesy", e);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
          <FormGroup>
            <Label for="ticketIds">Products & Services</Label>
            <Select
              isMulti
              value={selectedProducts}
              name="ticketIds"
              options={products}
              onChange={e => {
                setSelectedProducts(e);
                setFieldValue("selectedProducts", e);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>

          </FormFields>

          <FormActions
            cancelPath={`/${organizationId}/events/${eventId}/check_staff/`}
            submitCaption="Save"
          />
        </Form>
    </EventsContainer>
  );
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(form);
