import React from "react";
import { Switch, Route } from "react-router-dom";

import ViewUsers from "../../../../pages/users/index";
import UserForm from "../../../../pages/users/form";
import Profile from "../../../../pages/profile/index";
import PrivateRoute from "../../../../components/PrivateRoute";

export default function Application() {
  return (
    <Switch>
      <PrivateRoute path="/users/profile" component={Profile} />
      <PrivateRoute path="/users/new" component={UserForm} />
      <PrivateRoute path="/users" component={ViewUsers} />
    </Switch>
  );
}
