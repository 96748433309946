import React, { Component } from "react";
import { withFirebase } from "../../components/Firebase";
import { Col, FormGroup, Label, Row, Form, Button } from "reactstrap";
import FileUpload from "../../components/FileUpload";
import Image from "../../components/Image";
import EventsContainer from "../../components/EventsContainer";
import { Link } from "react-router-dom";
import { events_path } from "../../helpers/paths";

class PDFSettings extends Component {
  state = {
    load: false
  };
  updateEvent = async () => {
    const { eventId, organizationId } = this.props.match.params;
    const eventsCollection = await this.props.firebase.eventsCollection(
      organizationId
    );
    const event = {
      pdfImage: this.state.pdfImage
    };
    eventsCollection
      .doc(eventId)
      .update(event)
      .then(() => {
        this.props.history.push(`/${organizationId}/events/${eventId}`);
      });
  };

  handleGetInformation = async () => {
    const { organizationId, eventId } = this.props.match.params;
    this.setState({ organizationId: organizationId });
    if (eventId) {
      const eventsCollection = await this.props.firebase.eventsCollection(
        organizationId
      );
      const event = await eventsCollection.doc(eventId).get();
      const { pdfImage, name } = event.data();
      this.setState({
        pdfImage: pdfImage || "",
        name,
        load: true
      });
    }
  };

  handleGetUrlImage = url => {
    this.setState(url);
  };
  componentDidMount() {
    this.handleGetInformation();
  }
  render() {
    return (
      <EventsContainer event={this.state}>
        {this.state.load && (
          <Form className="form-horizontal form-material" id="loginform">
            <Row>
              <Col md="12">
                <br />
                <FormGroup>
                  <Label className="label-block" for="logo">
                    PDF IMAGE
                  </Label>
                  {this.state.pdfImage && (
                    <Image
                      imgUrl={this.state.pdfImage}
                      theme={{
                        width: "100px",
                        height: "100px",
                        border: "1px solid #ccc",
                        clear: "both"
                      }}
                    />
                  )}
                  <FileUpload
                    handleUpload={this.props.firebase.uploadImage(
                      "pdfImage",
                      "PDF"
                    )}
                    getUrl={this.handleGetUrlImage}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-right form-actions">
              <Link to={events_path()}>Cancel</Link> or{" "}
              <Button onClick={this.updateEvent} color="primary">
                Save
              </Button>
            </div>
          </Form>
        )}
      </EventsContainer>
    );
  }
}
export default withFirebase(PDFSettings);
