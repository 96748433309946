import React, { Component } from "react";
import { withFirebase } from "../../components/Firebase";
import { Col, Row, Button } from "reactstrap";
import FileUpload from "../../components/FileUpload";
import Image from "../../components/Image";
import EventsContainer from "../../components/EventsContainer";
import { Link } from "react-router-dom";
import { events_path } from "../../helpers/paths";

import "./index.scss";

import {
  Form,
  FormGroup,
  Label,
  FormActions,
  FormFields,
  FormFeedback,
  DropzoneUploader
} from "../../components/Formica";

class Design extends Component {
  state = {
    load: false
  };
  updateEvent = async () => {
    const { eventId, organizationId } = this.props.match.params;
    const eventsCollection = await this.props.firebase.eventsCollection(
      organizationId
    );
    const event = {
      headerImage: this.state.headerImage,
      logo: this.state.logo
    };
    eventsCollection
      .doc(eventId)
      .update(event)
      .then( ()=> {
        this.props.history.push(`/${organizationId}/events/${eventId}`);
      });
  };

  handleGetInformation = async () => {
    const { organizationId, eventId } = this.props.match.params;
    this.setState({ organizationId: organizationId });
    if (eventId) {
      const eventsCollection = await this.props.firebase.eventsCollection(
        organizationId
      );
      const event = await eventsCollection.doc(eventId).get();
      const { headerImage, logo, name } = event.data();
      this.setState({
        headerImage,
        logo,
        name,
        load: true
      });
    }
  };
  handleGetUrlImage = url => {
    this.setState(url);
  };
  componentDidMount() {
    this.handleGetInformation();
  }

  render() {
    return (
      <EventsContainer event={this.state}>
        {this.state.load && (
          <Form className="form-horizontal form-material" id="loginform">
            <FormGroup>
              <Label className="label-block" for="logo">
                Logo
              </Label>
              <br />
              <DropzoneUploader
                path="events/logo/"
                name="headerImage"
                onChange={(url) => this.handleGetUrlImage({ logo: url })}
                image={this.state.logo}
                className="logo-uploader"
                legend="Recomended size|320x320"
                previewImage={this.state.logo}
              />

            </FormGroup>

            <FormGroup>
              <Label className="label-block" for="eventheaderImage">
                Event header Image
              </Label>
              <DropzoneUploader
                path="events/headerImage/"
                name="headerImage"
                onChange={(url) => this.handleGetUrlImage({ headerImage: url })}
                image={this.state.headerImage}
                className="header-uploader"
                legend="Recomended size|320x320"
                previewImage={this.state.headerImage}
              />

              {/* <ImageUploaderWidget
                {...this.state}
                image={this.state.headerImage}
                name="headerImage"
                className="header-uploader"
                handleUpload={this.props.firebase.uploadImage(
                  "headerImage",
                  "events"
                )}
                legend="Recomended size|1500x237"
                getUrl={this.handleGetUrlImage}
              /> */}

            </FormGroup>

            <div className="text-right form-actions">
              <Link to={events_path()}>Cancel</Link> or{" "}
              <Button onClick={this.updateEvent} color="primary">
                Save
              </Button>
            </div>
          </Form>
        )}
      </EventsContainer>
    );
  }
}
export default withFirebase(Design);
