import React from "react";
import { Link } from "react-router-dom";

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge
} from "reactstrap";

import dotsIcon from "../../images/icons/dots.svg";
import permissions from "../../helpers/permissions";

export default function Coupon(props) {
  const {
    name,
    parentId,
    code,
    usedTimes,
    quantity,
    status,
    id
  } = props.record;
  const { organizationId, eventId } = props;

  return (
    <tr key={id} role="row" className="coupon-row">
      <td>
        {name} {parentId && <Badge color="secondary">from batch</Badge>}
      </td>
      <td className="text-danger coupon-code">{code}</td>
      <td>
        {usedTimes} / {quantity}
      </td>
      <td>{status}</td>

      <td className="text-right full-width">
        <UncontrolledDropdown direction="left" className="row-options-trigger">
          <DropdownToggle tag="span">
            <img src={dotsIcon} alt="Options" />
          </DropdownToggle>
          <DropdownMenu>
            {permissions.can("coupons", "edit") && (
              <DropdownItem
                tag={Link}
                to={`/${organizationId}/events/${eventId}/coupons/${id}/edit`}
              >
                Edit
              </DropdownItem>
            )}

            <DropdownItem divider />

            {permissions.can("coupons", "delete") && (
              <DropdownItem
                onClick={() =>
                  props.confirmDestroy(organizationId, eventId, id)
                }
                className="text-danger"
              >
                Destroy
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}
