import React from "react";
import { Card } from "./Card";
import { Item } from "./Item";
export function ListProducts({ product, handleCheckIn, handleVoid }) {
  return (
    <>
      <Card
        title={product.id}
        checkin={true}
        id={product.id}
        handleCheckIn={handleCheckIn}
        handleVoid={handleVoid}
        documentPath={product.documentPath}
      >
        <Item title="Product Type" value={product.productName} />
        <Item title="Status" value={product.status} />
      </Card>
      <br />
    </>
  );
}
