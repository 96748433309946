import React from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../../components/Firebase";
import permissions from "../../helpers/permissions";
import axios from "axios";
import { compose } from "recompose";
import { connect } from "react-redux";
import { roleLabel } from "../../helpers/roles";
import { environment } from "../../components/Firebase/config";

import {
  Table,
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import TableHead from "../../components/Admin/TableHead";
import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";

import dotsIcon from "../../images/icons/dots.svg";
import "./index.scss";

class ViewUsers extends React.Component {
  state = { users: [], alert: null };
  async componentDidMount() {
    const organizationsCollection = await this.props.firebase.getOrganizationCollection();
    const fbResult = await organizationsCollection.get();
    let organizations = {};
    fbResult.forEach(org => {
      organizations[org.id] = org.data().name;
    });
    this.setState({ organizations });

    if (permissions.adminPresent()) {
      this.handleGetAllUsers();
    } else {
      this.handleGetScopedUsers();
    }
  }
  handleGetAllUsers = async () => {
    const self = this;
    const snapshot = await this.props.firebase.usersCollection();

    let users = [];
    snapshot
      .where("type", "==", "administrative")
      .orderBy("name.firstName")
      .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          const id = doc.id;
          const user = { ...doc.data(), id, ...doc.data().roles };
          users.push(user);
        });
        self.setState(
          {
            users
          },
          () => {
            users = [];
          }
        );
      });
  };

  handleGetScopedUsers = async () => {
    const self = this;

    const administrableOrganizations = permissions.roles.map(role => {
      if (role.role === "organization_admin" || role.role === "organizer")
        return role.organization;
    });

    const tick = Date.now();
    const promises = [];
    for await (const orgId of administrableOrganizations) {
      const usersRef = self.props.firebase.db
        .collection("users")
        .where("type", "==", "administrative")
        .where("organizationIds", "array-contains", orgId)
        .where("isAdmin", "==", false)
        .orderBy("name.firstName")
        .get();
      promises.push(usersRef);
    }

    const log = v => console.log(`${v} \n Elapsed: ${Date.now() - tick}ms`);

    const usersList = (await Promise.all(promises)).flatMap(snapshot =>
      snapshot.docs.map(user => user.data())
    );
    log("usersFinished");
    this.setState({ users: usersList });
  };

  destroy = async uid => {
    console.log(uid);
    axios
      .delete(
        `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/app/users/${uid}`
      )
      .then(response => {
        console.log(response);
        this.hideAlert();
      })
      .catch(error => {
        console.log(error);
      });
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  cancelDestroy() {
    this.hideAlert();
  }

  confirmDestroy = uid => {
    console.log(uid);
    this.setState({
      alert: (
        <DestroyConfirmation
          onConfirm={() => this.destroy(uid)}
          onCancel={() => this.cancelDestroy()}
        />
      )
    });
  };

  render() {
    return (
      <>
        {this.state.alert}
        <div className="section-header">
          <div className="text-right table-actions pull-right">
            {permissions.can("events", "create") && (
              <Link to={`/users/new`} className="btn btn-primary">
                New user
              </Link>
            )}
          </div>

          <Breadcrumb>
            <BreadcrumbItem active>User</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Table className="responsive" role="table">
          <TableHead cols={["First name", "Last name", "Email", "Roles", ""]} />

          <tbody>
            {this.state.users.map((record, i) => (
              <tr className="user-row" key={i}>
                <td>{record.name.firstName}</td>
                <td>{record.name.lastName}</td>
                <td>{record.email}</td>
                <td>
                  {record.isAdmin && "Global admin"}
                  {!record.isAdmin && (
                    <div>
                      {record.roles &&
                        record.roles.map((role, i) => (
                          <span key={i}>
                            {roleLabel(role.role)}:{" "}
                            {this.state.organizations[role.organization]}
                            <br />
                          </span>
                        ))}
                    </div>
                  )}
                </td>
                <td className="text-right full-width">
                  <UncontrolledDropdown
                    direction="left"
                    className="row-options-trigger"
                  >
                    <DropdownToggle tag="span">
                      <img src={dotsIcon} alt="Options" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem divider />

                      {permissions.can("coupons", "delete") && (
                        <DropdownItem
                          onClick={() =>
                            this.confirmDestroy(record.id)
                          }
                          className="text-danger"
                        >
                          Destroy
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(ViewUsers);
