import { organizationConstants } from "../constants";

export function currentorganization(state = {}, action) {
  switch (action.type) {
    case organizationConstants.SET_ORGANIZATION:
      return {
        ...state,
        ...{
          id: action.organization.id,
          name: action.organization.name,
          code: action.organization.code,
          slug: action.organization.slug
        }
      };
    default:
      return state;
  }
}
