import React, { useEffect, useState } from "react";
import PieChart from "../../components/PieChart";
import XYChart from "../../components/XYChart";
import app from "firebase/app";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import permissions from "../../helpers/permissions";
import { asyncForEach } from "../../helpers/utils";
import { Row, Col, FormGroup, Input, Form } from "reactstrap";
import ordersService from "../../services/orders.service";
import "./styles.scss";

am4core.useTheme(am4themes_animated);

export default function Dashboard() {
  const [salesPerEvent, setSalesPerEvent] = useState([]);
  const [labels, setLabels] = useState([]);
  const [sales, setSales] = useState([]);
  const [granTotal, setGranTotal] = useState(0);
  const [organizationId, setOrganizationId] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState("");
  const organizationRef = app.firestore().collection("organizations");

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  // const colors = ["#3f51b5", "#2196f3", "#9c27b0", "#673ab7"];

  useEffect(() => {
    const onMount = async () => {
      if (permissions.adminPresent()) {
        const orgCollection = await organizationRef.get();
        const organizations = orgCollection.docs.map(org => ({
          id: org.id,
          name: org.data().name
        }));
        setOrganizations(organizations);
        setOrganizationId(organizations[0].id);
      } else {
        try {
          const list = [];
          await asyncForEach(permissions.organizationIds, async orgId => {
            const organization = await organizationRef.doc(orgId).get();
            list.push({ name: organization.data().name, id: organization.id });
          });
          setOrganizations(list);
          setOrganizationId(list[0].id);
        } catch (error) {}
      }
    };
    onMount();
  }, []);

  const getEvents = async () => {
    const eventsRef = organizationRef.doc(organizationId).collection("events");
    const eventsCollection = await eventsRef.get();
    const events = eventsCollection.docs.map(event => ({
      id: event.id,
      name: event.data().name
    }));
    setEvents(events);
  };

  useEffect(() => {
    if (organizationId) {
      const filters = [`q[organizationId_eq]=${organizationId}`];
      getTotalSales(filters);
      getEvents();
    }
  }, [organizationId]);

  useEffect(() => {
    if (eventId) {
      const filters = [`q[eventId_eq]=${eventId}`];
      getTotalSales(filters);
    }
  }, [eventId]);

  const getTotalSales = async filters => {
    const salesReport = await ordersService.totalSales({
      filters
    });
    setLabels(salesReport.events);
    setSales(salesReport.results);
    setSalesPerEvent(salesReport.totalPerEvents);
    const total = salesReport.totalPerEvents
      .map(event => event.subtotal)
      .reduce((a, b) => a + b, 0);
    setGranTotal(total);
  };

  return (
    <>
      <Row>
        <Col xs="6">
          <select
            value={organizationId}
            className="form-control"
            onChange={e => setOrganizationId(e.target.value)}
          >
            {organizations.map(org => {
              return (
                <option style={{ color: "black" }} key={org.id} value={org.id}>
                  {org.name}
                </option>
              );
            })}
          </select>
        </Col>
        <Col xs="6">
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="event"
                    onChange={e => setEventId(e.target.value)}
                  >
                    <option value="">-All Events-</option>
                    {events.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              {/* <Col md={6}>
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="day"
                    onChange={e => {
                      console.log(e);
                    }}
                  >
                    <option value="">All</option>
                  </Input>
                </FormGroup>
              </Col> */}
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h3>Sales</h3>
        </Col>
        <Col md={6}>
          <Form>
            <Row className="salesReportInfo" form>
              <Col md={4}>
                <div className="indicator">
                  <h3>{formatter.format(granTotal)}</h3>
                  <small>Sales</small>
                </div>
              </Col>
              <Col md={4}>
                {/* TODO: Show the transactions and Avrg Tickets */}
                {/* <FormGroup>
                  <Label for="exampleEmail">82</Label>
                  <FormText>Transactions</FormText>
                </FormGroup> */}
              </Col>
              <Col md={4}>
                {/* <FormGroup>
                  <Label for="exampleEmail">$ 739.98</Label>
                  <FormText>Avrg. Tickets</FormText>
                </FormGroup> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="graph-title">Total Sales</p>
          <XYChart data={sales} labels={labels} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <p className="graph-title">Sales Per Event</p>
          <PieChart
            data={salesPerEvent}
            labels={labels}
            dataField="subtotal"
            name="salesPerEvent"
          />
        </Col>
      </Row>
    </>
  );
}
