import React from "react";

import { Input } from "reactstrap";
import { titleize } from "../../../helpers/string";

export default function SelectFromArray({
  collection,
  name,
  value,
  placeholder,
  onChange,
  includeBlank,
  id,
  className,
  invalid
}) {

  className = className || "no-class";
  return (
    <Input
      type="select"
      name={name}
      value={value}
      id={id || `field-${name}`}
      onChange={onChange}
      placeholder={placeholder}
      className={`SelectFromArray ${className}`}
      invalid={invalid}
    >
      {includeBlank &&
        <option value="">{includeBlank || ""}</option>
      }

      {collection && collection.map((item, i) => (
        <option
          key={item}
          value={item}
          defaultValue={item === value}
        >
          {titleize(item)}
        </option>
      ))}
    </Input>
  );
}
