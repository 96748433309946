import React from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import dotsIcon from "../../images/icons/dots.svg";
import permissions from "../../helpers/permissions";

export default function BatchOrder(props) {
  const {
    id,
    ticketTypeName,
    phaseName,
    batchSize,
    fulfilled,
  } = props.record;

  const { organizationId, eventId } = props;

  const showOrder = orderId => {
    props.history.push(`/${organizationId}/events/${eventId}/batch_orders/${orderId}`)
  }

  return (
    <tr key={id} role="row" className="batch-order-row">
      <td onClick={() => showOrder(id)}>{id}</td>
      <td onClick={() => showOrder(id)}>{ticketTypeName}</td>
      <td onClick={() => showOrder(id)}>{phaseName}</td>
      <td onClick={() => showOrder(id)}>{batchSize} tickets</td>
      <td onClick={() => showOrder(id)}>{fulfilled ? "Success" : "Working"}</td>
      <td className="text-right">
        <UncontrolledDropdown
          direction="left"
          className="row-options-trigger"
        >
          <DropdownToggle tag="span">
            <img src={dotsIcon} alt="Options" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem 
              tag={Link} 
              to="#" 
              onClick={() => showOrder(id)}
            >
              Details
            </DropdownItem>
            {permissions.can("phases", "delete") && (
              <DropdownItem
                onClick={() =>
                  props.confirmDestroy(organizationId, eventId, id)
                }
                className="text-danger"
              >
                Destroy
                </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}
