import React from "react";
import { Badge } from "reactstrap";

export default function StatusBadge({ status }) {
  const badgeColors = {
    Complete: "success",
    Pending: "warning",
    "On Hold": "danger"
  };
  return (
    <Badge color={badgeColors[status]}>
      {status.toLowerCase().capitalize()}
    </Badge>
  );
}
