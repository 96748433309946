import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import HandypassLogo from "../../images/handypass_logo.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// redux
import { userSignedIn } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./index.scss";

class Login extends Component {
  state = { email: "", password: "", error: null };
  onSubmit = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    const { doSignInWithEmailAndPassword, canLogIn } = this.props.firebase;

    if (await canLogIn(email)) {
      doSignInWithEmailAndPassword(email, password)
        .then(async userData => {
          // destructure received data from user
          const {
            displayName,
            email,
            emailVerified,
            isAnonymous,
            uid,
            roles
          } = userData.user;
          // assign data received from user to currentUser on redux store
          this.props.userSignedIn(
            displayName,
            email,
            emailVerified,
            isAnonymous,
            uid,
            roles
          );
          // redirect to dashboard if successfully logged in
          this.props.history.push("/events");
        })
        .catch(error => {
          this.setState({ error });
        });
    } else {
      this.setState({
        error: {
          message:
            "There is no user with this email or you do not have the necessary permissions."
        }
      });
    }
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";
    return (
      <div className="login-register">
        {this.props.currentUser.ready && this.props.currentUser.isLoggedIn ? (
          <Redirect to="/" />
        ) : (
          <div className="v-centered">
              <form className="login-form" id="loginform">
                <img
                  src={HandypassLogo}
                  alt="Handypass"
                  className="logo"
                />
                <input
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Email Address"
                />
                <input
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Password"
                />
                <div className="text-center actions">
                  <button
                    className="btn btn-block btn-lg btn-blue"
                    disabled={isInvalid}
                    onClick={this.onSubmit}
                  >
                    Sign In
                  </button>
                  {error !== null && <p class="error-message">{error.message}</p>}
                </div>
              </form>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({ currentUser: state.currentUser }),
    dispatch => bindActionCreators({ userSignedIn }, dispatch)
  )
)(Login);
