import React from "react";
import { Nav, NavItem, NavLink, Col } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import permissions from "../../helpers/permissions";
import { compose } from "recompose";
import placeholder from "../../images/placeholder.png";

import {
  organization_event_path,
  edit_organization_event_path,
  organization_event_phases_path,
  organization_event_ticket_types_path,
  organization_event_products_path,
  activeLink
} from "../../helpers/paths";

function EventSidebar(props) {
  const { eventId, organizationId } = props.match.params;
  const { path: currentPath } = props.match;
  const { name, logo } = props.event || { name: "No name", logo: placeholder };

  return (
    <Col sm={2} className="event-sidebar-menu">
      <Nav vertical className="event-sidebar">
        <NavItem>
          {logo && <img src={logo} className="event-logo" alt={name} />}
        </NavItem>
        <NavItem className="header">Settings</NavItem>

        <NavItem>
          <NavLink
            className={activeLink(currentPath)}
            tag={Link}
            to={organization_event_path(organizationId, eventId)}
          >
            Sales Report
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeLink(currentPath, "checkin_report")}
            tag={Link}
            to={organization_event_path(
              organizationId,
              eventId,
              "/checkin_report"
            )}
          >
            Check In Report
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeLink(currentPath, "edit")}
            tag={Link}
            to={edit_organization_event_path(organizationId, eventId)}
          >
            General Information
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeLink(currentPath, "Venue&Date")}
            tag={Link}
            to={organization_event_path(organizationId, eventId, "/Venue&Date")}
          >
            Venue & Dates
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeLink(currentPath, "products")}
            tag={Link}
            to={organization_event_products_path(organizationId, eventId)}
          >
            Products & Services
          </NavLink>
        </NavItem>

        {permissions.can("ticket_types", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(currentPath, "ticket_types")}
              tag={Link}
              to={organization_event_ticket_types_path(organizationId, eventId)}
            >
              Ticket Types
            </NavLink>
          </NavItem>
        )}
        {permissions.can("phases", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(currentPath, "phases")}
              tag={Link}
              to={organization_event_phases_path(organizationId, eventId)}
            >
              Phases
            </NavLink>
          </NavItem>
        )}

        <NavItem>
          <NavLink
            className={activeLink(currentPath, "courtesy_ticket_types")}
            tag={Link}
            to={`/${organizationId}/events/${eventId}/courtesy_ticket_types`}
          >
            Courtesy ticket types
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeLink(currentPath, "courtesies")}
            tag={Link}
            to={`/${organizationId}/events/${eventId}/courtesies`}
          >
            Courtesies
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeLink(currentPath, "coupons")}
            tag={Link}
            to={`/${organizationId}/events/${eventId}/coupons`}
          >
            Coupons
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeLink(currentPath, "batch_orders")}
            tag={Link}
            to={`/${organizationId}/events/${eventId}/batch_orders`}
          >
            Tickets in batches
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeLink(currentPath, "check_staff")}
            tag={Link}
            to={`/${organizationId}/events/${eventId}/check_staff`}
          >
            Check in Staff
          </NavLink>
        </NavItem>

        <NavItem className="header">Design</NavItem>

        <NavItem>
          <NavLink
            className={activeLink(currentPath, "Design")}
            tag={Link}
            to={organization_event_path(organizationId, eventId, "/Design")}
          >
            Design
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeLink(currentPath, "pdf_settings")}
            tag={Link}
            to={`/${organizationId}/events/${eventId}/pdf_settings`}
          >
            PDF
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} disabled to={""}>
            Danger Zone
          </NavLink>
        </NavItem>
      </Nav>
    </Col>
  );
}

export default compose(
  withRouter,
  connect(state => ({ currentUser: state.currentUser }))
)(EventSidebar);
