import React from "react";
import "../../../css/loader-spinner/react-spinner-loader.css";
import Loader from "react-loader-spinner";


export default function TableLoadingRow({colspan}) {
  return (
    <tr>
      <td colSpan={colspan} className="text-center">
        <Loader
          type="Oval"
          color="#ddd"
          height={50}
          width={50}
          visible={true}
        />
        <small>LOADING</small>
      </td>
    </tr>
  );
}
