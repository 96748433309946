import { userConstants } from "../constants";

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.LOAD_USERS_REQUEST:
      return { ...state, ...{ loading: true, users: [] } };
    case userConstants.LOAD_USERS_SUCCESS:
      return { ...state, ...{ loading: false, users: action.users } };
    default:
      return state;
  }
}
