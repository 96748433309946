import { userConstants } from "../constants";

export function loadUsersRequest() {
  return { type: userConstants.LOAD_USERS_REQUEST };
}

export function loadUsersSuccess(data) {
  console.log("load users success " + data);
  return { type: userConstants.LOAD_USERS_SUCCESS, users: data };
}

export function loadUserRequest() {
  return { type: userConstants.LOAD_USER_REQUEST };
}

export function loadUserAttributes(data) {
  return {
    type: userConstants.LOAD_USER_SUCCESS,
    user: data
  };
}

export function userSignedIn(
  displayName,
  email,
  emailVerified,
  isAnonymous,
  uid,
  roles
) {
  return {
    type: userConstants.USER_SIGNED_IN,
    displayName,
    email,
    emailVerified,
    isAnonymous,
    uid,
    roles
  };
}

export function userSignedOut() {
  return {
    type: userConstants.USER_SIGNED_OUT
  };
}
