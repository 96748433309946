import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import permissions from "../../helpers/permissions";
import { compose } from "recompose";

import Organization from "./_organization";
import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";

import { Table} from "reactstrap";

import "./index.scss";
import TableHead from "../../components/Admin/TableHead";

class Organizations extends React.Component {
  state = {
    organizations: [],
    nextToken: null
  };

  async componentDidMount() {
    const uid = this.props.currentUser.uid;
    await permissions.getRolesPermissions(uid);

    var self = this;
    var orgs = await this.props.firebase.getOrganizations();
    orgs.onSnapshot(function(querySnapshot) {
      var organizations = [];
      querySnapshot.forEach(function(doc) {
        organizations.push({ id: doc.id, ...doc.data()});
      });
      self.setState({ organizations });
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  cancelDestroy() {
    this.hideAlert();
  }

  destroy(id) {
    this.props.firebase.deleteOrganization(id).then(record => {
      const organizations = [
        ...this.state.organizations.filter(r => {
          return r.id !== id;
        })
      ];
      this.setState({ organizations });
      this.hideAlert();
    });
  }

  confirmDestroy = id => {
    this.setState({
      alert: (
        <DestroyConfirmation
          onConfirm={() => this.destroy(id)}
          onCancel={() => this.cancelDestroy()}
        />
      )
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.alert}

        <div className="section-header">
          <div className="text-right table-actions pull-right">
            {permissions.can("organizations", "create") && (
              <Link
                to={`/organizations/new`}
                className="btn btn-primary"
              >
                Create Organization
              </Link>
            )}
          </div>

          <h5 className="section-title">Organizations</h5>
        </div>

        <Table className="responsive" role="table">
          <TableHead cols={["Logo", "Name", "Status", ""]} />
          <tbody>
            {this.state.organizations.map(record =>
              <Organization
                record={record}
                key={record.id}
                confirmDestroy={this.confirmDestroy}
              />
            )}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(Organizations);
