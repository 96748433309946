import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, Input } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import EventsContainer from "../../components/EventsContainer";
import permissions from "../../helpers/permissions";
// import algoliasearch from "algoliasearch";
import Pagination from "react-js-pagination";
import axios from "axios";
import ReactExport from "react-data-export";

import moment from "moment-timezone";

import TableHead from "../../components/Admin/TableHead";
import Courtesy from "./_courtesy";

import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";

import "./courtesies.scss";
import CourtesiesService from "../../services/courtesies.service";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function CourtesiesList({ match, firebase, currentUser }) {
  const { organizationId, eventId } = match.params;
  const [alert, setAlert] = useState(null);
  const [courtesies, setCourtesies] = useState([]);
  const [event, setEvent] = useState({});
  const [search, setSearch] = useState("");
  const [queryLimit, setQueryLimit] = useState(50);
  const [pageSize, setPageSize] = useState([0]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const [triggerDownload, setTriggerDownload] = useState(false);
  const [excelData, setExcelData] = useState([]);
  useEffect(() => {
    getData(currentPage);
  }, []);
  const getData = async page => {
    let offset = page === 1 ? 0 : (page - 1) * queryLimit;
    const filters = [`q[eventId_eq]=${eventId}`];
    const response = await CourtesiesService.all({
      queryLimit,
      offset,
      filters
    });
    setRecordsCount(response.recordsCount);
    setPageSize(response.pageSize);
    setLoading(false);
    setCourtesies(response.courtesies);
  };

  // const getCourtesyTicketsFromAlgolia = async () => {
  //   const response = await index.search({
  //     query: search,
  //     facetFilters: [`eventID:${eventId}`],
  //     page: pagination.activePage,
  //     hitsPerPage: 25
  //   });

  //   setPagination({
  //     itemsCountPerPage: response.hitsPerPage,
  //     totalItemsCount: response.nbHits,
  //     activePage: response.page
  //   });
  //   setCourtesies(response.hits);
  // };

  // useEffect(() => {
  //   const getEvent = async () => {
  //     var event = await firebase.getEvent(organizationId, eventId);
  //     setEvent(event.data());
  //   };
  //   getEvent();
  // }, []);

  // useEffect(() => {
  //   getCourtesyTicketsFromAlgolia();
  // }, [courtesies.length, search, pagination.activePage]);

  const destroy = async (orgId, eventId, id) => {
    firebase.deleteCourtesy(orgId, eventId, id).then(record => {
      const list = [
        ...courtesies.filter(r => {
          return r.id !== id;
        })
      ];
      setCourtesies(list);
      hideAlert();
    });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const cancelDestroy = () => {
    hideAlert();
  };

  const confirmDestroy = (orgId, eventId, id) => {
    setAlert(
      <DestroyConfirmation
        onConfirm={() => destroy(orgId, eventId, id)}
        onCancel={() => cancelDestroy()}
      />
    );
  };

  const downloadExcel = async () => {
    const filters = [`q[eventId_eq]=${eventId}`];
    const response = await CourtesiesService.report({ filters });
    setExcelData(response.courtesies);
    setTriggerDownload(true);
    setTriggerDownload(false);
  };

  async function handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    setCurrentPage(pageNumber);
    setLoading(true);
    await getData(pageNumber);
  }

  return (
    <EventsContainer event={event}>
      {alert}
      <Row className="section-header">
        <Col md="2">
          <div className="search-order">
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="search..."
            />
          </div>
        </Col>
        <Col md="2">
          <Button onClick={downloadExcel}>Export .XLS</Button>
          {triggerDownload && (
            <ExcelFile
              hideElement
              filename={`Courtesies-${moment().format("DD-MM-YY_HHmmss")}`}
            >
              <ExcelSheet data={excelData} name="courtesies">
                <ExcelColumn
                  label="COURTESY TICKET TYPE"
                  value={col => col.CourtesyTicketType.name}
                />
                <ExcelColumn label="COURTESY NAME" value="name" />
                <ExcelColumn label="CODE" value="code" />
                <ExcelColumn label="Quantity" value={c => `${c.quantity}`} />
                <ExcelColumn
                  label="Redeemed tickets"
                  value={c => `${c.redeemedTickets}`}
                />
                <ExcelColumn label="STATUS" value="status" />
                <ExcelColumn
                  label="STARTS AT"
                  value={col =>
                    moment(new Date(col.startsAt)).format("YYYY/MM/DD HH:mm")
                  }
                />
                <ExcelColumn
                  label="ENDS AT"
                  value={col =>
                    moment(new Date(col.endsAt)).format("YYYY/MM/DD HH:mm")
                  }
                />
              </ExcelSheet>
            </ExcelFile>
          )}
        </Col>
        <Col md="8">
          <div className="text-right table-actions pull-right">
            {permissions.can("courtesies", "create") && (
              <Link
                to={`/${organizationId}/events/${eventId}/courtesies/new`}
                className="btn btn-primary"
              >
                New courtesy
              </Link>
            )}
          </div>
        </Col>
      </Row>
      <br />
      <Table>
        <TableHead
          cols={[
            "Courtesy ticket type",
            "Courtesy name",
            "Code",
            "Usage",
            "Status",
            ""
          ]}
        />
        <tbody>
          {courtesies.map((record, i) => (
            <Courtesy
              key={record.id}
              record={record}
              organizationId={organizationId}
              eventId={eventId}
              confirmDestroy={confirmDestroy}
            />
          ))}
        </tbody>
      </Table>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={queryLimit}
        totalItemsCount={recordsCount}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
      {pageSize > 0 && (
        <span>
          Listing {pageSize} of {recordsCount} records found.
        </span>
      )}
    </EventsContainer>
  );
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(CourtesiesList);
