import React from "react";

export default function TableHead({cols}) {
  return (
    <thead>
      <tr role="row">
        {cols.map((header, i) =>
          <th key={i} role="columnheader">{header}</th>
        )}
      </tr>
    </thead>
  );
}
