import React, { useEffect, useState } from "react";
import EventsContainer from "../../../components/EventsContainer";
import checkinReportService from "../../../services/checkinReport.service";
import { withFirebase } from "../../../components/Firebase";

import TableHead from "../../../components/Admin/TableHead";
import { Table, FormGroup, Label, Input, Form, Row, Col } from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

const Sugar = require("sugar");

const ownerTypes = {
  product: "Products",
  courtesy_ticket_type: "Courtesy Ticket Types",
  phase: "Phase"
};

const CheckInReport = props => {
  const { organizationId, eventId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [checkinsCount, setCheckinsCount] = useState(0);
  const [activeFilter, setActiveFilter] = useState({});
  const [selectedUser, setSelectedUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [details, setDetails] = useState([]);
  const [ticketTypeDetails, setTicketTypeDetails] = useState([]);
  const [event, setEvent] = useState({});

  const [startAt, setStartAt] = useState(
    Sugar.Date.rewind(new Date(), "1 week")
  );
  const [endAt, setEndAt] = useState(new Date());

  const loadFilters = async () => {
    checkinReportService
      .getFilters([`q[eventId_eq]=${eventId}`])
      .then(({ users, owners }) => {
        setUsers(users.map(user => ({ id: user.id, name: user.name })));
        setOwners(owners.groupBy(owner => owner.ownerType));
      })
      .catch(error => {
        console.log(error);
      });
  };

  function handleStartAtChange(date) {
    setActiveFilter({
      ...activeFilter,
      createdAt_between: [date, endAt]
    });
    setStartAt(date);
  }

  function handleEndAtChange(date) {
    setActiveFilter({
      ...activeFilter,
      createdAt_between: [startAt, date]
    });
    setEndAt(date);
  }

  const getEventCheckinReport = filters => {
    setLoading(true);
    checkinReportService.count(filters).then(({ checkins, details }) => {
      const groupedData = details.groupBy(owner => {
        return `${owner.ownerType}`;
      });
      if (groupedData.phase) {
        const byTicketType = groupedData.phase.groupBy(
          r => `${r.ticketTypeId}-${r.ticketTypeName}`
        );
        setTicketTypeDetails(byTicketType);
        delete groupedData.phase;
      }

      setDetails(groupedData);
      setCheckinsCount(checkins);
      setLoading(false);
    });
  };

  useEffect(() => {
    // { organizationId, eventId }
    let filter = [
      `q[organizationId_eq]=${organizationId}`,
      `q[eventId_eq]=${eventId}`
    ];

    if (activeFilter.organizationId) {
      filter.push(`q[organizationId_eq]=${activeFilter.organizationId}`);
    }

    if (activeFilter.ownerId) {
      filter.push(`q[ownerId_eq]=${activeFilter.ownerId}`);
    }

    if (activeFilter.eventId) {
      filter.push(`q[eventId_eq]=${activeFilter.eventId}`);
    }

    if (activeFilter.userId) {
      setSelectedUser(users.find(u => u.id === activeFilter.userId));
      filter.push(`q[userId_eq]=${activeFilter.userId}`);
    }

    if (activeFilter.createdAt_between) {
      const dates = activeFilter.createdAt_between;
      console.log(dates);
      const [s, e] = dates;
      filter.push(
        `q[createdAt_between]=${s.toISOString()}..${e.toISOString()}`
      );
    }

    getEventCheckinReport(filter);
  }, [activeFilter]);

  useEffect(() => {
    getEvent();
    loadFilters();
    // getEventCheckinReport();
  }, []);

  const getEvent = async () => {
    const eventDoc = await props.firebase.getEvent(organizationId, eventId);
    console.log(eventDoc);
    let eventData = { id: eventDoc.id, ...eventDoc.data() };
    setEvent(eventData);
  };

  return (
    <EventsContainer>
      <Form className="orders-filters">
        <br />
        <Row form>
          <Col sm="6" md="3">
            <FormGroup>
              <Label for="exampleSelect">Phase / Product / Courtesy</Label>
              <Input
                type="select"
                name="select"
                id="event-selector"
                onChange={e =>
                  setActiveFilter({
                    ...activeFilter,
                    ownerId: e && e.target.value
                  })
                }
              >
                <option value="">- All -</option>
                {Object.keys(owners).map(ownerType => {
                  return (
                    <optgroup key={ownerType} label={ownerType}>
                      {owners[ownerType].map(owner => {
                        return (
                          <option key={owner.id} value={owner.id}>
                            {owner.name}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>

          <Col sm="6" md="3">
            <FormGroup>
              <Label for="exampleSelect">User</Label>
              <Input
                type="select"
                name="select"
                id="event-selector"
                onChange={e =>
                  setActiveFilter({
                    ...activeFilter,
                    userId: e && e.target.value
                  })
                }
              >
                <option value="">- All -</option>
                {users.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>

          <Col md="3" xs="12" sm="12">
            <Label>Filter by date range</Label>
            <br />
            <div className="orders-date-range">
              <DatePicker
                selected={startAt}
                onChange={handleStartAtChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="yyyy-MM-dd H:mm"
                popperPlacement="top-start"
                timeCaption="Time"
                showMonthDropdown
                withPortal
              />
            </div>
          </Col>
          <Col md="3" xs="12" sm="12">
            <Label>&nbsp;</Label>
            <br />
            <div className="orders-date-range">
              <DatePicker
                selected={endAt}
                onChange={handleEndAtChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="yyyy-MM-dd H:mm"
                timeCaption="Time"
                popperPlacement="top-start"
                showMonthDropdown
                withPortal
              />
            </div>
          </Col>
        </Row>
      </Form>

      <div className="clearfix">
        <br />
        <br />
      </div>
      <div className="indicator">
        <h3>{loading ? "loading..." : checkinsCount}</h3>
        <small>Checkins</small>
      </div>
      <div className="clearfix">
        <br />
        <br />
      </div>

      <Table className="checkin-report large">
        <TableHead cols={["Ticket Type", "Quantity"]} />
        <tbody>
          {Object.keys(ticketTypeDetails).map(ticketTypeDetail => {
            return (
              <React.Fragment>
                <tr
                  role="row"
                  className="checkint-ticket-type-row"
                  key={ticketTypeDetail.split("-")[0]}
                >
                  <td class="p-large">
                    <strong className="text-blue">
                      {ticketTypeDetail.split("-")[1]}
                    </strong>
                  </td>
                  <td class="p-large text-blue">
                    {ticketTypeDetails[ticketTypeDetail].reduce(
                      (c, val) => c + parseInt(val.count),
                      0
                    )}
                  </td>
                </tr>
                {ticketTypeDetails[ticketTypeDetail].map(item => {
                  return (
                    <tr className="checkin-phase-row" key={item.id}>
                      <td className="p-large p-l-20">{item.name}</td>
                      <td class="p-large">{item.count}</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}

          {Object.keys(details).map((ownerType, i) => {
            return (
              <React.Fragment>
                <tr
                  role="row"
                  className="checkin-ticket-type-row"
                  key={ownerType}
                >
                  <td class="p-large">
                    <strong className="text-blue">
                      {ownerTypes[ownerType]}
                    </strong>
                  </td>
                  <td class="p-large text-blue">
                    {details[ownerType].reduce(
                      (c, val) => c + parseInt(val.count),
                      0
                    )}
                  </td>
                </tr>

                {details[ownerType].map(item => {
                  return (
                    <tr
                      role="row"
                      className="checkin-product-row"
                      key={item.id}
                    >
                      <td className="p-large p-l-20">{item.name}</td>
                      <td className="p-large">{item.count}</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>

      {/* <ListGroup> */}
      {/* {Object.keys(ticketTypeDetails).map(ticketTypeDetail => {
          return <ListGroupItem key={ticketTypeDetail.split("-")[0]}>
            <strong className="text-blue">{ticketTypeDetail.split("-")[1]}</strong>
            <Badge
              className="pull-right"
              color="success"
            >
              {ticketTypeDetails[ticketTypeDetail].reduce((c, val) => c + parseInt(val.count), 0)}
            </Badge>
            <ul className="list-unstyled">
              {ticketTypeDetails[ticketTypeDetail].map(item => {
                return <li key={item.id}>
                  {item.name}
                  <Badge
                    className="pull-right"
                    color="secondary"
                  >
                    {item.count}
                  </Badge>
                </li>
              })}
            </ul>
          </ListGroupItem>;
        })} */}
      {/* {Object.keys(details).map(ownerType => {
          return <ListGroupItem key={ownerType}>
            {ownerTypes[ownerType]}
            <Badge
              className="pull-right"
              color="success"
            >
              {details[ownerType].reduce((c, val) => c + parseInt(val.count), 0)}
            </Badge>
            <ul className="list-unstyled">
              {details[ownerType].map(item => {
                return <li key={item.id}>
                  {item.name}
                  <Badge
                    className="pull-right"
                    color="secondary"
                  >
                    {item.count}
                  </Badge>
                </li>
              })}
            </ul>
          </ListGroupItem>;
        })}
      </ListGroup> */}
    </EventsContainer>
  );
};

export default withFirebase(CheckInReport);
