import React from "react";
import { useField } from "formik";
import { FormGroup, Input, Label, FormFeedback } from "reactstrap";

export default ({ ...props }) => {
  const { label } = props;
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormGroup>
      {label && <Label>{`${label}`}</Label>}
      <Input
        autoComplete="off"
        className="form-control"
        invalid={Boolean(errorText)}
        valid={meta.value && !meta.error}
        {...field}
        {...props}
      />
      <FormFeedback>{errorText}</FormFeedback>
    </FormGroup>
    // <TextField
    //   variant="outlined"
    //   margin="normal"
    //   {...field}
    //   helperText={errorText}
    //   error={!!errorText}
    //   {...props}
    //   onBlur={props.onBlur || field.onBlur}
    // />
  );
};
