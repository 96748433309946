import React from "react";
import { Link } from "react-router-dom";
import {

  Table,
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import { withFirebase } from "../../components/Firebase";
import EventsContainer from "../../components/EventsContainer";
import { compose } from "recompose";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { asyncForEach } from "../../helpers/utils";
import { connect } from "react-redux";
import permissions from "../../helpers/permissions";
import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";
import TableHead from "../../components/Admin/TableHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CheckInStaff extends React.Component {
  state = {
    event: {},
    associations: [],
    users: [],
    alert: null
  };
  componentDidMount() {
    this.handleFetchData();
  }
  handleFetchData = async () => {
    const { organizationId, eventId } = this.props.match.params;

    const userEvent = await this.props.firebase.getUserEventsCollectionGroup(
      eventId
    );
    await userEvent.onSnapshot(async userEventData => {
      const userEventList = userEventData.docs.map(data => ({
        ...data.data(),
        id: data.id
      }));
      const event = await this.props.firebase.getEvent(organizationId, eventId);
      let users = [];
      const { auth_user_check_in_ids } = event.data();
      if (auth_user_check_in_ids) {
        await asyncForEach(auth_user_check_in_ids, async userId => {
          const user = await this.props.firebase.getUser(userId);
          if (user) {
            const {
              name: { firstName, lastName }
            } = user;
            users.push({ value: userId, label: `${firstName} ${lastName} ` });
          }
        });
      }

      this.setState({
        event: event.data(),
        users: users,
        associations: userEventList
      });
    });
  };
  handleRedirecTo(id, user_id, organizationId, eventId) {
    this.props.history.push(
      `/${organizationId}/events/${eventId}/check_staff/${id}/edit`,
      {
        user_id
      }
    );
  }

  nameUser = id => {
    for (let item of this.state.users) {
      if (item.value === id) {
        return item.label;
      }
    }
  };
  destroy = async (orgId, eventId, id) => {
    try {
      await this.props.firebase
        .deleteUserEvents(orgId, eventId, id)
        .then(record => {
          this.hideAlert();
        });
    } catch (err) {
      console.log(err.message);
    }
  };
  hideAlert() {
    this.setState({
      alert: null
    });
  }

  cancelDestroy() {
    this.hideAlert();
  }
  confirmDestroy = (user_id, id) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.destroy(user_id, id)}
          onCancel={() => this.cancelDestroy()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
        >
          This action cannot be undone
        </ReactBSAlert>
      )
    });
  };

  render() {
    const { organizationId, eventId } = this.props.match.params;
    return (
      <EventsContainer event={this.state.event}>
        {this.state.alert}
        <div className="section-header">
          <div className="text-right table-actions pull-right">
            {permissions.can("events", "create") && (
              <Link
                to={`/${organizationId}/events/${eventId}/check_staff/new`}
                className="btn btn-primary"
              >
                Assign New Staff
              </Link>
            )}
          </div>
          <Breadcrumb>
            <BreadcrumbItem active>Check in Staff</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Table className="responsive" role="table">
          <TableHead cols={["Staff Name"]} />
          <tbody>
            {this.state.associations.map(
              (record, i) =>
                this.nameUser(record.user_id) && (
                  <tr key={record.id}>
                    <td>{this.nameUser(record.user_id)}</td>
                    <td>
                      <div className="branches-read__actions">
                        <UncontrolledDropdown
                          className="btn-form-options"
                          direction="left"
                        >
                          <DropdownToggle tag="span">
                            <FontAwesomeIcon icon="ellipsis-h" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() =>
                                this.handleRedirecTo(
                                  record.id,
                                  record.user_id,
                                  organizationId,
                                  eventId
                                )
                              }
                            >
                              Settings
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                              onClick={() => this.confirmDestroy(
                                record.user_id,
                                record.id
                              )}
                              className="text-danger"
                            >
                              Destroy
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </Table>
      </EventsContainer>
    );
  }
}
export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(CheckInStaff);
