import React from "react";

import { Link } from "react-router-dom";

import dotsIcon from "../../images/icons/dots.svg";

import { edit_slider_item_path } from "../../helpers/paths";

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import permissions from "../../helpers/permissions";

export default function Organization(props) {
  const { id, image, title, subtitle, link, linkCaption } = props.record;

  return (
    <tr key={id} className="slider-item-row">
      <td role="cell">
        <Link to={edit_slider_item_path(id)}>
          <img src={image} className="table-img" alt={title} />
        </Link>
      </td>
      <td role="cell" className="slider-item-content">
        <Link to={edit_slider_item_path(id)}>
          <h6>{title}</h6>
          <p>{subtitle}</p>
        </Link>
      </td>

      <td role="cell">
        <Link to={link}>
          {linkCaption} ({link})
        </Link>
      </td>
      <td role="cell" className="text-right">

        <UncontrolledDropdown direction="left">
          <DropdownToggle tag="span">
            <img src={dotsIcon} alt="Options" />
          </DropdownToggle>
          <DropdownMenu>
            {permissions.can("slider_items", "edit") && (
              <DropdownItem
                tag={Link}
                to={edit_slider_item_path(id)}
              >
                Edit
              </DropdownItem>
            )}
            <DropdownItem divider />
            <DropdownItem
              onClick={() => props.confirmDestroy(id)} className="text-danger">
              Destroy
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}
