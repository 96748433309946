import React from "react";
import { Switch, Route } from "react-router-dom";
import Events from "./Events";
// import Orders from "./Orders";
import OrdersDetails from "../../../pages/orders/details";
import Forms from "./Forms";
import Organization from "./Organizations";
import Users from "./Users";
import Home from "../../../pages/home/";
import Deposits from "./Deposits";
import SliderItems from "./SliderItems";
import Dashboard from "../../../pages/dashboard";
import OrdersV2 from "../../../pages/ordersV2"

export default function Application() {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/events" component={Events} />
      <Route path="/forms" component={Forms} />
      <Route path="/:orgId/forms" component={Forms} />
      <Route path="/:orgId/events" component={Events} />
      <Route path="/orders" exact component={OrdersV2} />
      <Route
        path="/:organizationId/:eventId/orders/:orderId"
        exact
        component={OrdersDetails} />
      <Route path="/organizations" component={Organization} />
      <Route path="/users" component={Users} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/deposits" component={Deposits} />
      <Route path="/slider_items" component={SliderItems} />
      {/* <Route path="/orders" component={Orders} /> */}
    </Switch>
  );
}
