import React from "react";

import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";

import { centsToDecimal } from "../../helpers/currency";
import EventsContainer from "../../components/EventsContainer";
import { withFirebase } from "../../components/Firebase";

import Sugar from "sugar";
Sugar.extend();

class EventShow extends React.Component {
  state = {
    event: {},
    ticketTypes: [],
    ready: false
  };

  async componentDidMount() {
    const { organizationId, eventId } = this.props.match.params;
    this.setState({ organizationId: organizationId });
    var eventsCollection = await this.props.firebase.eventsCollection(
      organizationId
    );

    const event = await eventsCollection.doc(eventId).get();

    this.setState({ event: event.data() });
  }

  render() {
    return (
      <EventsContainer event={this.state.event}>
        <NotificationAlert ref="notificationAlert" />
        <div className="content">
          <div className="table-responsive m-t-20">
            <table className="table vt no-th-brd pro-of-month">
              <thead>
                <tr>
                  <th>
                    <strong>Tipo de boleto</strong>
                  </th>
                  <th>Aforo disponible</th>
                  <th>Cantidad vendida</th>
                  <th>Cantidad disponible</th>
                  <th>Precio unitario</th>
                  <th>Subtotal</th>
                </tr>
              </thead>

              {this.state.ready &&
                this.state.ticketTypes.map((ticketType, i) => (
                  <tbody key={ticketType.id}>
                    <tr key={ticketType.id}>
                      <td>
                        <strong>{ticketType.name}</strong>
                      </td>
                      <td>{ticketType.quantity}</td>
                      <td>{ticketType.soldCount}</td>
                      <td />
                      <td />
                      <td />
                    </tr>
                    {ticketType.phases.items.map((phase, i) => (
                      <tr key={phase.id}>
                        <td className="text-right">
                          <strong>{phase.name}</strong>
                        </td>
                        <td>{phase.quantity}</td>
                        <td>{phase.soldCount}</td>
                        <td>{phase.quantity - phase.soldCount}</td>
                        <td>$ {centsToDecimal(phase.price).format(2)}</td>
                        <td>
                          ${" "}
                          {centsToDecimal(phase.price * phase.soldCount).format(
                            2
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ))}
            </table>
          </div>
        </div>
      </EventsContainer>
    );
  }
}

export default withFirebase(EventShow);