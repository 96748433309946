import app from "firebase/app";
import "firebase/firebase-firestore";
import { environment } from "../components/Firebase/config";
class Permissions {
  constructor() {
    app.initializeApp(environment.firebase);
    this.db = app.firestore();
    this.permissions = { manage_all: false };
    this.currentRoles = [];
    this.roles = [];
    this.organizationIds = [];
  }

  clean = () => {
    this.permissions = { manage_all: false };
    this.organizationIds = [];
    this.currentRoles = [];
    this.roles = [];
  };

  getRoles = async uid => {
    const user = await this.db
      .collection("users")
      .doc(uid)
      .get();

    const { roles, isAdmin, organizationIds } = user.data();
    this.organizationIds = organizationIds;
    const currentRoles = [];
    roles.forEach(item => {
      currentRoles.push(item.role);
    });
    this.currentRoles = currentRoles;
    this.roles = roles;
    const result = { currentRoles, isAdmin: isAdmin || false };
    return result;
  };

  getRolesPermissions = async uid => {
    const result = await this.getRoles(uid);

    if (result.currentRoles.includes("admin") || result.isAdmin) {
      const permissions = {
        manage_all: true
      };
      this.permissions = permissions;
      return;
    }

    const resources = [
      "users",
      "events",
      "organizations",
      "orders",
      "ticket_types",
      "phases",
      "products",
      "forms",
      "coupons",
      "courtesies",
      "courtesy_ticket_types",
      "deposits"
    ];

    const editdPermissions = {};
    await asyncForEach(result.currentRoles, async role => {
      const rolesPermissions = await this.db
        .collection("roles")
        .doc(role)
        .get();

      const { permissions } = rolesPermissions.data();

      resources.forEach(resource => {
        editdPermissions[resource] = {
          read: (permissions[resource] && permissions[resource].read) || false,
          create:
            (permissions[resource] && permissions[resource].create) || false,
          edit: (permissions[resource] && permissions[resource].edit) || false,
          delete:
            (permissions[resource] && permissions[resource].delete) || false
        };
      });
    });

    this.permissions = editdPermissions;
    return;

    async function asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }
  };

  adminPresent = () => {
    const { manage_all } = this.permissions;
    return manage_all;
  };

  hasRole = roleName => {
    return this.currentRoles.includes(roleName);
  };

  can = (resource, action) => {
    // console.log(this.permissions);
    const { manage_all } = this.permissions;
    if (manage_all) {
      return true;
    }
    return this.permissions[resource]
      ? this.permissions[resource][action]
      : false;
  };
}
export default new Permissions();
