import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import app from "firebase/app";
import { Row, Col, Breadcrumb, BreadcrumbItem, FormFeedback } from "reactstrap";
import { decimalToCents } from "../../../helpers/currency";
import "./new_deposit.scss";

import { withFirebase } from "../../../components/Firebase";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";


import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  DateTimeInput,
  CurrencyInput
} from "../../../components/Formica";

import { useFormik } from "formik";
import * as yup from "yup";

const db = app.firestore();
const validationSchema = yup.object({
  organizationId: yup
    .string()
    .required()
    .label("Organization"),
  type: yup
    .string()
    .required()
    .label("Type"),
  amount: yup
    .number()
    .positive()
    .required()
    .label("Amount"),
  date: yup.date().label("Date"),
  status: yup
    .string()
    .required()
    .label("Status"),
  type: yup
    .string()
    .required()
    .label("Type"),
  bank: yup
    .string()
    .required()
    .label("Bank's Name"),
  rfc: yup
    .string()
    .required()
    .label("Reference Code")
});
const initialValues = {
  organizationId: "",
  status: "Pending",
  type: "SPEI",
  bank: "",
  rfc: "",
  amount: 0,
  date: new Date()
};
function NewDeposit(props) {
  const { history } = props;
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(props.uid);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    setTouched,
    setValues
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      const now = new Date();
      if (!loading) {
        setLoading(true);
        const deposit = {
          organizationId: values.organizationId,
          status: values.status,
          type: values.type,
          bank: values.bank,
          rfc: values.rfc,
          amount: decimalToCents(values.amount),
          date: new Date(values.date),
          createdAt: now,
          updatedAt: now,
          authorId: currentUserId
        };
        const documentReference = await db
          .collection("organizations")
          .doc(values.organizationId)
          .collection("deposits")
          .add(deposit);

        const formatedDate = {
          _seconds: values.date.getTime() / 1000,
          _nanoseconds: 0
        };
        await axios.post(
          process.env.REACT_APP_ENV === "production"
            ? "https://us-central1-handypass-production.cloudfunctions.net/app/algolia/deposit"
            : "https://us-central1-handypass-dev.cloudfunctions.net/app/algolia/deposit",
          { ...deposit, date: formatedDate, id: documentReference.id }
        );
        history.push("/deposits");
        return setLoading(false);
      }
    },
    validationSchema
  });
  const getOrganizations = async () => {
    const organizationsDB = (
      await db.collection("organizations").get()
    ).docs.map(organization => {
      return { name: organization.data().name, id: organization.id };
    });
    return setOrganizations(organizationsDB);
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  return (
    <React.Fragment>
      <div className="section-header">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/deposits">Deposits</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>New</BreadcrumbItem>
        </Breadcrumb>
      </div>

      <Form
        className="form-horizontal form-compact"
        id="depositForm"
        onSubmit={handleSubmit}
      >
        <FormFields>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="organizationId">Organization</Label>
                <Input
                  type="select"
                  name="organizationId"
                  value={values.organizationId}
                  id="organizationId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Organization"
                  invalid={errors.organizationId && touched.organizationId}
                  onBlur={handleBlur}
                >
                  <option value="">Select an option</option>
                  {organizations.map(record => (
                    <option key={record.id} value={record.id}>
                      {record.name}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{errors.organizationId}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  name="status"
                  value={values.status}
                  id="status"
                  onChange={handleChange}
                  placeholder="Status"
                  invalid={errors.status && touched.status && true}
                  onBlur={handleBlur}
                >
                  <option value="">Select an option</option>
                  {["Pending", "Complete", "On Hold"].map(record => (
                    <option
                      key={record}
                      value={record}
                      defaultValue={values.status}
                    >
                      {record}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{errors.status}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="type">Type</Label>
                <Input
                  type="select"
                  name="type"
                  value={values.type}
                  id="type"
                  onChange={handleChange}
                  placeholder="Status"
                  invalid={errors.type && touched.type && true}
                  onBlur={handleBlur}
                >
                  <option value="">Select an option</option>
                  {["SPEI", "Cash"].map(record => (
                    <option
                      key={record}
                      value={record}
                      defaultValue={values.status}
                    >
                      {record}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{errors.type}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="bank">Bank</Label>
                <Input
                  type="text"
                  name="bank"
                  value={values.bank}
                  id="bank"
                  onChange={handleChange}
                  placeholder="Bank's Name"
                  invalid={errors.bank && touched.bank && true}
                  onBlur={handleBlur}
                />
                <FormFeedback>{errors.bank}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="rfc">Reference Code</Label>
                <Input
                  type="text"
                  name="rfc"
                  value={values.rfc}
                  id="rfc"
                  onChange={handleChange}
                  placeholder="Reference Code"
                  invalid={errors.rfc && touched.rfc && true}
                  onBlur={handleBlur}
                />
                <FormFeedback>{errors.rfc}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="amount">Amount</Label>
                <CurrencyInput
                  value={values.amount}
                  name="amount"
                  className="form-control"
                  prefix="$"
                  autoFocus={true}
                  onChangeEvent={(event, maskedVal, floatVal) =>
                    setFieldValue("amount", floatVal)
                  }
                />
                <FormFeedback>{errors.amount}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="date">Date</Label>
                <DateTimeInput
                  className="datetime-picker-input"
                  selected={values.date}
                  onChange={date => setFieldValue("date", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  invalid={errors.date && true}
                />
                <FormFeedback>{errors.date}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </FormFields>
        <FormActions cancelPath="/deposits" submitCaption="Save" />
      </Form>
    </React.Fragment>
  );
}

// export default withRouter(NewDeposit);
export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({ uid: state.currentUser.uid }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(NewDeposit);

