import React, { Component } from "react";
class Profile extends Component {
  state = { username: null };
  componentDidMount() {}
  render() {
    return (

        <center className="m-t-30">
          <img
            src="../assets/images/users/5.jpg"
            className="img-circle"
            width="150"
            alt="profile-pic"
          />
          <h4 className="card-title m-t-10">{this.state.username}</h4>
          <h6 className="card-subtitle">Accounts Manager Amix corp</h6>
          <div className="row text-center justify-content-md-center">
            <div className="col-4">
              <a href="/" className="link">
                <i className="icon-people" /> <font className="font-medium">254</font>
              </a>
            </div>
            <div className="col-4">
              <a href="/" className="link">
                <i className="icon-picture" /> <font className="font-medium">54</font>
              </a>
            </div>
          </div>
        </center>
    );
  }
}

export default Profile;
