import React, { useEffect, useState } from "react";
import { withFirebase } from "../../components/Firebase";
import "moment-timezone";
import { connect } from "react-redux";
import { compose } from "recompose";

import { Button, Row, Col, FormText, FormFeedback } from "reactstrap";
import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields
} from "../../components/Formica";
import EventsContainer from "../../components/EventsContainer";
import { organization_event_batch_orders_path } from "../../helpers/paths";
import { useFormik } from "formik";
import * as yup from "yup";
import { calculateFirebaseBatchPages, asyncForEach } from "../../helpers/utils";
const uuidv1 = require("uuid/v1");

const validationSchema = yup.object({
  batchSize: yup
    .number()
    .required()
    .min(1)
    .label("Batch Size"),
  phaseId: yup
    .string()
    .required()
    .label("Phase")
});
const initialValues = {
  phaseId: "",
  notes: "",
  batchSize: 0,
  availability: 0,
  selectedPhase: {}
};

function BatchOrderForm(props) {
  const [phases, setPhases] = useState([]);
  const [loading, setLoading] = useState(false);
  const { organizationId, eventId } = props.match.params;
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    handleBlur,
    touched
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      await create();
    },
    validationSchema
  });

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const phasesCollection = await props.firebase
      .phasesCol(organizationId, eventId)
      .get();
    const phases = phasesCollection.docs.map(phase => ({
      id: phase.id,
      ...phase.data()
    }));
    setPhases(phases);
  };

  useEffect(() => {
    if (values.phaseId) {
      const selectedPhase = phases.find(phase => phase.id == values.phaseId);
      const { quantity, soldCount, onHold } = selectedPhase;
      const availability = quantity - soldCount - onHold;
      if (isNaN(availability)) values.availability = 0;
      setFieldValue("selectedPhase", selectedPhase);
      setFieldValue("availability", availability);
      setFieldValue("batchSize");
    }
  }, [values.phaseId]);

  useEffect(() => {
    if (values.batchSize > values.availability) {
      setFieldValue("batchSize", values.availability);
    }
  }, [values.batchSize]);

  const create = async () => {
    const userId = props.currentUser.uid;

    const currentDate = new Date();
    setLoading(true);
    try {
      const phase = values.selectedPhase;
      const batchPages = calculateFirebaseBatchPages(values.batchSize);
      await asyncForEach(batchPages, async (pageSize, i) => {
        // create order with up to 500 tickets
        const new_order = await props.firebase
          .ordersCollection(organizationId, eventId)
          .add({
            organizationId,
            eventId,
            discount: 0,
            orderStatus: "PAID",
            orderType: "BATCH",
            total: 0,
            subtotal: 0,
            fee: 0,
            quantity: pageSize,
            ticketsCount: pageSize,
            courtesiesCount: 0,
            productsCount: 0,
            userId: userId,
            fulfilled: false,
            createdAt: currentDate,
            updatedAt: currentDate,
            donation: 0,
            notes: values.notes,
            batchSize: pageSize,
            isValid: { result: "PENDING", validationErrors: [] }
          });
        const orderId = new_order.id;
        let orderTicketData = {
          quantity: pageSize,
          name: phase.name,
          phaseId: phase.id,
          discount: 0,
          fee: 0,
          feePerUnit: 0,
          total: 0,
          subtotal: 0,
          ticketTypeId: phase.ticketTypeId
        };

        await props.firebase
          .orderDoc(organizationId, eventId, orderId)
          .collection("order_tickets")
          .add(orderTicketData);

        await props.firebase
          .eventDoc(organizationId, eventId)
          .collection("phases")
          .doc(phase.id)
          .update({
            onHold: props.firebase.FieldValue.increment(pageSize)
          });

        const paymentToken = uuidv1();
        const payment = await props.firebase
          .orderDoc(organizationId, eventId, orderId)
          .collection("payments")
          .add({
            provider: "batch",
            token: paymentToken,
            confirmationToken: paymentToken,
            paymentType: "batch",
            status: "PAID",
            orderType: "BATCH",
            orderId: orderId,
            eventId: eventId,
            paidAt: currentDate,
            createdAt: currentDate,
            updatedAt: currentDate,
            amount: 0,
            fee: 0,
            gatewatFee: 0,
            handypassFee: 0
          });

        // update order payment id
        await props.firebase
          .orderDoc(organizationId, eventId, orderId)
          .update({ paymentId: payment.id });
      });
      console.log("WORKING");
      props.history.push(`/${organizationId}/events/${eventId}/batch_orders/`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <EventsContainer>
      <Form className="form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="phaseId">Phase</Label>
                <Input
                  type="select"
                  name="phaseId"
                  value={values.phaseId}
                  id="phaseId"
                  onChange={handleChange}
                  placeholder="Select one phase"
                  invalid={errors.phaseId && touched.phaseId}
                >
                  <option value="">Select an option</option>
                  {phases.map(({ id, name }) => (
                    <option
                      key={id}
                      value={id}
                      defaultValue={id === values.phaseId}
                    >
                      {name}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{errors.phaseId}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="batchSize">Batch size</Label>
                <Input
                  type="number"
                  name="batchSize"
                  max={values.availability}
                  value={values.batchSize}
                  className="form-control input-danger"
                  id="batchSize"
                  disabled={values.availability === 0}
                  onChange={handleChange}
                  invalid={errors.batchSize && touched.batchSize}
                />
                <FormFeedback>{errors.batchSize}</FormFeedback>
                <FormText>
                  The max number of tickets to generate is{" "}
                  <a
                    onClick={() => {
                      setFieldValue("batchSize", values.availability);
                    }}
                  >
                    {values.availability}
                  </a>
                  .
                </FormText>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="notes">Additional note</Label>
            <Input
              type="textarea"
              rows="2"
              name="notes"
              value={values.notes}
              id="notes"
              onChange={handleChange}
              placeholder="write something or leave it blank."
            />
          </FormGroup>
        </FormFields>

        <FormActions
          cancelPath={organization_event_batch_orders_path(
            organizationId,
            eventId
          )}
          loading={loading}
          submitCaption="Save"
        />
      </Form>
    </EventsContainer>
  );
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(BatchOrderForm);
