import React from "react";

import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";

import { centsToDecimal } from "../../helpers/currency";
import EventsContainer from "../../components/EventsContainer";
import { withFirebase } from "../../components/Firebase";

import Sugar from "sugar";
Sugar.extend();

class BatchOrderShow extends React.Component {
  state = {
    event: {},
    ticketTypes: [],
    ready: false,
    tickets: []
  };

  async componentDidMount() {
    const { organizationId, eventId, orderId } = this.props.match.params;
    const { firebase } = this.props;
    this.setState({ organizationId: organizationId });
    var eventsCollection = await this.props.firebase.eventsCollection(
      organizationId
    );

    const event = await eventsCollection.doc(eventId).get();

    let orderTicketRef = await firebase.db
      .collection(`organizations/${organizationId}/events/`)
      .doc(eventId)
      .collection("orders")
      .doc(orderId)
      .collection("order_tickets")
      .get();

    const orderTicket = orderTicketRef.docs[0];

    const ticketsCollection = await firebase.db
      .collection(
        `organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_tickets/${
          orderTicket.id
        }/tickets`
      )
      .get();

    var tickets = [];
    ticketsCollection.docs.forEach(doc => {
      tickets.push({
        id: doc.id,
        ...doc.data()
      });
    });
    this.setState({ event: event.data(), tickets: tickets });
  }

  render() {
    return (
      <EventsContainer event={this.state.event}>
        <NotificationAlert ref="notificationAlert" />
        <div className="content">
          <table>
            <thead>
              <tr>
                <th>Ticket id</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.tickets.map((record, i) => (
                <tr key={record.id}>
                  <td>{record.id}</td>
                  <td>{record.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </EventsContainer>
    );
  }
}

export default withFirebase(BatchOrderShow);