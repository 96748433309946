import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { withFirebase } from "../../components/Firebase";
import EventsContainer from "../../components/EventsContainer";

import { compose } from "recompose";

import { connect } from "react-redux";

import permissions from "../../helpers/permissions";

import { Table } from "reactstrap";
import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";
import TableHead from "../../components/Admin/TableHead";
import CourtesyTicketType from "./_courtesyTicketType";

import "./index.scss";
class CourtesyTicketTypesList extends React.Component {
  state = {
    courtesyTicketTypes: [],
    loading: true,
    alert: null,
  };

  async componentDidMount() {
    const uid = this.props.currentUser.uid;
    permissions.getRolesPermissions(uid).then(async () => { });
    const { organizationId, eventId } = this.props.match.params;

    var self = this;

    this.setState({
      organizationId: organizationId
    });

    var courtesyTicketTypes = await this.props.firebase.courtesyTicketTypesCollection(
      organizationId,
      eventId
    );

    courtesyTicketTypes.onSnapshot(function (querySnapshot) {
      var courtesyTicketTypes = [];
      querySnapshot.forEach(function (doc) {
        courtesyTicketTypes.push({ id: doc.id, ...doc.data() });
      });
      self.setState({ courtesyTicketTypes });
    });
  }

  destroy = async (orgId, eventId, id) => {
    this.props.firebase.deleteCourtesyTicketType(orgId, eventId, id).then(record => {
      const courtesyTicketTypes = [
        ...this.state.courtesyTicketTypes.filter(r => {
          return r.id !== id;
        })
      ];
      this.setState({ courtesyTicketTypes });
      this.hideAlert();
    });
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  cancelDestroy() {
    this.hideAlert();
  }

  confirmDestroy = (orgId, eventId, id) => {
    this.setState({
      alert: (
        <DestroyConfirmation
          onConfirm={() => this.destroy(orgId, eventId, id)}
          onCancel={() => this.cancelDestroy()}
        />
      )
    });
  };

  render() {
    const { organizationId, eventId } = this.props.match.params;
    return (
      <EventsContainer event={this.state.event}>
        {this.state.alert}
        {permissions.can("courtesy_ticket_types", "create") && (
          <Row>
            <Col md="8" />
            <Col md="4">
              <div className="d-flex justify-content-end">
                <div className="p-2">
                  <Link
                    to={`/${organizationId}/events/${eventId}/courtesy_ticket_types/new`}
                    className="btn btn-primary"
                  >
                    New courtesy ticket type
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Table className="responsive">
          <TableHead cols={["Courtesy ticket type", ""]} />
          <tbody>
            {this.state.courtesyTicketTypes.map((record) => (
              <CourtesyTicketType
                key={record.id}
                record={record}
                organizationId={organizationId}
                eventId={eventId}
                confirmDestroy={this.confirmDestroy}
              />
            ))}
          </tbody>
        </Table>
      </EventsContainer>
    );
  }
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(CourtesyTicketTypesList);
