import React from "react";
import { Link } from "react-router-dom";
import { organization_event_path } from "../../helpers/paths";
import StatusBadge from "../../components/Admin/StatusBadge";

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import dotsIcon from "../../images/icons/dots.svg";
import permissions from "../../helpers/permissions";

import Moment from "react-moment";
import "moment-timezone";

export default function Event(props) {
  const {
    id,
    organizationId,
    name,
    startsAt,
    endsAt,
    eventStatus,
  } = props.record;

  return (
    <tr key={id} role="row" className="event-row">
      <td role="cell">
        {props.organizationName}
      </td>
      <td role="cell">
        <Link to={organization_event_path(organizationId, id)}>{name}</Link>
      </td>
      <td role="cell">
        <small className="text-muted">
          Starts at: <Moment format="YYYY/MM/DD HH:mm">{startsAt.toDate()}</Moment>
        </small>
        <br />
        <small className="text-muted">
          Ends at: <Moment format="YYYY/MM/DD HH:mm">{endsAt.toDate()}</Moment>
        </small>
      </td>
      <td role="cell">
        <StatusBadge status={eventStatus} />
      </td>
      <td className="text-right full-width">
        <UncontrolledDropdown direction="left" className="row-options-trigger">
          <DropdownToggle tag="span">
            <img src={dotsIcon} alt="Options" />
          </DropdownToggle>
          <DropdownMenu>
            {permissions.can("events", "edit") && (
              <DropdownItem
                tag={Link}
                to={organization_event_path(organizationId, id)}
              >
                Settings
              </DropdownItem>
            )}
            <DropdownItem divider />
            {permissions.can("events", "delete") && (
              <DropdownItem
                onClick={() => props.confirmDestroy(organizationId, id)}
                className="text-danger"
              >
                Destroy
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}
