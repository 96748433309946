import React, { useEffect, useState } from "react";
import { withFirebase } from "../../components/Firebase";
import { Row, Col, Button } from "reactstrap";
import { slugify } from "../../helpers/string";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import moment from "moment";
import "moment-timezone";
import { fbTimestampToDate } from "../../helpers/datetime";
import EventContainer from "../../components/EventsContainer";
import { Link } from "react-router-dom";
import { events_path } from "../../helpers/paths";

import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  FormFeedback,
  DateTimeInput
} from "../../components/Formica";

import { useFormik } from "formik";
import * as yup from "yup";

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: props.lat, lng: props.lon }}
      center={{ lat: props.lat, lng: props.lon }}
    >
      <Marker position={{ lat: props.lat, lng: props.lon }} />
    </GoogleMap>
  ))
);

const validationSchema = yup.object({
  name: "",
  endsAt: yup
    .date()
    .when("startsAt", (startsAt, schema) => {
      const validStartsAt = startsAt instanceof Date && !isNaN(startsAt);
      if (!validStartsAt) return;
      return startsAt && schema.min(startsAt);
    })
    .label("End At")
    .required(),
  startsAt: yup
    .date()
    .required()
    .label("Start At"),
  location: yup
    .string()
    .required()
    .label("Location"),
  logo: "",
  lat: yup
    .string()
    .required()
    .label("Latitude"),
  lon: yup
    .string()
    .required()
    .label("Longitude")
});
const initialValues = {
  name: "",
  endsAt: "",
  startsAt: "",
  location: "",
  logo: "",
  lat: "",
  lon: ""
};
const handleFormatData = values => {
  return {
    startsAt: moment(values.startsAt).toDate(),
    endsAt: moment(values.endsAt).toDate(),
    location: values.location,
    lat: values.lat.toString(),
    lon: values.lon.toString()
  };
};

function Venuedates(props) {
  const { organizationId, eventId } = props.match.params;
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    setTouched,
    setValues
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      const eventData = handleFormatData(values);
      const eventRef = await props.firebase.eventsCollection(organizationId);
      await eventRef.doc(eventId).update(eventData);
      props.history.push(`/${organizationId}/events/${eventId}`);
    },
    validationSchema
  });

  useEffect(() => {
    const getData = async () => {
      if (eventId) {
        const eventsCollection = await props.firebase.eventsCollection(
          organizationId
        );
        const event = await eventsCollection.doc(eventId).get();
        const { location, lat, lon, name, logo } = event.data();
        const endsAt = fbTimestampToDate(event.data().endsAt.seconds);
        const startsAt = fbTimestampToDate(event.data().startsAt.seconds);
        setValues({
          name,
          endsAt,
          startsAt,
          location,
          logo,
          lat,
          lon
        });
      }
    };
    getData();
  }, []);
  return (
    <EventContainer>
      <Form className="form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <Row>
            <Col md="12">
              <br />
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="eventStartsAt">Start At</Label>
                    <DateTimeInput
                      selected={values.startsAt}
                      onChange={date => setFieldValue("startsAt", date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      invalid={errors.startsAt && touched.startsAt}
                    />
                    <FormFeedback>{errors.startsAt}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="eventEndAt">End At</Label>
                    <DateTimeInput
                      selected={values.endsAt}
                      onChange={date => setFieldValue("endsAt", date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      invalid={errors.endsAt && touched.endsAt}
                    />
                    <FormFeedback>{errors.endsAt}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="eventLocation">Location</Label>
                <Input
                  type="text"
                  name="location"
                  value={values.location || ""}
                  id="eventfacebookLink"
                  onChange={handleChange}
                  placeholder="location"
                  invalid={errors.location && touched.location}
                  onBlur={handleBlur}
                />
                <FormFeedback>{errors.location}</FormFeedback>
              </FormGroup>
              <Row>
                <Col md="3">
                  <Input
                    name="lat"
                    value={values.lat || ""}
                    onChange={handleChange}
                    placeholder="latitude"
                    invalid={errors.lat && touched.lat}
                    onBlur={handleBlur}
                  />
                  <FormFeedback>{errors.lat}</FormFeedback>
                </Col>
                <Col md="3">
                  <Input
                    name="lon"
                    value={values.lon || ""}
                    onChange={handleChange}
                    placeholder="longitude"
                    invalid={errors.lon && touched.lon}
                    onBlur={handleBlur}
                  />
                  <FormFeedback>{errors.lon}</FormFeedback>
                </Col>
              </Row>
              <div className="clearfix"></div>
              <CustomSkinMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`}
                loadingElement={<div style={{ height: "100%" }} />}
                containerElement={
                  <div style={{ height: "500px", marginTop: "20px;" }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
                lat={parseFloat(values.lat)}
                lon={parseFloat(values.lon)}
              />
            </Col>
          </Row>
        </FormFields>
        <FormActions cancelPath={events_path()} />
        {/*submit={this.updateEvent} */}
      </Form>
    </EventContainer>
  );
}

export default withFirebase(Venuedates);
