import React, { useEffect } from "react";
import { withFirebase } from "../../components/Firebase";
import { centsToDecimal, decimalToCents } from "../../helpers/currency";
import { Col, Row } from "reactstrap";
import EventsContainer from "../../components/EventsContainer";
import "./index.scss";
import { events_path } from "../../helpers/paths";
import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  CurrencyInput,
  FormFeedback,
  SelectFromArray,
  DropzoneUploader
} from "../../components/Formica";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .required()
    .label("Name"),
  description: yup
    .string()
    .required()
    .label("Description"),
  quantity: yup
    .number()
    .positive()
    .moreThan(0)
    .required()
    .label("Quantity"),
  price: yup
    .number()
    .positive()
    .moreThan(0)
    .required()
    .label("Price"),
  fee: yup
    .number()
    .positive()
    .moreThan(0)
    .required()
    .label("Fee"),
  boxOfficePrice: yup
    .number()
    .positive()
    .moreThan(0)
    .label("BoxOffice Price"),
  boxOfficeFee: yup
    .number()
    .positive()
    .moreThan(0)
    .label("BoxOffice Fee")
});
const initialValues = {
  name: "",
  description: "",
  quantity: 0,
  soldCount: 0,
  onHold: 0,
  fee: 0,
  boxOfficeFee: 0,
  price: 0,
  boxOfficePrice: 0,
  event: {},
  file: "",
  fileUrl: "",
  sortOrder: 0,
  renderWidget: false,
  status: "ACTIVE",
  productEventId: ""
};
const handleFormatData = values => {
  return {
    name: values.name,
    description: values.description,
    price: decimalToCents(values.price),
    boxOfficePrice: decimalToCents(values.boxOfficePrice),
    fee: decimalToCents(values.fee),
    boxOfficeFee: decimalToCents(values.boxOfficeFee),
    file: values.file,
    status: values.status,
    quantity: values.quantity
  };
};
function ProductForm(props) {
  const { organizationId, eventId, productId } = props.match.params;
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    handleBlur
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      const now = new Date();
      let product = handleFormatData(values);
      if (productId) {
        product.updatedAt = now;
        await props.firebase.updateProduct(
          organizationId,
          eventId,
          productId,
          product
        );
      } else {
        product.createdAt = now;
        product.updatedAt = now;
        product.productEventId = eventId;
        product.soldCount = 0;
        product.onHold = 0;
        product.sortOrder = 0;
        await props.firebase.createProduct(organizationId, eventId, product);
      }
      props.history.push(`/${organizationId}/events/${eventId}/products`);
    },
    validationSchema
  });

  const handleChangeCurrency = (e, masked, value) => {
    const { name } = e.target;
    setFieldValue(name, value);
  };

  const handleChangeImage = url => {
    setFieldValue("file", url);
  };

  useEffect(() => {
    const mount = async () => {
      if (productId) {
        const product = await props.firebase.getProduct(
          organizationId,
          eventId,
          productId
        );
        const productData = product.data();
        setValues({
          ...productData,
          fee: centsToDecimal(productData.fee),
          boxOfficeFee: centsToDecimal(productData.boxOfficeFee),
          price: centsToDecimal(productData.price),
          boxOfficePrice: centsToDecimal(productData.boxOfficePrice)
        });
      }
    };
    mount();
  }, []);

  return (
    <EventsContainer>
      <Form className="form-horizontal form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <FormGroup>
            <Label className="label-block" for="logo">
              Image
            </Label>
            <br />
            <DropzoneUploader
              path="products/file/"
              name="file"
              onChange={handleChangeImage}
              image={values.file}
              className="product-header-uploader"
              legend="Recomended size|334x148"
              previewImage={values.file}
            />
          </FormGroup>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              value={values.name}
              className="form-control input-lg"
              id="name"
              onChange={handleChange}
              invalid={errors.name && touched.name}
              onBlur={handleBlur}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              rows="3"
              name="description"
              value={values.description}
              id="description"
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors.description && touched.description}
            />
            <FormFeedback>{errors.description}</FormFeedback>
          </FormGroup>
          <br />
          <Row className="form-section-header">
            <Col md="9">
              <h5>Availability & Prices</h5>
            </Col>
            <Col md="3">
              <FormGroup>
                <SelectFromArray
                  onChange={handleChange}
                  name="status"
                  value={values.status}
                  includeBlank="Select a status"
                  collection={["ACTIVE", "INACTIVE", "COMING_SOON"]}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="number"
                  name="quantity"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="quantity"
                  invalid={errors.quantity && touched.quantity}
                />
                <FormFeedback>{errors.quantity}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="soldCount">Tickets sold</Label>
                <Input
                  type="number"
                  name="soldCount"
                  value={values.soldCount}
                  disabled
                  id="quantity"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label for="price">Price</Label>
                <CurrencyInput
                  value={values.price}
                  name="price"
                  className={`form-control ${errors.price &&
                    touched.price &&
                    "is-invalid"}`}
                  prefix="$"
                  autoFocus={true}
                  onChangeEvent={handleChangeCurrency}
                />
                <FormFeedback>{errors.price}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="productFee">Fee</Label>
                <CurrencyInput
                  value={values.fee}
                  name="fee"
                  className={`form-control ${errors.fee &&
                    touched.fee &&
                    "is-invalid"}`}
                  prefix="$"
                  autoFocus={true}
                  onChangeEvent={handleChangeCurrency}
                />
                <FormFeedback>{errors.fee}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="box">Boxoffice Price</Label>
                <CurrencyInput
                  value={values.boxOfficePrice}
                  name="boxOfficePrice"
                  className={`form-control ${errors.boxOfficePrice &&
                    touched.boxOfficePrice &&
                    "is-invalid"}`}
                  prefix="$"
                  autoFocus={true}
                  onChangeEvent={handleChangeCurrency}
                />
                <FormFeedback>{errors.boxOfficePrice}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="productFeeBoxOffice">Boxoffice Fee</Label>
                <CurrencyInput
                  value={values.boxOfficeFee}
                  name="boxOfficeFee"
                  className={`form-control ${errors.boxOfficeFee &&
                    touched.boxOfficeFee &&
                    "is-invalid"}`}
                  prefix="$"
                  autoFocus={true}
                  onChangeEvent={handleChangeCurrency}
                />
                <FormFeedback>{errors.boxOfficeFee}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </FormFields>
        <FormActions cancelPath={events_path()} submitCaption="Save" />
      </Form>
    </EventsContainer>
  );
}

export default withFirebase(ProductForm);
