import React, { useState } from "react";
import { Button, Col, Row, FormText } from "reactstrap";

import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  SelectFromArray,
  DateTimeInput,
  CurrencyInput,
  CheckBox
} from "../../components/Formica";

import "./index.scss";

const fieldTypeOptions = [
  "BOOLEAN",
  "E-MAIL",
  "MULTIPLE-CHOICE",
  "OPEN",
  "RADIO",
  "SELECT"
];

class FieldInputsList extends React.Component {
  state = {
    required: true
  };

  renderFieldInput = ({
    name,
    fieldName,
    fieldType,
    fieldOptions,
    defaultValue,
    placeholder,
    token,
    required
  }) => (
    <div className="form-field-group">
      <div className="pull-right">
        <CheckBox
          name="required"
          onChange={() => this.setState({ required: !this.state.required })}
          checked={this.state.required}
          label="Required"
        />
      </div>
      <h5>Field</h5>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="name">Field name</Label>
            <Input
              name="name"
              id="name"
              placeholder="name..."
              required
              {...fieldName}
            />
            <Input name="token" id="token" {...token} type="hidden" />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label for="field[field_type]">Field type</Label>
            <Input id="field[field_type]" {...fieldType} type="select">
              <option>Select a valid field type</option>
              {fieldTypeOptions.map(value => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label>&nbsp;s</Label>
            <br />
          <Button
          className="btn-delete"
          color="danger"
          type="button"
          onClick={() => this.props.removeField(name)}
        >
          Delete
        </Button>

          </FormGroup>

        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="options">
              Options <small>Split with [ENTER] key</small>
            </Label>
            <Input
              id="options"
              placeholder=""
              {...fieldOptions}
              rows="5"
              type="textarea"
            />
            <FormText>Only if field type require options.</FormText>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="defaultValue">Default value</Label>
            <Input id="defaultValue" placeholder="..." {...defaultValue} />
          </FormGroup>
          <FormGroup>
            <Label for="placeholder">Placeholder</Label>
            <Input id="placeholder" placeholder="..." {...placeholder} />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );

  render() {
    const { fields, addField } = this.props;

    return (
      <>
        {fields.map((field, index) => (
          <React.Fragment key={`fieldInput-${index}`}>
            {this.renderFieldInput(field)}
          </React.Fragment>
        ))}

        <FormGroup>
          <Button onClick={addField} color="primary" type="button" className="btn-add">
            Add field
          </Button>
        </FormGroup>
      </>
    );
  }
}

export default FieldInputsList;
