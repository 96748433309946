import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class PrivateRoute extends React.Component {
  render() {
    const { path, exact, component } = this.props;
    return (
      <>
        {this.props.currentUser.ready ? (
          <Route
            path={path}
            exact={exact}
            render={(props) =>
              this.props.currentUser.isLoggedIn ? (
                React.createElement(component, {
                  ...props
                })
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        ) : (
          "loading"
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({ currentUser: state.currentUser }),
  (dispatch) => bindActionCreators({}, dispatch)
)(PrivateRoute);

/*import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class PrivateRoute extends React.Component {
  render() {
    const { path, exact, component, roles, role } = this.props;
    console.log(roles + " " + role);
    return (
      <Route
        path={path}
        exact={exact}
        render={(props) =>
          role === "Seller" ? (
            React.createElement(component, {
              ...props
            })
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  }
}

export default connect(
  (state) => ({ role: state.currentuser.role }),
  (dispatch) => bindActionCreators({}, dispatch)
)(PrivateRoute);*/
