import React from "react";
import { Link } from "react-router-dom";
// import { Button } from "reactstrap";
import Button from "../../Button";

export default function FormActions({ cancelPath, submitCaption, loading, children }) {
  return (
    <div className="text-right form-actions">
      {children}
      <Link to={cancelPath} className="btn btn-cancel-form">
        Cancel
      </Link>

      <Button
        color="primary"
        isLoading={loading}
        disabled={loading}
        type="submit"
      >
        {submitCaption || "Save"}
      </Button>
    </div>
  );
}
