import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./helpers";
import "./index.css";
import Admin from "./layouts/App/";
import axios from "axios";
import Firebase, { FirebaseContext } from "./components/Firebase/";
import * as serviceWorker from "./serviceWorker";
// import "bootstrap/dist/css/bootstrap.min.css";

import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faImage,
  faSearch,
  faTrash,
  faFileUpload,
  faFilePdf,
  faFileExcel,
  faFileImage,
  faFileAlt,
  faEllipsisH,
  faPlusSquare,
  faBalanceScale,
  faCodeBranch,
  faSitemap,
  faStream,
  faShieldAlt,
  faChevronLeft,
  faUsersCog,
  faSignOutAlt,
  faFileInvoice,
  faMapMarker,
  faDoorOpen,
  faSyncAlt,
  faBars
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faEdit,
  faTrash,
  faImage,
  faSearch,
  faFileUpload,
  faFilePdf,
  faFileExcel,
  faFileImage,
  faFileAlt,
  faEllipsisH,
  faPlusSquare,
  faBalanceScale,
  faCodeBranch,
  faSitemap,
  faStream,
  faShieldAlt,
  faChevronLeft,
  faUsersCog,
  faSignOutAlt,
  faFileInvoice,
  faMapMarker,
  faDoorOpen,
  faSyncAlt,
  faBars
);

var bugsnagClient = bugsnag({
  apiKey: "1f9faa26ddc0fe014aba4cac8107a6da___", // handypass account
  releaseStage: process.env.REACT_APP_ENV
});
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin("react");
axios.defaults.headers.common["x-access-token"] =
  process.env.REACT_APP_TOKEN_ENDPOINTS;
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <FirebaseContext.Provider value={new Firebase()}>
        <Admin />
      </FirebaseContext.Provider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("main-wrapper")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
