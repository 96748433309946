export const getFieldsAttributes = (values, fields) => {
  const res = [];

  const keys = Object.keys(values);
  fields.forEach(field => {
    if (keys.filter(key => key.includes(field)).length > 0) {
      const fieldData = {
        type: values[`${field}-field_type`],
        name: values[`${field}-field_name`],
        options: values[`${field}-field_options`].split("\n"),
        placeholder: values[`${field}-field_placeholder`],
        defaultValue: values[`${field}-field_default_value`],
        token:
          values[`${field}-field_token`] ||
          `field-${new Date().getTime()}`,
        sortOrder: values[`${field}-field_sort_order`] || 0,
        required: values[`${field}-field_required`]
      };
      res.push(fieldData);
    }
  });

  return res;
}
