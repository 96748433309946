import { combineReducers } from "redux";

import { registration } from "./registration.reducer";
import { global } from "./global.reducer";
import { users } from "./users.reducer";
import { currentUser } from "./currentuser.reducer";
import { currentorganization } from "./currentorganization.reducer";

const rootReducer = combineReducers({
  registration,
  global,
  users,
  currentUser,
  currentorganization
});

export default rootReducer;
