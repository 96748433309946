import axios from "axios";
import {auth} from "firebase"
const url =
  process.env.REACT_APP_ENV === "production"
    ? "https://us-central1-handypass-production.cloudfunctions.net/app"
    : "https://us-central1-handypass-dev.cloudfunctions.net/app";

// const url = "http://localhost:5000/handypass-production/us-central1/app";

const pgOrdersService = config => {
  const service = axios.create({
    baseURL: `${url}/replica/orders`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": "d0358c74ffb389ad56235eda29aa8a378ba7a2a2909a234d8a"
    }
  });

  return service(config);
};

const gcfOrdersService = config => {
  const service = axios.create({
    baseURL: `${url}/orders`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": "d0358c74ffb389ad56235eda29aa8a378ba7a2a2909a234d8a"
    }
  });

  return service(config);
};


const all = ({ limit, offset, filters = [] }) =>
  pgOrdersService({
    method: "GET",
    url: `?limit=${limit}&offset=${offset}${
      filters ? `&${filters.join("&")}` : ""
    }`
  }).then(({ data }) => data);

const salesReport = ({ filters = [] }) =>
  pgOrdersService({
    method: "GET",
    url: `/sales_report?${filters ? `&${filters.join("&")}` : ""}`
  }).then(response => response);

const ticketSalesReport = ({ filters = [] }) =>
  pgOrdersService({
    method: "GET",
    url: `/event_tickets_report?${filters ? `&${filters.join("&")}` : ""}`
  }).then(response => response);

const allToCSV = ({ filters = [] }) =>
  pgOrdersService({
    method: "GET",
    url: `/to_csv?${filters ? `&${filters.join("&")}` : ""}&userId=${auth().currentUser.uid}`
  }).then(response => response);

const accessesToCSV = ({ filters = [] }) =>
  pgOrdersService({
    method: "GET",
    url: `/tickets/csv?${filters ? `&${filters.join("&")}` : ""}&userId=${auth().currentUser.uid}`
  }).then(response => response);

const sendNotification = (orgId, eventId, orderId) => {
  gcfOrdersService({
    method: "POST",
    data: {
      orgId,
      eventId,
      orderId
    }
  }).then(response => response);
}

const totalSales = ({ filters = []}) =>
  pgOrdersService({
    method: "GET",
    url: `/total_sales?${filters ? `&${filters.join("&")}` : ""}`,
  }).then(response => response.data);



export default {
  all,
  salesReport,
  ticketSalesReport,
  allToCSV,
  accessesToCSV,
  sendNotification,
  totalSales
};
