import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, FormGroup, Input } from "reactstrap";
export function ItemName({ title, value, name, onSubmit, onChange }) {
  const [mode, setMode] = useState(false);
  if (mode) {
    return (
      <Row>
        <Col className="col-2" md={2}>
          {title}
        </Col>
        <Col md={10} className="infoItem col-10">
          <Row form>
            <Col>
              <FormGroup>
                <Input
                  type="text"
                  value={name.firstname}
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Input
                  type="text"
                  name="lastName"
                  value={name.lastName}
                  id="lastName"
                  placeholder="Last Name"
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Link
            to="#"
            onClick={() => {
              setMode(false);
            }}
          >
            Cancel
          </Link>
          {" | "}
          <Link
            to="#"
            onClick={() => {
              onSubmit();
              setMode(false);
            }}
          >
            Save
          </Link>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col className="col-2" md={2}>
          {title}
        </Col>
        <Col md={10} className="infoItem col-10">
          {value}
          <Link
            to="#"
            onClick={() => {
              setMode(true);
            }}
          >
            <span>(edit)</span>
          </Link>
        </Col>
      </Row>
    );
  }
}
