import React from "react";
import { Link } from "react-router-dom";
import {
  organization_event_path,
  edit_organization_batch_order_path,
  order_path
} from "../../helpers/paths";

import { Button, UncontrolledTooltip } from "reactstrap";

import dotsIcon from "../../images/icons/dots.svg";
import permissions from "../../helpers/permissions";

import Moment from "react-moment";
import "moment-timezone";
import { centsToDecimal } from "../../helpers/currency";
import StatusBadge from "../../components/Admin/StatusBadge";

export default function Order(props) {
  const {
    createdAt,
    id,
    Event,
    orderStatus,
    User,
    quantity,
    subtotal,
    fee,
    total,
    organizationId,
    eventId,
    isValid,
    validationErrors
  } = props.record;

  return (
    <tr key={id} role="row" className="order-row">
      <td>
        <Link to={order_path(organizationId, eventId, id)}>
          {new Date(createdAt).toLocaleString()}
        </Link>
      </td>
      <td>
        <Link to={order_path(organizationId, eventId, id)}>{id}</Link>
      </td>
      <td>
        <Link to={order_path(organizationId, eventId, id)}>
          {Event ? Event.name : "--"}
        </Link>
      </td>
      <td>
        <Link to={order_path(organizationId, eventId, id)}>
          <StatusBadge status={orderStatus} />
        </Link>
      </td>
      <td>
        <Link to={order_path(organizationId, eventId, id)}>
          {User ? User.name : "--"}
        </Link>
      </td>
      <td>
        <Link to={order_path(organizationId, eventId, id)}>{quantity}</Link>
      </td>
      <td>
        <Link to={order_path(organizationId, eventId, id)}>
          $ {centsToDecimal(subtotal, true)}
        </Link>
      </td>
      <td>
        <Link to={order_path(organizationId, eventId, id)}>
          $ {centsToDecimal(fee, true)}
        </Link>
      </td>
      <td>
        <Link to={order_path(organizationId, eventId, id)}>
          $ {centsToDecimal(total, true)}
        </Link>
      </td>
      <td>
        {isValid === null ? "pending" : isValid ? "valid" : "invalid"}
      </td>
      <td>
        <Button
          size="sm"
          color="default"
          disabled={orderStatus != "PAID"}
          className="btn-resend"
          onClick={() => props.sendNotification(props.record)}
        >
          Resend
        </Button>
      </td>
    </tr>
  );
}
