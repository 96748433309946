import React from "react";
import { Switch, Route } from "react-router-dom";

import Forms from "../../../../pages/forms";

export default function Application() {
  return (
    <Switch>
      <Route exact path="/forms" component={Forms.List} />
      <Route exact path="/forms/new" component={Forms.Form} />
      <Route
        exact
        path="/:orgId/forms/:formId/edit"
        component={Forms.Form}
      />
    </Switch>
  );
}
