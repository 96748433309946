import React from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import permissions from "../../helpers/permissions";
import { compose } from "recompose";

import {
  organization_event_path,
  edit_organization_event_path,
  organization_event_phases_path,
  organization_event_ticket_types_path,
  organization_event_products_path,
  activeLink,
  getEventActiveSection
} from "../../helpers/paths";

function EventDropdownMenu(props) {
  const { eventId, organizationId } = props.match.params;
  const { path: currentPath } = props.match;
  const [activeSection, setActiveSection] = React.useState(null);

  React.useEffect(() => {
    setActiveSection(getEventActiveSection(currentPath));
  }, [])

  return (
    <UncontrolledDropdown className="event-dropdown-menu-wrapper">
      <DropdownToggle outline className="event-dropdown-menu" caret>
        { activeSection && activeSection.label }
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Settings</DropdownItem>
        <DropdownItem
          className={activeLink(currentPath)}
          tag={Link}
          to={organization_event_path(organizationId, eventId)}
        >
          Sales Report
        </DropdownItem>
        <DropdownItem
          className={activeLink(currentPath, "checkin_report")}
          tag={Link}
          to={organization_event_path(
            organizationId,
            eventId,
            "/checkin_report"
          )}
        >
          Check In Report
        </DropdownItem>
        <DropdownItem
          className={activeLink(currentPath, "edit")}
          tag={Link}
          to={edit_organization_event_path(organizationId, eventId)}
        >
          General Information
        </DropdownItem>
        <DropdownItem
          className={activeLink(currentPath, "Venue&Date")}
          tag={Link}
          to={organization_event_path(organizationId, eventId, "/Venue&Date")}
        >
          Venue & Dates
        </DropdownItem>

        <DropdownItem
          className={activeLink(currentPath, "products")}
          tag={Link}
          to={organization_event_products_path(organizationId, eventId)}
        >
          Products & Services
        </DropdownItem>

        {permissions.can("ticket_types", "read") && (
          <DropdownItem
            className={activeLink(currentPath, "ticket_types")}
            tag={Link}
            to={organization_event_ticket_types_path(organizationId, eventId)}
          >
            Ticket Types
          </DropdownItem>
        )}

        {permissions.can("phases", "read") && (
          <DropdownItem
            className={activeLink(currentPath, "phases")}
            tag={Link}
            to={organization_event_phases_path(organizationId, eventId)}
          >
            Phases
          </DropdownItem>
        )}

        <DropdownItem
          className={activeLink(currentPath, "courtesy_ticket_types")}
          tag={Link}
          to={`/${organizationId}/events/${eventId}/courtesy_ticket_types`}
        >
          Courtesy ticket types
        </DropdownItem>
        <DropdownItem
          className={activeLink(currentPath, "courtesies")}
          tag={Link}
          to={`/${organizationId}/events/${eventId}/courtesies`}
        >
          Courtesies
        </DropdownItem>
        <DropdownItem
          className={activeLink(currentPath, "coupons")}
          tag={Link}
          to={`/${organizationId}/events/${eventId}/coupons`}
        >
          Coupons
        </DropdownItem>

        <DropdownItem
          className={activeLink(currentPath, "batch_orders")}
          tag={Link}
          to={`/${organizationId}/events/${eventId}/batch_orders`}
        >
          Tickets in batches
        </DropdownItem>

        <DropdownItem
          className={activeLink(currentPath, "check_staff")}
          tag={Link}
          to={`/${organizationId}/events/${eventId}/check_staff`}
        >
          Check in Staff
        </DropdownItem>

        <DropdownItem header>Design</DropdownItem>

        <DropdownItem
          className={activeLink(currentPath, "Design")}
          tag={Link}
          to={organization_event_path(organizationId, eventId, "/Design")}
        >
          Design
        </DropdownItem>

        <DropdownItem
          className={activeLink(currentPath, "pdf_settings")}
          tag={Link}
          to={`/${organizationId}/events/${eventId}/pdf_settings`}
        >
          PDF
        </DropdownItem>

        <DropdownItem tag={Link} disabled to={""}>
          Danger Zone
        </DropdownItem>

      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default compose(
  withRouter,
  connect(state => ({ currentUser: state.currentUser }))
)(EventDropdownMenu);
