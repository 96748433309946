import React from "react";

export default function CheckBox(props) {
  let defaultProps = {
    id,
    checked: false,
    name: "",
    label: "",
    disabled: false
  };

  const {
    id,
    name,
    checked,
    label,
    onChange,
    disabled
  } = {
    ...defaultProps,
    ...props
  };

  return (
    <div className="control-group">
      <label className="control control-checkbox" id={`checkbox-${id}-label`}>
        {label}
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <div className="control_indicator"></div>
      </label>
    </div>
  );
}


/* <div className="control-group">
    <label className="control control-checkbox">
        First checkbox
            <input type="checkbox" checked="checked" />
        <div className="control_indicator"></div>
    </label>
    <label className="control control-checkbox">
        Second checkbox
            <input type="checkbox" />
        <div className="control_indicator"></div>
    </label>
    <label className="control control-checkbox">
        Third checkbox
            <input type="checkbox" />
        <div className="control_indicator"></div>
    </label>
    <label className="control control-checkbox">
        Disabled
            <input type="checkbox" disabled="disabled" />
        <div className="control_indicator"></div>
    </label>
    <label className="control control-checkbox">
        Disabled & checked
            <input type="checkbox" disabled="disabled" checked="checked" />
        <div className="control_indicator"></div>
    </label>
</div>
*/