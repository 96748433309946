import React, { useEffect, useState } from "react";
import { useFormState } from "react-use-form-state";
import { Link } from "react-router-dom";

import { Row, Col, Button, Breadcrumb, BreadcrumbItem } from "reactstrap";

import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  FormFeedback,
  SelectFromCollection,
  SelectFromArray,
  DropzoneUploader,
  MarkdownInput,
  DateTimeInput,
  CurrencyInput
} from "../../../components/Formica";

import { withFirebase } from "../../../components/Firebase";
import { forms_path } from "../../../helpers/paths";

import FieldInputsList from "../../../components/FieldInputsList";

import { getFieldsAttributes } from "../../../helpers/form-fields";

const FormForm = props => {
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState("");
  const [
    formState,
    { text, email, checkbox, number, select, textarea }
  ] = useFormState();

  const [fields, setFields] = useState(["field1"]);
  const [fieldsQty, setFieldsQty] = useState(1);
  const [form, setForm] = useState({});
  const { orgId, formId } = props.match.params;

  useEffect(() => {
    async function fetchOrganizations() {
      const organizationsCollection = await props.firebase.getOrganizationCollection();
      var self = this;
      const fbResult = await organizationsCollection.get();
      let organizations = fbResult.docs;
      setOrganizations(organizations);
    }
    fetchOrganizations();
  }, []);

  async function getForm(orgId, formId) {
    const f = await props.firebase.getForm(orgId, formId);
    const { title, description, organizationId, fields } = f.data();
    formState.setField("title", title);
    formState.setField("description", description);
    formState.setField("organizationId", organizationId);

    if (fields.length === 0) {
      // No fields
      setFields(["field1"]);
      setFieldsQty(1);
      setLoading(false);
      return;
    }

    const fieldsArray = [];
    let fieldsQty = 1;
    fields.forEach(field => {
      formState.setField(`field${fieldsQty}-field_type`, field.type);

      formState.setField(`field${fieldsQty}-field_name`, field.name);

      formState.setField(
        `field${fieldsQty}-field_placeholder`,
        field.placeholder
      );
      formState.setField(
        `field${fieldsQty}-field_default_value`,
        field.defaultValue
      );
      formState.setField(
        `field${fieldsQty}-field_options`,
        field.options.join("\n")
      );
      formState.setField(`field${fieldsQty}-field_sort_order`, field.sortOrder);
      formState.setField(`field${fieldsQty}-field_required`, field.required);
      formState.setField(
        `field${fieldsQty}-field_token`,
        field.token || `field-${new Date().getTime()}`
      );

      fieldsArray.push(`field${fieldsQty}`);
      fieldsQty += 1;
    });

    setFields(fieldsArray);
    setFieldsQty(fieldsQty);

    setLoading(false);
  }

  useEffect(() => {
    if (!formId) return;
    getForm(orgId, formId);
  }, []);

  function navigateBack() {
    const { history } = props;
    history.replace("/forms");
  }

  function handleSubmit(e) {
    const { orgId, formId } = props.match.params;
    e.preventDefault();
    const { title, organizationId, description } = formState.values;

    const data = {
      title,
      organizationId,
      description,
      fields: getFieldsAttributes(formState.values, fields)
    };

    formId
      ? props.firebase
          .updateForm(organizationId, formId, data)
          .then(navigateBack)
      : props.firebase.createForm(organizationId, data).then(navigateBack);
  }

  function addField() {
    setFields([...fields, `field${fieldsQty + 1}`]);
    setFieldsQty(fieldsQty + 1);
  }

  function removeField(name) {
    setFields([...fields.filter(field => field !== name)]);
  }

  return (
    <React.Fragment>

      <div className="section-header">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/forms">Forms</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {formId ? "Edit" : "New"}
          </BreadcrumbItem>

        </Breadcrumb>
      </div>

      <Form onSubmit={handleSubmit} className="form-compact">
        <FormFields>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="organizationId">Organization</Label>
                <Input
                  name="organizationId"
                  value={organizationId}
                  id="organizationId"
                  placeholder="Organization"
                  {...select("organizationId")}
                  disabled={props.match.params.formId}
                  type="select"
                  required
                >
                  <option value="">Select an option</option>
                  {organizations.map((record, i) => (
                    <option
                      key={record.id}
                      value={record.id}
                      defaultValue={record.id === organizationId}
                    >
                      {record.data().name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  name="title"
                  id="title"
                  placeholder="title..."
                  required
                  {...text("title")}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              name="description"
              id="description"
              placeholder="..."
              {...textarea("description")}
              type="textarea"
            />
          </FormGroup>

          <FieldInputsList
            fields={fields.map(name => ({
              name,
              fieldName: { ...text(`${name}-field_name`) },
              fieldType: { ...select(`${name}-field_type`) },
              fieldOptions: { ...textarea(`${name}-field_options`) },
              placeholder: { ...text(`${name}-field_placeholder`) },
              defaultValue: { ...text(`${name}-field_default_value`) },
              sortOrder: { ...text(`${name}-field_sort_order`) },
              token: { ...text(`${name}-field_token`) },
              required: { ...checkbox(`${name}-field_required`) }
            }))}
            addField={addField}
            removeField={removeField}
          />
        </FormFields>
        <FormActions cancelPath={forms_path()} submitCaption="Save" />

        {/* <div className="text-right form-actions">
            <Link to={forms_path()}>Cancel</Link> or{" "}
            <Button color="primary">Save</Button>
          </div> */}
      </Form>
    </React.Fragment>
  );
};

export default withFirebase(FormForm);
