import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
} from "reactstrap";

import { withRouter } from "react-router-dom";

import { withFirebase } from "../../components/Firebase";
import { useFormik } from "formik";
import * as yup from "yup";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";


import {
  Form,
  Label,
  FormGroup,
  Input,
  FormFeedback,
  FormActions,
  FormFields,
  DropzoneUploader
} from "../../components/Formica";

const validationSchema = yup.object({
  title: yup
    .string()
    .required()
    .label("Title"),
  sortOrder: yup
    .number()
    .required()
    .label("Sort order"),

});

const initialValues = {
  image: "",
  title: "",
  subtitle: "",
  link: "",
  linkCaption: "",
  sortOrder: 0,
};

const handleFormatData = values => {
  const {
    image,
    title,
    subtitle,
    link,
    linkCaption,
    sortOrder
  } = values;

  return {
    image,
    title,
    subtitle,
    link,
    linkCaption,
    sortOrder: parseInt(sortOrder)
  }
};

function SliderItemForm(props) {
  const { id } = props.match.params;
  const currentUserId = props.uid;

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    setTouched,
    setValues
  } = useFormik({
    initialValues,
    validate: values => {
      // console.log(values);
    },
    onSubmit: async values => {
      const now = new Date();

      let sliderItem = handleFormatData(values);

      if (id) {
        //UPDATE
        await props.firebase.updateSliderItem(id, sliderItem);
      } else {
        //CREATE
        sliderItem.createdAt = new Date();
        sliderItem.createdAt = now;
        sliderItem.updatedAt = now;
        sliderItem.authorId = currentUserId;

        props.firebase.createSliderItem(sliderItem);
      }
      props.history.push("/slider_items");
    },
    validationSchema
  });

  useEffect(() => {
    const getSliderData = async () => {
      if (id) {
        const sliderItemDoc = await props.firebase.getSliderItem(id);
        let sliderItem = sliderItemDoc.data();
        sliderItem.image = sliderItem.image || "";
        setValues(sliderItem);
      }
    };
    getSliderData();
  }, []);

  return (
    <React.Fragment>

      <div className="section-header">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/slider_items">Featured Events</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {id ? "Edit" : "New"}
          </BreadcrumbItem>

        </Breadcrumb>
      </div>

      <Form className="form-horizontal form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <FormGroup>
            <Label for="sliderItemTitle">Title</Label>
            <Input
              type="text"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Title"
              invalid={errors.title && true}
            />
            <FormFeedback>{errors.title}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="sliderItemSubtitle">Subtitle</Label>
            <Input
              type="text"
              name="subtitle"
              value={values.subtitle}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="subtitle"
              invalid={errors.subtitle && true}
            />
            <FormFeedback>{errors.subtitle}</FormFeedback>
          </FormGroup>


          <FormGroup>
            <Label className="label-block" for="image">Logo</Label>

            <DropzoneUploader
              path="slider_items/image/"
              name="image"
              onChange={(url) => setFieldValue("image", url)}
              image={values.image}
              className="image-uploader"
              legend="Recomended size|1440x600"
              previewImage={values.image}
            />
          </FormGroup>

          <Row>
            <Col md="6">
              <FormGroup>
              <Label for="sliderItemLink">Link</Label>
              <Input
                type="text"
                name="link"
                value={values.link}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="link"
                invalid={errors.link && true}
              />
              <FormFeedback>{errors.link}</FormFeedback>
            </FormGroup>

            </Col>
            <Col md="4">
              <FormGroup>
              <Label for="sliderItemLink">Link caption</Label>
              <Input
                type="text"
                name="linkCaption"
                value={values.linkCaption}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="linkCaption"
                invalid={errors.linkCaption && true}
              />
              <FormFeedback>{errors.linkCaption}</FormFeedback>
            </FormGroup>

            </Col>

            <Col md="2">
              <FormGroup>
              <Label for="sliderItemLink">Item order</Label>
              <Input
                type="text"
                name="sortOrder"
                value={values.sortOrder}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="sortOrder"
                invalid={errors.sortOrder && true}
              />
              <FormFeedback>{errors.sortOrder}</FormFeedback>
            </FormGroup>

            </Col>


          </Row>
        </FormFields>
        <FormActions cancelPath="/slider_items" />
      </Form>
    </React.Fragment>

  );
}

export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({ uid: state.currentUser.uid }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(SliderItemForm);
