import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import { Breadcrumb, BreadcrumbItem, Row, Col } from "reactstrap";
import { withFirebase } from "../../components/Firebase";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  Form,
  Label,
  FormGroup,
  Input,
  FormFeedback,
  FormActions,
  FormFields,
  DropzoneUploader
} from "../../components/Formica";

const validationSchema = yup.object({
  name: yup
    .string()
    .required()
    .label("Name"),
  slug: yup
    .string()
    .required()
    .label("Slug"),
  organizationStatus: yup
    .string()
    .required()
    .label("Status"),
  eventFilter: yup
    .string()
    .required()
    .label("Event Filter")
});

const initialValues = {
  headerImage: "",
  logo: "",
  slug: "",
  name: "",
  description: "",
  facebookLink: "",
  twitterLink: "",
  createdAt: "",
  updatedAt: "",
  organizationStatus: "ACTIVE",
  author: "",
  gmId: "",
  intercomId: "",
  googleAnalyticsId: "",
  facebookPixelId: "",
  eventFilter: "DATE"
};
const handleFormatData = values => {
  return {
    slug: values.slug,
    name: values.name,
    description: values.description,
    facebookLink: values.facebookLink,
    twitterLink: values.twitterLink,
    logo: values.logo || "",
    headerImage: values.headerImage || "",
    updatedAt: new Date(),
    organizationStatus: values.organizationStatus,
    gmId: values.gmId,
    intercomId: values.intercomId,
    googleAnalyticsId: values.googleAnalyticsId,
    facebookPixelId: values.facebookPixelId,
    eventFilter: values.eventFilter
  };
};

function OrganizationForm(props) {
  const { organizationId } = props.match.params;
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    setTouched,
    setValues
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      let organization = handleFormatData(values);
      if (organizationId) {
        //UPDATE
        await props.firebase.updateOrganization(organization, organizationId);
      } else {
        //CREATE
        organization.createdAt = new Date();
        props.firebase.addOrganization(organization);
      }
      props.history.push("/organizations");
    },
    validationSchema
  });

  useEffect(() => {
    const handleGetOrganizationData = async () => {
      if (organizationId) {
        //SET INITIAL VALUES - WHEN ORG EXIST
        const organizationDoc = await props.firebase.getOrganization(
          organizationId
        );
        let organization = organizationDoc.data();
        organization.logo = organization.logo || "";
        organization.headerImage = organization.headerImage || "";
        setValues(organization);
      }
    };
    handleGetOrganizationData();
  }, []);

  function handleGetUrlHeaderImage(url) {
    setFieldValue("headerImage", url);
  }

  function handleGetUrlLogo(url) {
    setFieldValue("logo", url);
  }
  return (
    <React.Fragment>
      <div className="section-header">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/organizations">Organizations</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {organizationId ? "Edit" : "New"}
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <Form className="form-horizontal form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="organizationName">Organization's Name</Label>
                <Input
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Name"
                  invalid={errors.name && true}
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="organizationSlug">Slug</Label>
                <Input
                  type="text"
                  name="slug"
                  value={values.slug}
                  id="organizationSlug"
                  onChange={handleChange}
                  placeholder="handypass.com/«namespace»"
                  invalid={errors.slug && true}
                />
                <FormFeedback>{errors.slug}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="organizationDescription">Description</Label>
            <Input
              type="textarea"
              rows="4"
              name="description"
              value={values.description}
              id="organizationDescription"
              onChange={handleChange}
              invalid={errors.description && true}
            />
            <FormFeedback>{errors.description}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label className="label-block" for="logo">
              Logo
            </Label>

            <DropzoneUploader
              path="organizations/logo/"
              name="logo"
              onChange={url => setFieldValue("logo", url)}
              image={values.logo}
              className="logo-uploader"
              legend="Recomended size|320x320"
              previewImage={values.logo}
            />
          </FormGroup>

          <FormGroup>
            <Label className="label-block" for="headerImage">
              Header image
            </Label>

            <DropzoneUploader
              path="organizations/headerImage/"
              name="headerImage"
              onChange={url => setFieldValue("headerImage", url)}
              image={values.headerImage}
              className="header-uploader"
              legend="Recomended size|820x360"
              previewImage={values.headerImage}
            />
          </FormGroup>

          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="eventGmId">Google Tag Id</Label>
                <Input
                  type="text"
                  name="gmId"
                  value={values.gmId}
                  id="eventGmId"
                  onChange={handleChange}
                  placeholder="GmId"
                  invalid={errors.gmId && true}
                />
                <FormFeedback>{errors.description}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="eventGmId">Intercom Id</Label>
                <Input
                  type="text"
                  name="intercomId"
                  value={values.intercomId}
                  id="eventGmId"
                  onChange={handleChange}
                  placeholder="Intercom Id"
                  invalid={errors.intercomId && true}
                />
                <FormFeedback>{errors.intercomId}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="eventGmId">Google Analytics Id</Label>
                <Input
                  type="text"
                  name="googleAnalyticsId"
                  value={values.googleAnalyticsId}
                  id="eventGmId"
                  onChange={handleChange}
                  placeholder="Google Analytics Id"
                  invalid={errors.googleAnalyticsId && true}
                />
                <FormFeedback>{errors.googleAnalyticsId}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="eventGmId">Facebook Pixel Id</Label>
                <Input
                  type="text"
                  name="facebookPixelId"
                  value={values.facebookPixelId}
                  id="eventGmId"
                  onChange={handleChange}
                  placeholder="Facebook Pixel Id"
                  invalid={errors.facebookPixelId && true}
                />
                <FormFeedback>{errors.facebookPixelId}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="organizationfacebookLink">Facebook</Label>
                <Input
                  type="text"
                  name="facebookLink"
                  value={values.facebookLink}
                  id="organizationfacebookLink"
                  onChange={handleChange}
                  placeholder="your facebok"
                  invalid={errors.facebookLink && true}
                />
                <FormFeedback>{errors.facebookLink}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="organizationtwitterLink">Twitter</Label>
                <Input
                  type="text"
                  name="twitterLink"
                  value={values.twitterLink}
                  id="organizationtwitterLink"
                  onChange={handleChange}
                  placeholder="your twitter"
                  invalid={errors.twitterLink && true}
                />
                <FormFeedback>{errors.twitterLink}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="eventFilter">Event Filter (Date is default)</Label>
                <Input
                  onChange={handleChange}
                  type="select"
                  id="eventFilter"
                  name="eventFilter"
                  value={values.eventFilter}
                  invalid={errors.eventFilter && true}
                >
                  <option value="">Select a option</option>
                  <option value="DATE">Date</option>
                  <option value="PRICE">Price</option>
                </Input>
                <FormFeedback>{errors.eventFilter}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="organizationStatus">Status</Label>
                <Input
                  onChange={handleChange}
                  type="select"
                  name="organizationStatus"
                  id="organizationStatus"
                  value={values.organizationStatus}
                  invalid={errors.organizationStatus && true}
                >
                  <option value="">Select a status</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </Input>
                <FormFeedback>{errors.organizationStatus}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </FormFields>
        <FormActions cancelPath="/organizations" />
      </Form>
    </React.Fragment>
  );
}
export default withFirebase(OrganizationForm);
