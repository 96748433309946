import React from 'react';
import { Table } from 'reactstrap';

import loadingGif from '../../images/loading.gif';

import './index.scss';

const CustomTable = ({
  changePage,
  children,
  headers,
  loading,
  paginationData,
}) => (
  <>
  {
    loading ? (
      <>
        <Table responsive className="custom-table">
          <thead>
            <tr>
              {
                headers.map(header => (
                  <th key={header}>{header}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            <tr>
              {
                headers.map(header => (
                  <td key={`${header}-td`} />
                ))
              }
            </tr>
          </tbody>
        </Table>
        <img
          className="custom-table-loading"
          src={loadingGif}
          alt="loading..."
        />
      </>
    ) : (
      <Table responsive>
        <thead>
          <tr>
            {
              headers.map(header => (
                <th key={header}>{header}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </Table>
    )}

    {/* <PaginationBar
      paginationData={paginationData}
      changePage={changePage}
    /> */}
  </>
);

export default CustomTable;
