import React from "react";
import { Link } from "react-router-dom";

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import dotsIcon from "../../images/icons/dots.svg";
import permissions from "../../helpers/permissions";

export default function TicketType(props) {
  const {
    id,
    name,
    eventId
  } = props.record;
  const { organizationId } = props;

  return (
    <tr key={id} role="row" className="ticket-type-row">
      <td role="cell">{name}</td>
      <td className="text-right full-width">
        <UncontrolledDropdown direction="left" className="row-options-trigger">
          <DropdownToggle tag="span">
            <img src={dotsIcon} alt="Options" />
          </DropdownToggle>
          <DropdownMenu>

            {permissions.can("ticket_types", "edit") && (
              <DropdownItem
                tag={Link}
                to={`/${organizationId}/events/${eventId}/ticket_types/${id}/edit`}
              >
                Edit
              </DropdownItem>
            )}

            <DropdownItem divider />

            {permissions.can("ticket_types", "delete") && (
              <DropdownItem
                onClick={() =>
                  props.confirmDestroy(
                    organizationId,
                    eventId,
                    id
                  )
                }
                className="text-danger"
              >
                Destroy
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}
