import axios from "axios";

const url =
  process.env.REACT_APP_ENV === "production"
    ? "https://us-central1-handypass-production.cloudfunctions.net/app"
    : "https://us-central1-handypass-dev.cloudfunctions.net/app";
// const url = "http://localhost:5000/handypass-production/us-central1/app";

const service = config => {
  const service = axios.create({
    baseURL: `${url}/replica/access_logs`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": "d0358c74ffb389ad56235eda29aa8a378ba7a2a2909a234d8a"
    }
  });

  return service(config);
};

const count = (filters = []) =>
  service({
    method: "GET",
    url: `?${
      filters ? `&${filters.join("&")}` : ""
    }`
  }).then(({ data }) => data);

const getFilters = filters =>
  service({
    method: "GET",
    url: `/filters?${filters ? `&${filters.join("&")}` : ""}`
  }).then(({ data }) => data);

export default {
  count,
  getFilters
};
