import app from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/storage";
// import { environment } from "./config";

class Firebase {
  constructor() {
    //app.initializeApp(environment.firebase);
    this.db = app.firestore();
    this.auth = app.auth();
    this.FieldValue = app.firestore.FieldValue;
    this.storage = app.storage();
  }

  //getUserOrganizations = async () => await this.db.collection("organizations").where("","array-contains","")

  getOrganizations = async () => await this.db.collection("organizations");

  getOrganization = async id =>
    await this.db
      .collection("organizations")
      .doc(id)
      .get();

  deleteOrganization = async id =>
    await this.db
      .collection("organizations")
      .doc(id)
      .delete();

  updateOrganization = async (organization, id) =>
    await this.db
      .collection("organizations")
      .doc(id)
      .update(organization);

  addOrganization = async organization =>
    await this.db.collection("organizations").add(organization);

  // auth
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
  auth = () => this.auth();
  getUser = async uid => {
    return (
      await this.db
        .collection("users")
        .doc(uid)
        .get()
    ).data();
  };
  getUsersCollection = async () => {
    return await this.db
      .collection("users")
      .where("type", "==", "administrative")
      .get();
  };

  // events
  eventsCollection = organizationId =>
    this.db.collection(`organizations/${organizationId}/events`);

  eventsCollectionGroup = async () => await this.db.collectionGroup("events");

  getEvent = async (organizationId, eventId) =>
    await this.db
      .collection(`organizations/${organizationId}/events`)
      .doc(eventId)
      .get();

  getEventBySlug = async (organizationId, slug) =>
    await this.db
      .collectionGroup("events")
      .where("slug", "==", slug)
      .get();

  getEventsReferenceOrganizer = async uid => {
    return await this.db
      .collectionGroup("events")
      .where(`auth_user_organizer_ids`, "array-contains", uid);
  };

  //organizations
  getOrganizationCollection = async () =>
    await this.db.collection("organizations");
  // tickets
  ticketTypesCollection = async (orgId, eventId) =>
    await this.db.collection(
      `organizations/${orgId}/events/${eventId}/ticket_types`
    );

  getTicketType = async (orgId, eventId, id) =>
    await this.db
      .collection(`organizations/${orgId}/events/${eventId}/ticket_types`)
      .doc(id)
      .get();

  updateTicketType = async (orgId, eventId, id, ticketType) => {
    return await this.db
      .collection(`organizations/${orgId}/events/${eventId}/ticket_types`)
      .doc(id)
      .update(ticketType);
  };
  createTicketType = async (orgId, eventId, ticketType) => {
    return await this.db
      .collection(`organizations/${orgId}/events/${eventId}/ticket_types`)
      .add(ticketType);
  };
  deleteTicketType = async (orgId, eventId, id) => {
    return await this.db
      .collection(`organizations/${orgId}/events/${eventId}/ticket_types`)
      .doc(id)
      .delete();
  };

  // phases
  phasesCollection = async (orgId, eventId) =>
    this.db
      .collection(`organizations/${orgId}/events/${eventId}/phases`)
      .orderBy("sortOrder");
  createPhase = async (orgId, eventId, ticketType) => {
    return await this.db
      .collection(`organizations/${orgId}/events/${eventId}/phases`)
      .add(ticketType);
  };
  getPhase = async (orgId, eventId, id) =>
    await this.db
      .collection(`organizations/${orgId}/events/${eventId}/phases`)
      .doc(id)
      .get();
  updatePhase = async (orgId, eventId, id, phase) => {
    return await this.db
      .collection(`organizations/${orgId}/events/${eventId}/phases`)
      .doc(id)
      .update(phase);
  };
  deletePhase = async (orgId, eventId, id) => {
    return await this.db
      .collection(`organizations/${orgId}/events/${eventId}/phases`)
      .doc(id)
      .delete();
  };

  productsCollection = (orgId, eventId) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("products")
      .orderBy("sortOrder");
  };
  createProduct = async (orgId, eventId, product) => {
    return await this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("products")
      .add(product);
  };

  getProduct = async (orgId, eventId, id) => {
    return await this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("products")
      .doc(id)
      .get();
  };

  updateProduct = async (orgId, eventId, id, product) => {
    return await this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("products")
      .doc(id)
      .update(product);
  };
  deleteProduct = async (orgId, eventId, id) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("products")
      .doc(id)
      .delete();
  };

  couponsCollection = (orgId, eventId) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("coupons");
  };
  createCoupon = async (orgId, eventId, coupon) => {
    return await this.couponsCollection(orgId, eventId).add(coupon);
  };
  getCoupon = async (orgId, eventId, id) =>
    await this.couponsCollection(orgId, eventId)
      .doc(id)
      .get();
  updateCoupon = async (orgId, eventId, id, coupon) => {
    return await this.couponsCollection(orgId, eventId)
      .doc(id)
      .update(coupon);
  };
  deleteCoupon = async (orgId, eventId, id) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("coupons")
      .doc(id)
      .delete();
  };

  // Courtesy ticket types:
  courtesyTicketTypesCollection = (orgId, eventId) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("courtesy_ticket_types");
  };
  createCourtesyTicketType = async (orgId, eventId, courtesyTicketType) => {
    return await this.courtesyTicketTypesCollection(orgId, eventId).add(
      courtesyTicketType
    );
  };
  getCourtesyTicketType = async (orgId, eventId, id) =>
    await this.courtesyTicketTypesCollection(orgId, eventId)
      .doc(id)
      .get();
  updateCourtesyTicketType = async (orgId, eventId, id, courtesyTicketType) => {
    return await this.courtesyTicketTypesCollection(orgId, eventId)
      .doc(id)
      .update(courtesyTicketType);
  };
  deleteCourtesyTicketType = async (orgId, eventId, id) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("courtesy_ticket_types")
      .doc(id)
      .delete();
  };

  // Courtesies
  courtesiesCollection = (orgId, eventId) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("courtesies");
  };
  createCourtesy = async (orgId, eventId, courtesy) => {
    return await this.courtesiesCollection(orgId, eventId).add(courtesy);
  };
  getCourtesy = async (orgId, eventId, id) =>
    await this.courtesiesCollection(orgId, eventId)
      .doc(id)
      .get();
  updateCourtesy = async (orgId, eventId, id, courtesy) => {
    return await this.courtesiesCollection(orgId, eventId)
      .doc(id)
      .update(courtesy);
  };
  deleteCourtesy = async (orgId, eventId, id) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("courtesies")
      .doc(id)
      .delete();
  };

  uploadImage = (type, partialPath = "") => (file, metadata) => {
    return new Promise((resolve, reject) => {
      const path = `${partialPath}/${type}/`;
      const uploadImg = this.storage.ref(`${path}${metadata.name}`).put(file);
      uploadImg.on(`state_changed`, null, null, async () => {
        try {
          const url = await this.storage
            .ref(path)
            .child(metadata.name)
            .getDownloadURL();
          resolve({ [type]: url });
        } catch (error) {
          reject(error);
        }
      });
    });
  };
  usersCollection = async () => {
    return this.db.collection("users");
  };

  canLogIn = async email => {
    const users = await this.db
      .collection("users")
      .where("email", "==", email)
      .get();
    if (users.empty) {
      return false;
    }

    const user = users.docs[0].data();
    if (user.isAdmin) {
      return true;
    }
    const roles = [];
    user.roles.forEach(item => {
      roles.push(item.role);
    });
    const isOrganizer = roles.includes("organizer");
    const isBoxOfficeStaff = roles.includes("box_office");
    const isOrganizationAdmin = roles.includes("organization_admin");
    const isAnalyst = roles.includes("analyst");

    return isOrganizationAdmin || isOrganizer || isBoxOfficeStaff || isAnalyst;
  };

  getPermissions(role) {
    this.db
      .collection("permissions")
      .doc(role)
      .get();
  }

  getUserData = async () => {
    return await this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .get();
  };

  getCouponByCode = async (orgId, eventId, code) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("coupons")
      .where("code", "==", code)
      .get();
  };

  organizationEventsCollection = organizationId => {
    return this.db.collection(`organizations/${organizationId}/events`);
  };

  // Forms
  formsCollectionGroup = () => this.db.collectionGroup("forms");
  formsCollection = orgId => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("forms");
  };
  createForm = async (orgId, formData) => {
    return await this.formsCollection(orgId).add(formData);
  };
  getForm = async (orgId, id) =>
    await this.formsCollection(orgId)
      .doc(id)
      .get();
  updateForm = async (orgId, id, formData) => {
    return await this.formsCollection(orgId)
      .doc(id)
      .update(formData);
  };
  deleteForm = async (orgId, id) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("forms")
      .doc(id)
      .delete();
  };

  organizationDoc = orgId => {
    return this.db.collection("organizations").doc(orgId);
  };
  // Event
  eventDoc = (orgId, eventId) => {
    return this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId);
  };
  // Orders
  ordersCollection = (orgId, eventId) => {
    return this.eventDoc(orgId, eventId).collection("orders");
  };
  orderDoc = (orgId, eventId, orderId) => {
    return this.ordersCollection(orgId, eventId).doc(orderId);
  };
  paymentsCollection = (orgId, eventId, orderId) => {
    return this.orderDoc(orgId, eventId, orderId).collection("payments");
  };
  phasesCol = (orgId, eventId) =>
    this.db
      .collection(`organizations/${orgId}/events/${eventId}/phases`)
      .orderBy("sortOrder");

  //Details

  updateStatusOrder = async (organizationId, eventId, orderId, data) => {
    return await this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("events")
      .doc(eventId)
      .collection("orders")
      .doc(orderId)
      .update(data);
  };

  getSaleReportPhase = async (organizationId, eventId, phaseId, salesId) => {
    return await this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("events")
      .doc(eventId)
      .collection("phases")
      .doc(phaseId)
      .collection("sales_indicators")
      .doc(salesId)
      .get();
  };
  getSaleReportProducts = async (
    organizationId,
    eventId,
    productsId,
    salesId
  ) => {
    return await this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("events")
      .doc(eventId)
      .collection("products")
      .doc(productsId)
      .collection("sales_indicators")
      .doc(salesId)
      .get();
  };
  UpdateSaleReportPhase = async (
    organizationId,
    eventId,
    phaseId,
    salesId,
    data
  ) => {
    return await this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("events")
      .doc(eventId)
      .collection("phases")
      .doc(phaseId)
      .collection("sales_indicators")
      .doc(salesId)
      .update(data);
  };
  UpdateSaleReportProducts = async (
    organizationId,
    eventId,
    productsId,
    salesId,
    data
  ) => {
    return await this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("events")
      .doc(eventId)
      .collection("products")
      .doc(productsId)
      .collection("sales_indicators")
      .doc(salesId)
      .update(data);
  };
  updatePayments = async (
    organizationId,
    eventId,
    orderId,
    paymentsId,
    data
  ) => {
    return this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("events")
      .doc(eventId)
      .collection("orders")
      .doc(orderId)
      .collection("payments")
      .doc(paymentsId)
      .update(data);
  };

  getOrderTicketsStatic = async (organizationId, eventId, orderId) =>
    await this.db
      .collection(
        `/organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_tickets`
      )
      .get();
  getTickets = async (organizationId, eventId, orderId, ticketId) =>
    await this.db
      .collection(
        `/organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_tickets/${ticketId}/tickets`
      )
      .get();

  getOrderProductsCollectionStatic = async (organizationId, eventId, orderId) =>
    await this.db
      .collection(
        `/organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_products`
      )
      .get();
  getOrderProducts = async (organizationId, eventId, orderId, productsId) =>
    await this.db
      .collection(
        `/organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_products/${productsId}/purchased_products`
      )
      .get();

  getOrderCourtesiesCollection = async (organizationId, eventId, orderId) => {
    return await this.db
      .collection("organizations")
      .doc(organizationId)
      .collection("events")
      .doc(eventId)
      .collection("orders")
      .doc(orderId)
      .collection("order_courtesies")
      .get();
  };

  createUserEvents = async (userId, data) => {
    return await this.db
      .collection("users")
      .doc(userId)
      .collection("user_events")
      .add(data);
  };
  updateUserEvents = async (userId, id, data) => {
    return await this.db
      .collection("users")
      .doc(userId)
      .collection("user_events")
      .doc(id)
      .update(data);
  };
  deleteUserEvents = async (userId, id) => {
    return await this.db
      .collection("users")
      .doc(userId)
      .collection("user_events")
      .doc(id)
      .delete();
  };

  getUserEventsCollection = userId => {
    return this.db
      .collection("users")
      .doc(userId)
      .collection("user_events");
  };
  getUserEvents = async (userId, id) => {
    return await this.db
      .collection("users")
      .doc(userId)
      .collection("user_events")
      .doc(id)
      .get();
  };
  getUserEventsCollectionGroup = eventId => {
    return this.db
      .collectionGroup("user_events")
      .where("eventId", "==", eventId);
  };

  getUsersIdEvente = uid => {
    return this.db
      .collectionGroup("events")
      .where("auth_user_check_in_ids", "array-contains", uid);
  };

  ticketsCollection = async (orgId, eventId) => {
    return await this.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("ticket_types")
      .get();
  };
  updateUser = async (userData, id) => {
    return await this.db
      .collection("users")
      .doc(id)
      .update(userData);
  };
  myEvents = isAdmin => {
    const now = new Date();
    const ref = this.db
      .collectionGroup("events")
      .where("eventStatus", "==", "ACTIVE");
    // .where("startsAt", ">", now);

    return isAdmin
      ? ref
      : ref.where(
          "auth_user_box_office_ids",
          "array-contains",
          this.auth.currentUser.uid
        );
  };

  administrativeUsers = () => {
    return this.db
      .collection("users")
      .where("type", "==", "administrative")
      .orderBy("name.firstName");
  };

  ticketTypes = (orgId, eventId) =>
    this.db.collection(`organizations/${orgId}/events/${eventId}/ticket_types`);

  eventPhasesCollection = (orgId, eventId) =>
    this.db
      .collection(`organizations/${orgId}/events/${eventId}/phases`)
      .orderBy("sortOrder");


  sliderItemsCollection = () => this.db.collection("slider_items");

  createSliderItem = sliderItem =>
    this.sliderItemsCollection().add(sliderItem);

  getSliderItem = id =>
    this.sliderItemsCollection()
      .doc(id)
      .get();

  updateSliderItem = (id, sliderItem) =>
    this.sliderItemsCollection()
      .doc(id)
      .update(sliderItem);

  deleteSliderItem = id =>
    this.sliderItemsCollection()
      .doc(id)
      .delete();
}

export default Firebase;
