import React from "react";

import { Link } from "react-router-dom";

import dotsIcon from "../../images/icons/dots.svg";

import { edit_organization_path } from "../../helpers/paths";

import StatusBadge from "../../components/Admin/StatusBadge";

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import permissions from "../../helpers/permissions";

export default function Organization(props) {
  const { id, logo, name, organizationStatus } = props.record;

  return (
    <tr key={id} className="organization-row">
      <td role="cell">
        <Link to={edit_organization_path(id)}>
          <img src={logo} className="table-img" alt={name} />
        </Link>
      </td>
      <td role="cell">
        <Link to={edit_organization_path(id)}>
          <h6>{name}</h6>
        </Link>
      </td>
      <td role="cell">
        <StatusBadge status={organizationStatus} />
      </td>
      <td role="cell" className="text-right">

        <UncontrolledDropdown direction="left">
          <DropdownToggle tag="span">
            <img src={dotsIcon} alt="Options" />
          </DropdownToggle>
          <DropdownMenu>
            {permissions.can("organizations", "edit") && (
              <DropdownItem
                tag={Link}
                to={edit_organization_path(id)}
              >
                Edit
              </DropdownItem>
            )}
            <DropdownItem divider />
            {permissions.can("organizations", "delete") && (
              <DropdownItem
                onClick={() => props.confirmDestroy(id)} className="text-danger">
                Destroy
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}
