import React from "react";
import { Card } from "./Card";
import { Item } from "./Item";
import { centsToDecimal } from "../../helpers/currency";
export function Product({ product }) {
  return (
    <>
      <Card title="Product">
        <Item title="Product Name" value={product.name} />
        <Item title="Quantity" value={product.quantity || 0} />
        <Item
          title="Unit Price"
          value={"$" + centsToDecimal(product.unitPrice || 0).toFixed(2)}
        />
        <Item
          title="Fee Per Price"
          value={"$" + centsToDecimal(product.feePerUnit || 0).toFixed(2)}
        />
        <Item
          title="Total"
          value={"$" + centsToDecimal(product.total).toFixed(2)}
        />
      </Card>
      <br />
    </>
  );
}
