import React from "react";
import Moment from "react-moment";
import { centsToDecimal } from "../../../helpers/currency";
import "moment-timezone";
import StatusBadge from "./status";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

export default function Deposit({ deposit }) {
  const { id, date, status, type, bank, rfc, amount, Organization } = deposit;
  return (
    <tr key={id} role="row" className="deposit-row">
      <td role="cell">
        <small className="text-muted">
          <Moment format="YYYY/MM/DD HH:mm">{date}</Moment>
        </small>
      </td>
      <td role="cell">{id}</td>
      <td role="cell">{Organization ? Organization.name : ""}</td>
      <td role="cell">
        <StatusBadge status={status} />
      </td>
      <td role="cell">{type}</td>
      <td role="cell">{bank}</td>
      <td role="cell">{rfc}</td>
      <td role="cell">{formatter.format(centsToDecimal(amount))}</td>
    </tr>
  );
}
