import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DateTimeInput(props) {
  return (
    <div className={`datetime-input ${props.invalid && "is-invalid"}`}>
      <DatePicker
        {...props}
        className={`form-control ${props.invalid && "is-invalid"}`}
      />
    </div>
  );
}
