import React from "react";

import { Link } from "react-router-dom";

import { withFirebase } from "../../components/Firebase";

import EventsContainer from "../../components/EventsContainer";

import { compose } from "recompose";

import { connect } from "react-redux";

import permissions from "../../helpers/permissions";

import { Table } from "reactstrap";

import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";

import TableHead from "../../components/Admin/TableHead";

import "./index.scss";

import TicketType from "./_ticketType";

import { organization_event_ticket_types_path } from "../../helpers/paths";

class TicketTypesList extends React.Component {
  state = {
    ticketTypes: [],
    organization: {},
    organizations: [],
    nextToken: null,
    loading: true,
    alert: null,
    name: "",
    eventId: ""
  };

  async componentDidMount() {
    const uid = this.props.currentUser.uid;
    permissions.getRolesPermissions(uid).then(async () => {});
    const { organizationId, eventId } = this.props.match.params;

    var self = this;

    this.setState({ organizationId });

    var ticketTypesCollection = await this.props.firebase.ticketTypesCollection(
      organizationId,
      eventId
    );

    ticketTypesCollection.onSnapshot(function(querySnapshot) {
      var ticketTypes = [];
      querySnapshot.forEach(function(doc) {
        ticketTypes.push({ id: doc.id, ...doc.data() });
      });
      self.setState({ ticketTypes });
    });
  }

  destroy = async (orgId, eventId, id) => {
    this.props.firebase.deleteTicketType(orgId, eventId, id).then(record => {
      const ticketTypes = [
        ...this.state.ticketTypes.filter(r => {
          return r.id !== id;
        })
      ];
      this.setState({ ticketTypes });
      this.hideAlert();
    });
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  cancelDestroy() {
    this.hideAlert();
  }

  confirmDestroy = async (orgId, eventId, id) => {
    const phasesSnapshot = await this.props.firebase.db
      .collection("organizations")
      .doc(orgId)
      .collection("events")
      .doc(eventId)
      .collection("phases")
      .where("ticketTypeId", "==", id)
      .get();

    console.log(phasesSnapshot.empty)
    if (phasesSnapshot.empty) {
      this.setState({
        alert: (
          <DestroyConfirmation
            onConfirm={() => this.destroy(orgId, eventId, id)}
            onCancel={() => this.cancelDestroy()}
          />
        )
      });
    } else {
      this.setState({
        alert: (
          <DestroyConfirmation
            onConfirm={() => this.cancelDestroy()}
            title="This ticket tipe has many phases associated."
            description="It is not safe to delete it, please remove association from phases and try again."
            confirmText="Close"
            hideCancel
          />
        )
      });
    }
  };

  render() {
    const { organizationId, eventId } = this.props.match.params;
    return (
      <EventsContainer event={this.state.event}>
        {this.state.alert}

        {permissions.can("ticket_types", "create") && (
          <div className="section-header">
            <div className="text-right table-actions pull-right">
              <Link
                to={organization_event_ticket_types_path(organizationId, eventId, "/new")}
                className="btn btn-primary"
              >
                New Ticket Type
              </Link>
            </div>
          </div>
        )}
        <br />
        <Table className="responsive">
          <TableHead cols={["Ticket type"]} />
          <tbody>
            {this.state.ticketTypes.map((record) => (
              <TicketType
                key={record.id}
                record={record}
                organizationId={organizationId}
                eventId={eventId}
                confirmDestroy={this.confirmDestroy}
              />
            ))}
          </tbody>
        </Table>
      </EventsContainer>
    );
  }
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(TicketTypesList);
