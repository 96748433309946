import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import permissions from "../../../helpers/permissions";
import { NavItem, NavLink, Collapse, Nav } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import depositsIcon from "../../../images/icons/deposits.svg";
import eventsIcon from "../../../images/icons/events.svg";
import formsIcon from "../../../images/icons/forms.svg";
import homeIcon from "../../../images/icons/home.svg";
import ordersIcon from "../../../images/icons/orders.svg";
import organizationsIcon from "../../../images/icons/organizations.svg";
import usersIcon from "../../../images/icons/users.svg";

import "./index.scss";

function CollapseMenu(props) {
  useEffect(() => {
    const uid = props.uid;
    permissions.getRolesPermissions(uid).then(() => {});
  }, []);

  const activeLink = (path, option = undefined) => {
    const matchesLink = option !== "/" && path.includes(option);
    const isHome = option === "/" && path === "/";
    if (matchesLink || isHome) return "active";
  };
  const closeMenu = () => {
    props.setIsOpen(false);
  };
  return (
    <Collapse isOpen={props.isOpen} className="w-100 collapsible-menu">
      <Nav className="mr-auto w-100 d-md-none" navbar>
        <NavItem>
          <NavLink
            className={activeLink(props.location.pathname, "/")}
            tag={Link}
            to={"/"}
            onClick={closeMenu}
          >
            <span className="menu-icon-wrapper">
              <img className="icon" src={homeIcon} alt="Events" />
            </span>
            <span className="menu-label">Dashboard</span>
          </NavLink>
        </NavItem>
        {permissions.can("events", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/events")}
              tag={Link}
              to={"/events"}
              onClick={closeMenu}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={eventsIcon} alt="Events" />
              </span>
              <span>Events</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("forms", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/forms")}
              tag={Link}
              to={"/forms"}
              onClick={closeMenu}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={formsIcon} alt="Forms" />
              </span>
              <span>Forms</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("orders", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/orders")}
              tag={Link}
              to={"/orders"}
              onClick={closeMenu}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={ordersIcon} alt="Orders" />
              </span>
              <span>Orders</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("deposits", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/deposits")}
              tag={Link}
              to={"/deposits"}
              onClick={closeMenu}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={depositsIcon} alt="Deposits" />
              </span>
              <span>Deposits</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("organizations", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/organizations")}
              tag={Link}
              to={"/organizations"}
              onClick={closeMenu}
            >
              <span className="menu-icon-wrapper">
                <img
                  className="icon"
                  src={organizationsIcon}
                  alt="Organizations"
                />
              </span>
              <span>Organizations</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("users", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/users")}
              tag={Link}
              to={"/users"}
              onClick={closeMenu}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={usersIcon} alt="Users" />
              </span>
              <span>Users</span>
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </Collapse>
  );
}

export default compose(
  withRouter,
  connect(
    state => ({ uid: state.currentUser.uid }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(CollapseMenu);
