import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "../../components/PrivateRoute/index";
import Application from "./Application/";
import Login from "../../pages/login";

import Navbar from "../../components/Admin/Navbar";
import Sidebar from "../../components/Admin/Sidebar";

// firebase
import { withFirebase } from "../../components/Firebase";
import { compose } from "recompose";
// redux
import { userSignedIn, userSignedOut } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import permissions from "../../helpers/permissions";

import ScrollToTop from "../../components/ScrollToTop";

import "./admin.scss";

console.log(`
  ============================================
  Handypass Admin Panel
  Environment: ${process.env.REACT_APP_ENV}
  ============================================
`);

const App = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    props.firebase.auth.onAuthStateChanged(async function(user) {
      if (user) {
        const { displayName, email, emailVerified, isAnonymous, uid } = user;
        await permissions.getRolesPermissions(uid);
        props.userSignedIn(displayName, email, emailVerified, isAnonymous, uid);

        setIsLoggedIn(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsLoggedIn(false);
        props.userSignedOut();
        permissions.clean();
      }
    });
  }, []);

  return (
    !isLoading && (
      <Router>
        <>
          <ScrollToTop />
          {isLoggedIn && (
            <>
              <Navbar />
            </>
          )}
          <div className="content">
            {isLoggedIn && <Sidebar />}
            <div className="main-content">
              <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/" component={Application} />
              </Switch>
            </div>
          </div>
        </>
      </Router>
    )
  );
};

export default compose(
  withFirebase,
  connect(
    state => ({ currentUser: state.currentUser }),
    dispatch => bindActionCreators({ userSignedIn, userSignedOut }, dispatch)
  )
)(App);
