import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

export default function DestroyConfirmation(props) {
  const title = props.title || "Are you sure?";
  const description = props.description || "This action cannot be undone";
  return (
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={title}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText={props.confirmText ? props.confirmText : "Confirm"}
      cancelBtnText="Cancel"
      showCancel={!props.hideCancel}
    >
      {description}
    </ReactBSAlert>
  );
}
