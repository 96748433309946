import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import permissions from "../../../helpers/permissions";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import depositsIcon from "../../../images/icons/deposits.svg";
import eventsIcon from "../../../images/icons/events.svg";
import formsIcon from "../../../images/icons/forms.svg";
import homeIcon from "../../../images/icons/home.svg";
import ordersIcon from "../../../images/icons/orders.svg";
import organizationsIcon from "../../../images/icons/organizations.svg";
import usersIcon from "../../../images/icons/users.svg";
import monitor from "../../../images/icons/monitor.svg";

function Sidebar(props) {
  useEffect(() => {
    const uid = props.uid;
    permissions.getRolesPermissions(uid).then(() => {});
  }, []);

  const activeLink = (path, option = undefined) => {
    const matchesLink = option !== "/" && path.includes(option);
    const isHome = option === "/" && path === "/";
    if (matchesLink || isHome) return "active";
  };

  return (
    <aside
      className="sidebar"
      data-ps-id="65370a29-63fc-71f6-51db-72ac65381e8e"
    >
      <Nav vertical>
        <NavItem>
            <NavLink
            className={activeLink(props.location.pathname, "/")}
            tag={Link}
            to={"/"}
          >
            <span className="menu-icon-wrapper">
              <img className="icon" src={homeIcon} alt="Events" />
            </span>
            <span className="menu-label">Dashboard</span>
          </NavLink>
        </NavItem>
        {permissions.can("events", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/events")}
              tag={Link}
              to={"/events"}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={eventsIcon} alt="Events" />
              </span>
              <span className="menu-label">Events</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("forms", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/forms")}
              tag={Link}
              to={"/forms"}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={formsIcon} alt="Forms" />
              </span>
              <span className="menu-label">Forms</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("orders", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/orders")}
              tag={Link}
              to={"/orders"}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={ordersIcon} alt="Orders" />
              </span>
              <span className="menu-label">Orders</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("deposits", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/deposits")}
              tag={Link}
              to={"/deposits"}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={depositsIcon} alt="Deposits" />
              </span>
              <span className="menu-label">Deposits</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("organizations", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/organizations")}
              tag={Link}
              to={"/organizations"}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={organizationsIcon} alt="Organizations" />
              </span>
              <span className="menu-label">Organizations</span>
            </NavLink>
          </NavItem>
        )}
        {permissions.can("users", "read") && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/users")}
              tag={Link}
              to={"/users"}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={usersIcon} alt="Users" />
              </span>
              <span className="menu-label">Users</span>
            </NavLink>
          </NavItem>
        )}

        {permissions.adminPresent() && (
          <NavItem>
            <NavLink
              className={activeLink(props.location.pathname, "/slider_items")}
              tag={Link}
              to={"/slider_items"}
            >
              <span className="menu-icon-wrapper">
                <img className="icon" src={monitor} alt="Users" />
              </span>
              <span className="menu-label">Slider items</span>
            </NavLink>
          </NavItem>
        )}

      </Nav>
    </aside>
  );
}

export default compose(
  withRouter,
  connect(
    state => ({ uid: state.currentUser.uid }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(Sidebar);
