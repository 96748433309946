import React from "react";
import { Link } from "react-router-dom";

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import dotsIcon from "../../images/icons/dots.svg";
import permissions from "../../helpers/permissions";

export default function CourtesyTicketType(props) {
  const { organizationId } = props;
  const {
    id,
    name,
    eventId
  } = props.record;

  return (
    <tr key={id} className="courtesy-ticket-type-row">
      <td>{name}</td>
      <td className="text-right">
        <UncontrolledDropdown direction="left" className="row-options-trigger">
          <DropdownToggle tag="span">
            <img src={dotsIcon} alt="Options" />
          </DropdownToggle>
          <DropdownMenu>

            {permissions.can("ticket_types", "edit") && (
              <DropdownItem
                tag={Link}
                to={`/${organizationId}/events/${eventId}/courtesy_ticket_types/${
                  id
                  }/edit`}
              >
                Edit
              </DropdownItem>
            )}

            <DropdownItem divider />

            {permissions.can("ticket_types", "delete") && (
              <DropdownItem
                onClick={() =>
                  this.confirmDestroy(
                    organizationId,
                    eventId,
                    id
                  )
                }
                className="text-danger"
              >
                Destroy
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>

      </td>
    </tr>
  );
}

