import React from "react";
import { NavLink, NavItem as NavReacstrap } from "reactstrap";

export function NavItem({ onChangeTab, title, active, disabled = false }) {
  return (
    <NavReacstrap>
      <NavLink
        onClick={onChangeTab}
        disabled={disabled}
        active={active === title}
      >
        {title}
      </NavLink>
    </NavReacstrap>
  );
}

// className={classnames({ active: activeTab === '1' })}
