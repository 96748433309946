import React from "react";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { withFirebase } from "../../Firebase";

import "./index.scss";

// custom input
const LegendLabel = ({ legendSegments }) => {
  return (
    <React.Fragment>
      <span className="uploader-content">
        {legendSegments.map((row, i) => (
          <React.Fragment key={i}>
            <span>{row}</span>
            <br />
          </React.Fragment>
        ))}
        <br />
        <span className="btn" size="sm">
          Select file
        </span>
      </span>
    </React.Fragment>
  );
};
// end custom input

function DropzoneUploader(props) {
  let {
    path,
    onChange,
    firebase,
    devMode,
    theme,
    legend,
    image,
    className,
    previewImage
  } = props;

  theme = theme || {};
  const legendSegments = legend ? legend.split("|") : [];

  path = devMode ? "dev/null/" : path;

  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = async ({ meta, file, remove }, status) => {
    if (status === "headers_received") {
      await firebase.storage.ref(`${path}${meta.name}`).put(file);
      const url = await firebase.storage
        .ref(path)
        .child(meta.name)
        .getDownloadURL();
      onChange(url);
      remove();
    } else if (status === "aborted") {
      console.log(`${meta.name}, upload failed...`);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        ...theme
      }}
      className={`dropzone-uploader-wrapper ${
        className
      } uploader-preview ${
        image ? "has-content" : "empty"
      }`}
    >
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        accept="image/*"
        previewImage={previewImage}
        inputContent={props => <LegendLabel legendSegments={legendSegments} />}
      />
    </div>
  );
}

export default withFirebase(DropzoneUploader);
