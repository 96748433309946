import React from "react";
import { Switch, Route } from "react-router-dom";

import Deposits from "../../../../pages/deposits/Deposits";
import NewDeposit from "../../../../pages/deposits/NewDeposit";

export default function Application() {
  return (
    <Switch>
      <Route exact path="/deposits" component={Deposits} />
      <Route exact path="/deposits/new" component={NewDeposit} />
    </Switch>
  );
}
