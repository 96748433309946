import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { withRouter, Link } from "react-router-dom";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import { events_path } from "../../helpers/paths";

import "./styles.scss";

import { connect } from "react-redux";
import permissions from "../../helpers/permissions";
import { compose } from "recompose";
import EventSidebar from "../../pages/events/_sidebar";
import EventDropdownMenu from "../../pages/events/_dropdownMenu";
import { withFirebase } from "../../components/Firebase";

import { getEventActiveSection } from "../../helpers/paths";

class ContainerSettings extends Component {
  state = {
    currentSection: ""
  };

  async componentDidMount() {
    const { organizationId, eventId } = this.props.match.params;
    const uid = this.props.currentUser.uid;
    permissions.getRolesPermissions(uid).then(async () => {});
    if (organizationId && eventId) {
      const eventDoc = await this.props.firebase.getEvent(
        organizationId,
        eventId
      );

      this.setState({
        event: { id: eventDoc.id, ...eventDoc.data() },
        currentSection: getEventActiveSection(this.props.match.path)
      });
    }
  }

  render() {
    const { name } = this.state.event || { name: "No name" };
    return (
      <>
        <div className="section-header">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={events_path()}>Events</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>{name}</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <EventDropdownMenu {...this.state} />

        <Row>
          <EventSidebar {...this.state} event={this.state.event} />
          <Col>{this.props.children}</Col>
        </Row>
      </>
    );
  }
}

export default compose(
  withRouter,
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(ContainerSettings);
