import React from "react";

import {
  Table,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import dotsIcon from "../../images/icons/dots.svg";

import { Link } from "react-router-dom";

import { centsToDecimal } from "../../helpers/currency";

import Image from "../../components/Image";

import { withFirebase } from "../../components/Firebase";

import EventsContainer from "../../components/EventsContainer";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";

import TableHead from "../../components/Admin/TableHead";

import { edit_organization_event_product_path } from "../../helpers/paths";

import "./index.scss";
class ProductsList extends React.Component {
  state = {
    products: [],
    organization: {},
    nextToken: null,
    loading: true,
    alert: null
  };

  async componentDidMount() {
    try {
      const { organizationId, eventId } = this.props.match.params;

      var self = this;

      var productsCollection = await this.props.firebase.productsCollection(
        organizationId,
        eventId
      );
      var event = await this.props.firebase.getEvent(organizationId, eventId);
      this.setState({
        event: event.data(),
        organizationId,
        eventId
      });

      productsCollection.onSnapshot(function (querySnapshot) {
        if (querySnapshot.docChanges().some(e => e.type !== "modified")) {
          var products = [];
          querySnapshot.forEach(function (doc) {
            products.push(self.normalizeProductDoc(doc));
          });
          self.setState({ products });
        }
      });
    } catch (error) { }
  }

  normalizeEventDoc = doc => {
    try {
      const eventData = doc.data();
      let normalizedDoc = {
        id: doc.id,
        name: eventData.name,
        description: eventData.description
      };
      console.log(normalizedDoc);
      return normalizedDoc;
    } catch (error) { }
  };

  normalizeProductDoc = doc => {
    const docData = doc.data();
    let normalizedDoc = {
      id: doc.id,
      ...docData,
      feeDecimal: centsToDecimal(docData.fee),
      priceDecimal: centsToDecimal(docData.price),
      boxOfficePriceDecimal: centsToDecimal(docData.boxOfficePrice),
      eventId: this.props.match.params.eventId,
      ticketTypePhaseStatus: docData.ticketTypePhaseStatus || "ACTIVE"
    };

    return normalizedDoc;
  };
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "batchSize")
      this.setState({ [e.target.name]: parseInt(e.target.value) });
  };

  destroy = async (orgId, eventId, id) => {
    try {
      await this.props.firebase
        .deleteProduct(orgId, eventId, id)
        .then(record => {
          const products = [
            ...this.state.products.filter(r => {
              return r.id !== id;
            })
          ];
          this.setState({ products });
          this.hideAlert();
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  cancelDestroy() {
    this.hideAlert();
  }

  confirmDestroy = (organizationId, eventId, productId) => {
    this.setState({
      alert: (
        <DestroyConfirmation
          onConfirm={() => this.destroy(organizationId, eventId, productId)}
          onCancel={() => this.cancelDestroy()}
        />
      )
    });
  };

  onDragEnd = async result => {
    const { eventId, organizationId } = this.props.match.params;
    const { draggableId, destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newSortOrder = Array.from(this.state.products);

    newSortOrder.splice(source.index, 1);
    newSortOrder.splice(destination.index, 0, {
      ...this.state.products[source.index],
      id: draggableId
    });

    newSortOrder.map(async (product, index) => {
      await this.props.firebase.db
        .collection("organizations")
        .doc(organizationId)
        .collection("events")
        .doc(eventId)
        .collection("products")
        .doc(product.id)
        .update({ sortOrder: index });
    });

    this.setState({ products: newSortOrder });
  };

  bgStyle = isDragging => {
    return {
      background: isDragging ? "lightgreen" : "white"
    }
  }

  render() {
    const { organizationId, eventId } = this.props.match.params;
    return (
      <EventsContainer>
        {this.state.alert}

        <div className="section-header">
          <div className="text-right table-actions pull-right">
            <Link
              to={`/${organizationId}/events/${eventId}/products/new`}
              className="btn btn-primary"
            >
              Create Product
            </Link>
          </div>
        </div>

        <br />

        <Table className="responsive" role="table">

          <TableHead cols={["Image", "Product name", "Availability", ""]} />

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="productsDroppable">
              {provided => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {this.state.products.map((record, i) => (
                    <Draggable
                      key={i}
                      style={{ display: "table", width: "100%" }}
                      draggableId={record.id}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <tr
                          className="product-row"
                          key={record.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <td style={this.bgStyle(snapshot.isDragging)}>
                            <Image
                              imgUrl={record.file}
                              theme={{ width: "50px", height: "50px" }}
                            />
                          </td>
                          <td style={this.bgStyle(snapshot.isDragging)}>
                            {record.name}
                          </td>
                          <td style={this.bgStyle(snapshot.isDragging)}>

                            {record.quantity - record.soldCount} available -{" "}

                            {record.quantity - record.soldCount}/{record.quantity}
                          </td>
                          <td style={this.bgStyle(snapshot.isDragging)} className="text-right full-width">

                            <UncontrolledDropdown direction="left" className="row-options-trigger">
                              <DropdownToggle tag="span">
                                <img src={dotsIcon} alt="Options" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  tag={Link}
                                  to={edit_organization_event_product_path(organizationId, eventId, record.id)}
                                >
                                  Edit
                                </DropdownItem>

                                <DropdownItem divider />

                                <DropdownItem
                                  className="text-danger"                                
                                  onClick={() =>
                                    this.confirmDestroy(
                                      organizationId,
                                      eventId,
                                      record.id
                                    )
                                  }
                                >
                                  Destroy
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>

                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>

      </EventsContainer>
    );
  }
}

export default withFirebase(ProductsList);
