import { userConstants } from "../constants";

export function currentUser(state = { isLoggedIn: false, roles: [], ready: false }, action) {
  switch (action.type) {
    case userConstants.LOAD_USER_SUCCESS:
      return {
        ...state,
        ...{
          loading: false,
          id: action.user.attributes["sub"],
          username: action.user.username,
          role: action.user.attributes["custom:role"],
          email_verified: action.user.attributes["email_verified"],
          email: action.user.attributes["email"],
          userPoolId: action.user.pool.userPoolId
        }
      };
    case userConstants.USER_SIGNED_IN:
      return {
        ...state,
        ...{
          isLoggedIn: true,
          displayName: action.displayName,
          email: action.email,
          emailVerified: action.emailVerified,
          isAnonymous: action.isAnonymous,
          uid: action.uid,
          roles: action.roles,
          ready: true
        }
      };
    case userConstants.USER_SIGNED_OUT:
      return {
        ...state,
        ...{
          isLoggedIn: false,
          displayName: "",
          email: "",
          emailVerified: "",
          isAnonymous: "",
          uid: "",
          roles: "",
          ready: true
        }
      };
    default:
      return state;
  }
}
