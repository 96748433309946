import React from "react";
import { Input, Button } from "reactstrap";

export default function SelectFromCollection({
  collection,
  name,
  value,
  placeholder,
  onChange,
  includeBlank,
  labelField,
  optionValue,
  optionLabel,
  id,
  invalid
}) {
  return (
    <Input
      type="select"
      name={name}
      value={value}
      id={id || `field-$(name)`}
      onChange={onChange}
      placeholder={placeholder}
      invalid={invalid}
    >
      {includeBlank &&
        <option value="">{includeBlank || ""}</option>
      }

      {collection && collection.map((item, i) => (
        <option
          key={item[optionValue]}
          value={item[optionValue]}
          defaultValue={item[optionValue] == value}
        >
          {item[optionLabel]}
        </option>
      ))}
    </Input>
  );
}
