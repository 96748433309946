import axios from "axios";
const url =
  process.env.REACT_APP_ENV === "production"
    ? "https://us-central1-handypass-production.cloudfunctions.net/app"
    : "https://us-central1-handypass-dev.cloudfunctions.net/app";

// const url = "http://localhost:5000/handypass-production/us-central1/app";

const pgEventsService = config => {
  const service = axios.create({
    baseURL: `${url}/replica/events`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": "d0358c74ffb389ad56235eda29aa8a378ba7a2a2909a234d8a"
    }
  });

  return service(config);
};
const chartsData = ({ filters = [] }) =>
  pgEventsService({
    method: "GET",
    url: `/sales?${filters ? `&${filters.join("&")}` : ""}`
  }).then(response => response.data);

export default {
  chartsData
};
