import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default function XYChart(props) {
  const { data, labels, events } = props;
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.colors.list = [
      am4core.color("#3f51b5"),
      am4core.color("#2196f3"),
      am4core.color("#9c27b0"),
      am4core.color("#673ab7")
    ];
    chart.paddingRight = 20;
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.contentAlign = "right";
    chart.legend.useDefaultMarker = true;
    chart.cursor = new am4charts.XYCursor();
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 1;
    marker.scale = 0.7;

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // dateAxis.renderer.grid.template.location = 0;
    // dateAxis.renderer.minGridDistance = 30;
    function createSeries(field, name, data) {
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "createdAt";
      series.name = name;
      series.tooltipText = "{dateX}: [b]$ {valueY}[/]";
      series.strokeWidth = 2;
      series.minBulletDistance = 15;
      series.data = data;

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color("#fff");
      bullet.circle.strokeWidth = 2;

      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.parent = chart.leftAxesContainer;
      chart.scrollbarY.toBack();

      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX.series.push(series);
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      return series;
    }
    if (events) {
      labels.forEach(label => {
        createSeries("subtotal", label.text, data[label.type]);
      });
    } else {
      labels.forEach(label => {
        createSeries("subtotal", label.event, data[label.id]);
      });
    }
  }, [data]);
  return <div id="chartdiv" style={{ height: "500px", width: "100%" }} />;
}
