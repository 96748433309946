import React, { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../components/Firebase/config";

import {
  Form,
  FormGroup,
  Input,
  Col,
  Row,
  Table,
  Breadcrumb,
  BreadcrumbItem,
  Button
} from "reactstrap";

import ordersService from "../../services/orders.service";
import organizationsService from "../../services/organizations.service";
import { withFirebase } from "../../components/Firebase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TableHead from "../../components/Admin/TableHead";
import Pagination from "react-js-pagination";
import "../../css/loader-spinner/react-spinner-loader.css";

import "./styles.scss";

import TableLoadingRow from "../../components/Admin/TableLoadingRow";
import Order from "./_order";
import { isMobile } from "react-device-detect";
const Sugar = require("sugar");
const fileDownload = require("js-file-download");

const dateRanges = {
  today: {
    startsAt: Sugar.Date.beginningOfDay(new Date()),
    endsAt: Sugar.Date.endOfDay(new Date())
  },
  week: {
    startsAt: Sugar.Date.beginningOfWeek(new Date()),
    endsAt: Sugar.Date.endOfWeek(new Date())
  },
  month: {
    startsAt: Sugar.Date.beginningOfMonth(new Date()),
    endsAt: Sugar.Date.endOfMonth(new Date())
  }
};
// const url = "http://localhost:5000/handypass-dev/us-central1/app";
const url = `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/app`;
const API = {
  getEvents: payload => {
    return axios.post(`${url}/filtersOrders/events`, payload);
  },
  getOrganizations: payload => {
    return axios.post(`${url}/filtersOrders/organization`, payload);
  },
  getUsers: payload => {
    return axios.post(`${url}/filtersOrders/users`, payload);
  }
};

const Orders = props => {
  // console.log(dateRanges);
  const [startAt, setStartAt] = useState(dateRanges.week.startsAt);
  const [endAt, setEndAt] = useState(dateRanges.week.endsAt);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [activeFilter, setActiveFilter] = useState({});
  const [myOrgIds, setMyOrgIds] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);

  const [currentDateFilter, setCurrentDateFilter] = useState("all");

  const [typingTimeout, setTypingTimeout] = useState(false);
  const [currentFilters, setCurrenFilters] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [printButtonActive, setPrintButtonActive] = useState(false);
  const [viewAllFilters, setViewAllFilters] = useState(true);

  // pagination:
  const [queryLimit, setQueryLimit] = useState(50);
  const [pageSize, setPageSize] = useState([0]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloading, setDownloading] = useState(false);
  const [admin, setIsAdmin] = useState(false);
  const [loadingOrganizations, setLoadingOrganizations] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [orgIdSelected, setOrgIdSelected] = useState("");
  const [eventId, setEventId] = useState("");
  const [showFilters, setShowFilters] = useState(!isMobile);

  const loadEvents = async orgId => {
    try {
      setLoadingEvents(true);
      const userDoc = await props.firebase.getUserData();
      const response = await API.getEvents({ uid: userDoc.id, orgId: orgId });
      setEvents(response.data.events);
      setLoadingEvents(false);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAdministrativeUsers = async (orgId, eventId) => {
    try {
      setLoadingUsers(true);
      const response = await API.getUsers({ orgId, eventId });
      setUsers(response.data.users);
      setLoadingUsers(false);
    } catch (error) {
      const response = await API.getUsers({ orgId, eventId });
      setUsers(response.data.users);
      setLoadingUsers(false);
    }
  };

  const loadOrganizations = async () => {
    try {
      setLoadingOrganizations(true);
      const userDoc = await props.firebase.getUserData();
      const { isAdmin } = userDoc.data();
      setIsAdmin(isAdmin);
      const response = await API.getOrganizations({ uid: userDoc.id });
      setOrganizations(response.data.organizations);
      setLoadingOrganizations(false);
      if (!isAdmin) {
        const Ids = response.data.organizations.map(item => item.id);
        setActiveFilter({
          ...activeFilter,
          organizationId_in: Ids
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      await Promise.all([loadOrganizations(), loadEvents()]);
      props.firebase.getUserData().then(user => {
        const { isAdmin } = user.data();
        if (isAdmin) {
          loadOrders(currentPage, []);
        }
      });
    };
    init();
  }, []);

  useEffect(() => {
    const hasFilters = Object.keys(activeFilter).length > 0;
    if (hasFilters) {
      let filter = [];
      // console.log(activeFilter, "Filtros activos");
      if (
        !activeFilter.organizationId &&
        !activeFilter.eventId &&
        activeFilter.organizationId_in
      ) {
        const organizationsId = JSON.stringify(activeFilter.organizationId_in);
        filter.push(
          `q[organizationId_in]=${organizationsId.replace(/[\[\]"]+/g, "")}`
        );
      }
      if (activeFilter.organizationId && !activeFilter.eventId) {
        // console.log("ENTRO AQUI");
        // loadAdministrativeUsers(activeFilter.organizationId);
        filter.push(`q[organizationId_eq]=${activeFilter.organizationId}`);
      }
      

      if (activeFilter.orderType) {
        filter.push(`q[orderType_eq]=${activeFilter.orderType}`);
      }

      if (activeFilter.eventId) {
        setLoadingOrganizations(true);
        filter.push(`q[eventId_eq]=${activeFilter.eventId}`);
      }

      if (activeFilter.userId) {
        setSelectedUser(users.find(u => u.id === activeFilter.userId));
        filter.push(`q[userId_eq]=${activeFilter.userId}`);
      }

      if (activeFilter.status) {
        filter.push(`q[orderStatus_eq]=${activeFilter.status}`);
      }

      if (activeFilter.paymentProvider) {
        filter.push(`q[paymentProvider_eq]=${activeFilter.paymentProvider}`);
      }

      if (activeFilter.findByTerm) {
        filter.push(`q[str_cont]=${activeFilter.findByTerm}`);
      }

      if (activeFilter.isValid_eq) {
        filter.push(`q[isValid_eq]=${activeFilter.isValid_eq}`);
      }

      if (activeFilter.createdAt_between) {
        const dates = activeFilter.createdAt_between;
        const start = Date.create(dates[0], { setUTC: true });
        const finish = Date.create(dates[1], { setUTC: true });
        filter.push(
          `q[createdAt_between]=${Sugar.Date.format(
            start,
            "%Y-%m-%d %H:%M"
          )}..${Sugar.Date.format(finish, "%Y-%m-%d %H:%M")}`
        );
      }
      setCurrenFilters(filter);
      props.firebase.getUserData().then(user => {
        const { isAdmin } = user.data();
        if (isAdmin) {
          loadOrders(currentPage, filter);
        } else if (activeFilter.organizationId_in) {
          loadOrders(currentPage, filter);
        }
      });
    }
  }, [activeFilter]);

  const loadOrders = async (page, filters) => {
    setLoading(true);
    // console.log(filters, "filters in load orders");
    let offset = page === 1 ? 0 : (page - 1) * queryLimit;
    try {
      const { orders, recordsCount, pageSize } = await ordersService.all({
        limit: queryLimit,
        offset: offset,
        filters
      });
      setRecordsCount(recordsCount);
      setPageSize(pageSize);
      setOrders(orders);
      setLoading(false);
    } catch (error) {
      const { orders, recordsCount, pageSize } = await ordersService.all({
        limit: queryLimit,
        offset: offset,
        filters
      });
      setRecordsCount(recordsCount);
      setPageSize(pageSize);
      setOrders(orders);
      setLoading(false);
    }
  };

  function changeCurrentDateFilter(dateFilter) {
    // console.log(dateFilter);
    setCurrentDateFilter(dateFilter);
    setCurrenFilters([]);
    if (["today", "week", "month"].includes(dateFilter)) {
      setStartAt(dateRanges[dateFilter].startsAt);
      setEndAt(dateRanges[dateFilter].endsAt);
      setActiveFilter({
        ...activeFilter,
        createdAt_between: [
          dateRanges[dateFilter].startsAt,
          dateRanges[dateFilter].endsAt
        ]
      });
    } else if (dateFilter === "all") {
      setActiveFilter({
        ...activeFilter,
        createdAt_between: false
      });
    }
  }

  async function handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    setCurrentPage(pageNumber);
    setLoading(true);
    await loadOrders(pageNumber, currentFilters);
  }

  function searchTermChange(value) {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function() {
        setActiveFilter({
          ...activeFilter,
          findByTerm: value
        });
      }, 1000)
    );
  }

  async function downloadReport() {
    setDownloading(true);
    const [ordersCSV, accessesCSV] = await Promise.all([
      ordersService.allToCSV({ filters: currentFilters }),
      ordersService.accessesToCSV({ filters: currentFilters })
    ]);
    setDownloading(false);
  }

  const goToOrderDetails = ({
    id,
    organizationId,
    eventId,
    eventName
  }) => () => {
    props.history.push(`/orders/${id}`, {
      organizationId,
      eventId,
      eventName
    });
  };

  const sendNotification = order => {
    console.log("sup?");
    const { organizationId: orgId, eventId, id: orderId } = order;
    setSendingEmail(true);
    // ordersService.sendNotification(orgId, eventId, orderId).then(res => {
    //   setSendingEmail(false);
    //   window.alert("Notification sent!");
    // });

    axios
      .post(
        `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/app/orders/send_notification`,
        {
          orgId,
          eventId,
          orderId
        }
      )
      .then(res => {
        setSendingEmail(false);
        window.alert("Correo enviado correctamente");
      });
  };

  function handleStartAtChange(date) {
    setStartAt(date);
    setActiveFilter({
      ...activeFilter,
      createdAt_between: [date, endAt]
    });
  }
  function handleEndAtChange(date) {
    setEndAt(date);
    setActiveFilter({
      ...activeFilter,
      createdAt_between: [startAt, date]
    });
  }

  function handleSetFilter({ target }) {
    const { value, name } = target;
    setActiveFilter({
      ...activeFilter,
      [name]: value
    });

    if (name === "eventId" && !value) {
      setLoadingOrganizations(false);
    }
    if (name === "eventId") {
      setEventId(value);
    }
    if (name === "organizationId" && !value) {
      setLoadingUsers(true);
    }
    if (name === "organizationId") {
      loadAdministrativeUsers(value);
      setOrgIdSelected(value);
    }
  }
  return (
    <React.Fragment>
      <div className="section-header orders-page">
        <div className="text-right table-actions pull-right top-search">
          <Input
            type="text"
            placeholder="search"
            id="search-term"
            onChange={e => searchTermChange(e.target.value)}
          />

          <Button
            color="primary"
            disabled={!printButtonActive}
            onClick={downloadReport}
          >
            {downloading ? "Downloading..." : "Export CSV"}
          </Button>
        </div>

        <Breadcrumb>
          <BreadcrumbItem active>Orders</BreadcrumbItem>
          <li className="date-filters pull-left">
            <button
              onClick={() => changeCurrentDateFilter("today")}
              className={`btn btn-filter ${
                currentDateFilter === "today" ? "active-filter" : ""
              }`}
            >
              Today
            </button>
            <button
              onClick={() => changeCurrentDateFilter("week")}
              className={`btn btn-filter ${
                currentDateFilter === "week" ? "active-filter" : ""
              }`}
            >
              Week
            </button>
            <button
              onClick={() => changeCurrentDateFilter("month")}
              className={`btn btn-filter ${
                currentDateFilter === "month" ? "active-filter" : ""
              }`}
            >
              Month
            </button>
            <button
              onClick={() => changeCurrentDateFilter("all")}
              className={`btn btn-filter ${
                currentDateFilter === "all" ? "active-filter" : ""
              }`}
            >
              All
            </button>
            <button
              onClick={() => changeCurrentDateFilter("range")}
              className={`btn btn-filter ${
                currentDateFilter === "range" ? "active-filter" : ""
              }`}
            >
              Range
            </button>
          </li>
          <li
            className={`orders-date-range ${
              currentDateFilter === "range" ? "" : "hidden"
            }`}
          >
            <DatePicker
              selected={startAt}
              onChange={handleStartAtChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd H:mm"
              popperPlacement="top-start"
              timeCaption="Time"
              showMonthDropdown
            />
            <DatePicker
              selected={endAt}
              onChange={handleEndAtChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd H:mm"
              timeCaption="Time"
              popperPlacement="top-end"
              showMonthDropdown
            />
          </li>
        </Breadcrumb>

        <div className="text-right table-actions pull-right down-search">
          <Form inline>
            <FormGroup style={{ paddingTop: "19px" }}>
              <Input
                type="text"
                placeholder="search"
                id="search-term"
                onChange={e => searchTermChange(e.target.value)}
              />
            </FormGroup>
          </Form>
        </div>
      </div>

      <Form>
        <br />
        <div className="row">
          {isMobile && (
            <FormGroup className="col-sm-12 col-md-2 form-group">
              <Button
                className="btn-show-filters"
                block
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                Show {showFilters ? "Less" : "More"}
              </Button>
            </FormGroup>
          )}
          {showFilters && (
            <React.Fragment>
              <Col sm="12" md="2">
                <FormGroup>
                  <Input
                    type="select"
                    id="organization-selector"
                    name="organizationId"
                    onChange={handleSetFilter}
                    value={orgIdSelected.length === 0 ? "" : orgIdSelected}
                    disabled={loadingOrganizations}
                  >
                    <option value="">Organization</option>
                    {organizations.map(organization => (
                      <option key={organization.id} value={organization.id}>
                        {organization.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12" md="2">
                <FormGroup>
                  <Input
                    type="select"
                    id="event-selector"
                    name="eventId"
                    onChange={handleSetFilter}
                    disabled={loadingEvents}
                    value={eventId}
                  >
                    <option value="">Event</option>
                    {events.map(event => {
                      if (orgIdSelected.length === 0) {
                        return (
                          <option key={event.id} value={event.id}>
                            {event.name}
                          </option>
                        );
                      }
                      if (event.organizationId == orgIdSelected) {
                        return (
                          <option key={event.id} value={event.id}>
                            {event.name}
                          </option>
                        );
                      }
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12" md="1">
                <FormGroup>
                  <Input
                    type="select"
                    id="user-selector"
                    name="userId"
                    onChange={handleSetFilter}
                    disabled={loadingUsers}
                    value={activeFilter.userId ? activeFilter.userId : ""}
                  >
                    <option value="">User</option>
                    {users.map(user => {
                      if (eventId.length === 0)
                        return (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        );
                      if (
                        user.eventIds.includes(eventId) ||
                        user.organizationAdmin.includes(orgIdSelected)
                      )
                        return (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12" md="1">
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="event-selector"
                    onChange={e =>
                      setActiveFilter({
                        ...activeFilter,
                        orderType: e && e.target.value
                      })
                    }
                  >
                    <option value="">Order type</option>
                    <option value="ONLINE">ONLINE</option>
                    <option value="BOXOFFICE">BOXOFFICE</option>
                    <option value="BATCH">BATCH</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12" md="2">
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="payment-type-selector"
                    onChange={e =>
                      setActiveFilter({
                        ...activeFilter,
                        paymentProvider: e && e.target.value
                      })
                    }
                  >
                    <option value="">Payment type</option>
                    <option value="batch">BATCH</option>
                    <option value="courtesy">COURTESY</option>
                    <option value="conekta">CONEKTA</option>
                    <option value="paypal plus">PAYPAL PLUS</option>
                    <option value="BOXOFFICE">BOXOFFICE</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col sm="12" md="2">
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="event-selector"
                    onChange={e => {
                      setPrintButtonActive(e && e.target.value);
                      setActiveFilter({
                        ...activeFilter,
                        status: e && e.target.value
                      });
                    }}
                  >
                    <option value="">Status</option>
                    <option value="CANCELLED">Canceled</option>
                    <option value="EXPIRED">Expired</option>
                    <option value="PAID">Paid</option>
                    <option value="PENDING">Pending</option>
                    <option value="REFUND">Refund</option>
                    <option value="REJECTED">Rejected</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col sm="12" md="2">
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="event-selector"
                    onChange={e => {
                      setPrintButtonActive(e && e.target.value);
                      setActiveFilter({
                        ...activeFilter,
                        isValid_eq: e && e.target.value
                      });
                    }}
                  >
                    <option value="">Validation status</option>
                    <option value="true">Valido</option>
                    <option value="false">Invalido</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col sm="12" md="2">
                <FormGroup>
                  <Button
                    color="link"
                    onClick={() => {
                      setOrgIdSelected("");
                      setEventId("");
                      setLoadingOrganizations(false);
                      setActiveFilter({});
                    }}
                  >
                    ... borrar Filtros
                  </Button>
                  {isMobile && (
                    <Button
                      color="primary"
                      className="col-6"
                      disabled={!printButtonActive}
                      onClick={downloadReport}
                    >
                      {downloading ? "Downloading..." : "Export CSV"}
                    </Button>
                  )}
                </FormGroup>
              </Col>
            </React.Fragment>
          )}
        </div>
      </Form>

      <Table className="responsive">
        <TableHead
          cols={[
            "Date",
            "Order Id",
            "Event",
            "Status",
            "Client",
            "Qty",
            "Subtotal",
            "Commission",
            "Total",
            "Validation",
            ""
          ]}
        />
        <tbody>
          {loading ? (
            <TableLoadingRow colspan="10" />
          ) : (
            orders.map(record => (
              <Order
                key={record.id}
                record={record}
                onClick={goToOrderDetails(record)}
                sendNotification={() => sendNotification(record)}
                sendingEmail={sendingEmail}
              />
            ))
          )}
        </tbody>
      </Table>
      <div>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={queryLimit}
          totalItemsCount={recordsCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
        {pageSize > 0 && (
          <span>
            Listing {pageSize} of {recordsCount} records found.
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

// export default withFirebase(withAuth(Orders));
export default withFirebase(Orders);
