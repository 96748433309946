import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import { compose } from "recompose";
import moment from "moment";
import "moment/locale/es";
import { centsToDecimal } from "../../helpers/currency";
import { environment } from "../../components/Firebase/config";
import { asyncForEach } from "../../helpers/utils";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Row,
  Col,
  Button,
  Nav,
  TabPane,
  TabContent,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup
} from "reactstrap";

import {
  Card,
  Item,
  NavItem,
  Event,
  Product,
  Courtesy,
  ListProducts
} from "../../components/orderDetails";
import "./styles.scss";
class ordersDetails extends React.Component {
  state = {
    tickets: [],
    ticket: [],
    productslist: [],
    products: [],
    courtesies: [],
    orders: [],
    coupons: [],
    user: {},
    email: "",
    firstName: "",
    lastName: "",
    orderStatus: "",
    changeOrder: false,
    couponsExist: false,
    nameEvents: "",
    userExist: false,
    isReady: false,
    LogRocketSessionUrl: "",
    activeTab: "Details",
    editEnabled: false,
    orderEditable: false,
    alert: null
  };
  generateList = async elem => {
    return new Promise(async (resolve, reject) => {
      const data = elem.docs.map(record => ({
        ...record.data(),
        id: record.id
      }));
      resolve(data);
    });
  };

  changeOrderStatus = async () => {
    const status = this.state.orderStatus;
    const statusnow = this.state.orders.orderStatus;
    const paymentId = this.state.orders.paymentId;
    const { organizationId, eventId } = this.state;
    const { orderId } = this.props.match.params;
    this.changeSalesProducts(status, statusnow);
    this.changeSalesPhases(status, statusnow);
    const data = {
      orderStatus: status
    };
    const dataPayment = {
      status: status
    };
    await this.props.firebase.updateStatusOrder(
      organizationId,
      eventId,
      orderId,
      data
    );
    await this.props.firebase.updatePayments(
      organizationId,
      eventId,
      orderId,
      paymentId,
      dataPayment
    );

    this.toggleEdit();
  };
  changeSalesPhases = async (status, statusnow) => {
    const { organizationId, eventId } = this.state;
    const orderType = this.state.orders.orderType;
    const reportSalesDate = moment(
      this.state.orders.createdAt.seconds * 1000
    ).format("YYYY-MM-DD");
    const salesId = `${reportSalesDate}-${orderType}`;

    const phases = this.state.tickets;
    await asyncForEach(phases, async elem => {
      const phaseId = elem.phaseId;
      const sale = await this.props.firebase.getSaleReportPhase(
        organizationId,
        eventId,
        phaseId,
        salesId
      );
      const data = sale.data();
      const {
        fee,
        cancelled,
        soldCount,
        subtotal,
        cancellations,
        refounds,
        refound
      } = sale.data();

      var newSaleData = {};
      if (status === "CANCELLED") {
        if (statusnow === "REFUND") {
          const newFee = fee - elem.fee;
          newSaleData = {
            refounds: (refounds || 0) - elem.subtotal,
            refound: (refound || 0) - elem.quantity,
            cancellations: (cancellations || 0) + elem.subtotal,
            cancelled: (cancelled || 0) + elem.quantity,
            fee: newFee,
            total: subtotal + newFee
          };
        } else {
          const newSubtotal = subtotal - elem.subtotal;
          const newFee = fee - elem.fee;
          newSaleData = {
            soldCount: soldCount - elem.quantity,
            fee: newFee,
            subtotal: newSubtotal,
            total: newSubtotal + newFee,
            cancellations: (cancellations || 0) + elem.subtotal,
            cancelled: (cancelled || 0) + elem.quantity
          };
        }
      } else if (status === "REFUND") {
        if (statusnow === "CANCELLED") {
          const newFee = fee + elem.fee;
          newSaleData = {
            refounds: (refounds || 0) + elem.subtotal,
            refound: (refound || 0) + elem.quantity,
            cancellations: (cancellations || 0) - elem.subtotal,
            cancelled: (cancelled || 0) - elem.quantity,
            fee: newFee,
            total: subtotal + newFee
          };
        } else {
          const newSubtotal = subtotal - elem.subtotal;
          newSaleData = {
            soldCount: soldCount - elem.quantity,
            subtotal: newSubtotal,
            total: newSubtotal + fee,
            refounds: (refounds || 0) + elem.subtotal,
            refound: (refound || 0) + elem.quantity
          };
        }
      } else {
        if (statusnow === "REFUND") {
          const newSubtotal = subtotal + elem.subtotal;
          newSaleData = {
            soldCount: soldCount + elem.quantity,
            subtotal: newSubtotal,
            total: newSubtotal + fee,
            refounds: (refounds || 0) - elem.subtotal,
            refound: (refound || 0) - elem.quantity
          };
        } else {
          const newSubtotal = subtotal + elem.subtotal;
          const newFee = fee + elem.fee;
          newSaleData = {
            soldCount: soldCount + elem.quantity,
            fee: newFee,
            subtotal: newSubtotal,
            total: newSubtotal + newFee,
            cancellations: (cancellations || 0) - elem.subtotal,
            cancelled: (cancelled || 0) - elem.quantity
          };
        }
      }
      await this.props.firebase.UpdateSaleReportPhase(
        organizationId,
        eventId,
        phaseId,
        salesId,
        newSaleData
      );
    });
  };
  changeSalesProducts = async (status, statusnow) => {
    const { organizationId, eventId } = this.state;
    const orderType = this.state.orders.orderType;
    const reportSalesDate = moment(
      this.state.orders.createdAt.seconds * 1000
    ).format("YYYY-MM-DD");
    const salesId = `${reportSalesDate}-${orderType}`;
    const product = this.state.products;
    await asyncForEach(product, async elem => {
      const productId = elem.productId;
      const sale = await this.props.firebase.getSaleReportProducts(
        organizationId,
        eventId,
        productId,
        salesId
      );
      const {
        fee,
        cancelled,
        soldCount,
        subtotal,
        cancellations,
        refounds,
        refound
      } = sale.data();

      var newSaleData = {};
      if (status === "CANCELLED") {
        if (statusnow === "REFUND") {
          const newFee = fee - elem.fee;
          newSaleData = {
            refounds: (refounds || 0) - elem.subtotal,
            refound: (refound || 0) - elem.quantity,
            cancellations: (cancellations || 0) + elem.subtotal,
            cancelled: (cancelled || 0) + elem.quantity,
            fee: newFee,
            total: subtotal + newFee
          };
        } else {
          const newSubtotal = subtotal - elem.subtotal;
          const newFee = fee - elem.fee;
          newSaleData = {
            soldCount: soldCount - elem.quantity,
            fee: newFee,
            subtotal: newSubtotal,
            total: newSubtotal + newFee,
            cancellations: (cancellations || 0) + elem.subtotal,
            cancelled: (cancelled || 0) + elem.quantity
          };
        }
      } else if (status === "REFUND") {
        if (statusnow === "CANCELLED") {
          const newFee = fee + elem.fee;
          newSaleData = {
            refounds: (refounds || 0) + elem.subtotal,
            refound: (refound || 0) + elem.quantity,
            cancellations: (cancellations || 0) - elem.subtotal,
            cancelled: (cancelled || 0) - elem.quantity,
            fee: newFee,
            total: subtotal + newFee
          };
        } else {
          const newSubtotal = subtotal - elem.subtotal;
          newSaleData = {
            soldCount: soldCount - elem.quantity,
            subtotal: newSubtotal,
            total: newSubtotal + fee,
            refounds: (refounds || 0) + elem.subtotal,
            refound: (refound || 0) + elem.quantity
          };
        }
      } else {
        if (statusnow === "REFUND") {
          const newSubtotal = subtotal + elem.subtotal;
          newSaleData = {
            soldCount: soldCount + elem.quantity,
            subtotal: newSubtotal,
            total: newSubtotal + fee,
            refounds: (refounds || 0) - elem.subtotal,
            refound: (refound || 0) - elem.quantity
          };
        } else {
          const newSubtotal = subtotal + elem.subtotal;
          const newFee = fee + elem.fee;
          newSaleData = {
            soldCount: soldCount + elem.quantity,
            fee: newFee,
            subtotal: newSubtotal,
            total: newSubtotal + newFee,
            cancellations: (cancellations || 0) - elem.subtotal,
            cancelled: (cancelled || 0) - elem.quantity
          };
        }
      }
      await this.props.firebase.UpdateSaleReportProducts(
        organizationId,
        eventId,
        productId,
        salesId,
        newSaleData
      );
    });
  };
  updateOrderUser = async () => {
    const userId = this.state.orders.userId;
    const name = {
      firstName: this.state.firstName,
      lastName: this.state.lastName
    };
    const userData = {
      email: this.state.email,
      name: name
    };
    try {
      await this.props.firebase.updateUser(userData, userId);
    } catch (err) {
      console.log(err);
    }
  };

  sendNotificacion = () => {
    const { organizationId, eventId, orderId } = this.state;

    axios
      .post(
        `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/app/orders/send_notification`,
        {
          orgId: organizationId,
          eventId: eventId,
          orderId: orderId
        }
      )
      .then(res => {});
  };

  handleCheckIn = ticketId => async () => {
    try {
      const userId = this.props.firebase.auth.currentUser.uid;
      const { eventId } = this.props.match.params;
      const { data } = await axios.post(
        `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/app/accesses/${ticketId}/checkin`,
        {
          userId,
          eventId
        }
      );
      if (data.status === "SUCCESS") {
        this.AlertConfirmation("success", "se ha realizado con éxito");
        this.handleGetAccess();
      }
    } catch (error) {
      const { response } = error;
      this.AlertConfirmation("danger", "");
    }
  };

  handleFetchData = async () => {
    const { organizationId, eventId, orderId } = this.state;

    const order = await this.props.firebase.orderDoc(
      organizationId,
      eventId,
      orderId
    );
    const eventDoc = await this.props.firebase
      .eventDoc(organizationId, eventId)
      .get();
    const { name } = eventDoc.data();
    await order.onSnapshot(async orderData => {
      if (orderData.exists) {
        orderData = orderData.data();
        this.setState({
          orderEditable: !["PENDING", "EXPIRED"].includes(orderData.orderStatus)
        });

        if (orderData.couponId != null) {
          const couponId = orderData.couponId;
          const orderCoupons = await this.props.firebase.getCoupon(
            organizationId,
            eventId,
            couponId
          );
          const couponData = orderCoupons.data();
          this.setState({
            coupons: couponData,
            couponsExist: true
          });
        }
        if (orderData.userId != null) {
          const orderUser = await this.props.firebase.getUser(orderData.userId);
          const { firstName, lastName } = orderUser.name;
          this.setState({
            user: orderUser,
            firstName: firstName,
            lastName: lastName,
            email: orderUser.email,
            userExist: true
          });
        }
        const orderTickets = await this.props.firebase.getOrderTicketsStatic(
          organizationId,
          eventId,
          orderId
        );
        const orderProducts = await this.props.firebase.getOrderProductsCollectionStatic(
          organizationId,
          eventId,
          orderId
        );
        const orderCourtesies = await this.props.firebase.getOrderCourtesiesCollection(
          organizationId,
          eventId,
          orderId
        );
        const ticketData = await this.generateList(orderTickets);
        const productData = await this.generateList(orderProducts);
        const courtesiesData = await this.generateList(orderCourtesies);

        orderData.orderStatus !== "EXPIRED" &&
          orderData.orderStatus !== "PENDING" &&
          this.setState({
            changeOrder: true
          });

        orderData.orderStatus !== "EXPIRED" &&
          orderData.orderStatus !== "PENDING" &&
          this.setState({
            changeOrder: true
          });

        const { LogRocketSessionUrl } = orderData;
        this.handleGetAccess();
        this.setState({
          tickets: ticketData,
          products: productData,
          courtesies: courtesiesData,
          orderStatus: orderData.orderStatus,
          orders: orderData,
          nameEvents: name,
          isReady: true,
          LogRocketSessionUrl: LogRocketSessionUrl || "",
          organizationId,
          eventId
        });
      } else {
        this.props.history.push("/orders");
      }
    });
  };
  handleGetAccess = async () => {
    const { orderId } = this.props.match.params;
    const accessesCollection = await this.props.firebase.db
      .collection("accesses")
      .where("orderId", "==", orderId)
      .get();
    const accesses = accessesCollection.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    const accessesGroup = accesses.groupBy("resourceType");
    this.setState({
      ticket: accessesGroup.ticket ? accessesGroup.ticket : [],
      productslist: accessesGroup.purchased_product
        ? accessesGroup.purchased_product
        : []
    });
  };
  componentDidMount() {
    const { organizationId, eventId, orderId } = this.props.match.params;

    this.setState(
      {
        organizationId,
        eventId,
        orderId
      },
      this.handleFetchData
    );

    // this.handleFetchData();
  }
  onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };
  onChangeStatus = e => {
    const value = e.target.value;
    this.setState({ orderStatus: value }, this.changeOrderStatus);
  };
  handleRedirectLogRocket = () => {
    window.open(this.state.orders.LogRocketSessionUrl);
  };
  hangleToggleTab = tab => () => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  };

  toggleEdit = () => {
    this.setState({ editEnabled: !this.state.editEnabled });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  AlertConfirmation = (res, messenger) => {
    let modal = null;
    if (res === "success") {
      modal = (
        <ReactBSAlert success title="Realizado!" onConfirm={this.hideAlert}>
          {messenger}
        </ReactBSAlert>
      );
    } else {
      modal = (
        <ReactBSAlert danger title="Error!" onConfirm={this.hideAlert}>
          {messenger}
        </ReactBSAlert>
      );
    }
    this.setState({
      alert: modal
    });
  };
  handleVoidAccess = async (documentPath, id) => {
    await this.props.firebase.db.doc(documentPath).update({ status: "VOID" });
    this.handleGetAccess();
    this.AlertConfirmation("success", "se ha realizado con éxito");
    setTimeout(() => {
      this.handleGetAccess();
    }, 1000);
  };

  render() {
    const { orderId } = this.props.match.params;
    return this.state.isReady ? (
      <>
        {/* <Button onClick={() => this.AlertConfirmation()}>hola </Button> */}
        <Row className="order-details">
          {this.state.alert}
          <Col xs="auto" md={4}>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/orders">Orders</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{orderId}</BreadcrumbItem>
            </Breadcrumb>
            <Card title="Order Resume">
              <Item title="Order ID" value={orderId} />
              <Item title="Event" value={this.state.nameEvents} />
              <Item
                title="Date"
                value={
                  moment(this.state.orders.createdAt.seconds * 1000)
                    .format("dddd DD MMMM YYYY")
                    .capitalize() +
                  " - " +
                  moment(this.state.orders.createdAt.seconds * 1000).format(
                    "h:mm a"
                  )
                }
              />

              <div className="row">
                <div className="col-2 col-md-2">Status</div>
                <div className="infoItem col-10 col-md-10">
                  {this.state.orderEditable ? (
                    <React.Fragment>
                      <Link
                        to="#"
                        onClick={this.toggleEdit}
                        className={this.state.editEnabled ? "hidden" : ""}
                      >
                        {this.state.orderStatus}
                        <span>(edit)</span>
                      </Link>
                      <span className={this.state.editEnabled ? "" : "hidden"}>
                        <FormGroup>
                          <select
                            className="form-control"
                            value={this.state.orderStatus}
                            onChange={this.onChangeStatus}
                          >
                            <option value="PAID">PAID</option>REFUND
                            <option value="REFUND">REFUND</option>
                            <option value="CANCELLED">CANCELLED</option>
                          </select>
                        </FormGroup>
                        <Link to="#" onClick={this.toggleEdit}>
                          ...cancel
                        </Link>
                      </span>
                    </React.Fragment>
                  ) : (
                    <span>{this.state.orderStatus}</span>
                  )}
                </div>
              </div>
              {/* <Item title="Status" value={this.state.orderStatus} /> */}

              <Item
                title="Total"
                value={`$ ${centsToDecimal(this.state.orders.total).toFixed(
                  2
                )}`}
              />
            </Card>
            <Button
              className="mt-3 btnSendTickets"
              color="primary"
              size="lg"
              block
              onClick={this.sendNotificacion}
              disabled={!this.state.email}
            >
              Send Tickets
            </Button>
          </Col>
          <Col xs="auto" md={8}>
            <Nav tabs className="nav-pills nav-justified">
              <NavItem
                onChangeTab={this.hangleToggleTab("Details")}
                active={this.state.activeTab}
                title="Details"
              />
              <NavItem
                onChangeTab={this.hangleToggleTab("Details")}
                title="Charges"
              />
              <NavItem
                onChangeTab={this.hangleToggleTab("Details")}
                title="Refunds"
              />
              <NavItem
                onChangeTab={this.hangleToggleTab("Tickets")}
                active={this.state.activeTab}
                title="Accesses"
              />
              <NavItem
                onChangeTab={this.hangleToggleTab("Products")}
                active={this.state.activeTab}
                disabled={this.state.productslist.length > 0 ? false : true}
                title="Products"
              />
              <NavItem
                onChangeTab={this.handleRedirectLogRocket}
                title="LogRocket"
              />
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <br></br>
              <TabPane tabId="Details">
                <Card title="Client">
                  <Item
                    title="Full Name"
                    value={`${this.state.firstName} ${this.state.lastName}`}
                    name={{
                      firstname: this.state.firstName,
                      lastName: this.state.lastName
                    }}
                    type="name"
                    editable
                    onSubmit={this.updateOrderUser}
                    onChange={this.onChange}
                  />
                  <Item
                    title="Email Address"
                    editable
                    type="email"
                    value={this.state.email}
                    onSubmit={this.updateOrderUser}
                    onChange={this.onChange}
                  />
                  <Item title="Phone Number" value={this.state.user.phone} />
                </Card>
                <br />
                {this.state.tickets.map((ticket, i) => {
                  return <Event key={i} ticket={ticket} />;
                })}
                {this.state.products.map((product, i) => {
                  return <Product key={i} product={product} />;
                })}
                {this.state.courtesies.map((courtesy, i) => {
                  return <Courtesy key={i} courtesy={courtesy} />;
                })}
                <Card title="Transaction Total">
                  <Item
                    title="Donation"
                    value={`$ ${centsToDecimal(
                      this.state.orders.donation
                    ).toFixed(2)}`}
                  />
                  <Item
                    title="Discount"
                    value={`$${centsToDecimal(
                      this.state.orders.discount
                    ).toFixed(2)}`}
                  />
                  <Item
                    title="Fee"
                    value={`$${centsToDecimal(this.state.orders.fee).toFixed(
                      2
                    )}`}
                  />
                  <Item
                    title="Subtotal"
                    value={`$${centsToDecimal(
                      this.state.orders.subtotal
                    ).toFixed(2)}`}
                  />
                  <Item
                    title="Total"
                    value={`$${centsToDecimal(this.state.orders.total).toFixed(
                      2
                    )}`}
                  />
                </Card>
              </TabPane>
              <TabPane tabId="Tickets">
                {this.state.ticket.map((ticket, i) => (
                  <React.Fragment key={i}>
                    <Card
                      title={ticket.id}
                      checkin={true}
                      id={ticket.id}
                      handleCheckIn={this.handleCheckIn}
                      handleVoid={this.handleVoidAccess}
                      documentPath={ticket.documentPath}
                    >
                      <Item title="Ticket Type" value={ticket.ticketTypeName} />
                      <Item title="Phase" value={ticket.phaseName} />
                      <Item title="Status" value={ticket.status} />
                    </Card>
                    <br />
                  </React.Fragment>
                ))}
              </TabPane>
              <TabPane tabId="Products">
                {this.state.productslist.map((products, i) => {
                  return (
                    <ListProducts
                      key={i}
                      product={products}
                      handleCheckIn={this.handleCheckIn}
                      handleVoid={this.handleVoidAccess}
                    />
                  );
                })}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </>
    ) : (
      <p>loading</p>
    );
  }
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(ordersDetails);
