import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Col, Container, Row, Input, Button } from "reactstrap";

class Home extends React.Component {
  render() {
    return (
      <Container>
        <div className="row">
          <Row>
            <Col md="6">
              <h5>Total Sales</h5>
              <h1>$ 0.00</h1>
            </Col>
            <Col md="6" className="text-right">
              <h5>Total Sales</h5>
              <h1>$ 0.00</h1>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
export default connect((state) => ({
  currentuser: state.currentUser.id,
  currentorganization: state.currentorganization
}))(Home);
