import { createStore, compose } from "redux";
import rootReducer from "../reducers";
import { isChrome } from "react-device-detect";


export const store = createStore(
  rootReducer,
  process.env.NODE_ENV === "development" && isChrome
    ? compose(
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : compose()
);
