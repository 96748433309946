import React, { Component } from "react";
import Select from "react-select";
import { withFirebase } from "../Firebase";
import OrganizationSelect from "./OrganizationSelect";
import { connect } from "react-redux";
import { compose } from "recompose";
import permissions from "../../helpers/permissions";
import { roleNames, roleLabel } from "../../helpers/roles";
import { Row, Col, FormGroup, Label } from "reactstrap";

class UserRoles extends Component {
  state = {
    Events: [],
    Options: [],
    Organizations: [],
    Role: "",
    Organization: "",
    availableRoles: []
  };

  handleChange = value => {
    this.setState({ Events: value }, () => {
      this.handleSubmitRole();
    });
  };

  handleGetOrganizations = async () => {
    const organizations = await this.props.firebase.getOrganizations();
    const OrganizationsCollection = await organizations.get();
    const Organizations = [];
    if (permissions.adminPresent()) {
      OrganizationsCollection.docs.map(Organization => {
        let element = Organization.data();
        element.id = Organization.id;
        Organizations.push(element);
      });
    } else {
      const UserOrganizations = await this.handleGetUserOrganizations();
      UserOrganizations.forEach(userOrganization => {
        OrganizationsCollection.docs.map(Organization => {
          if (userOrganization === Organization.id) {
            let element = Organization.data();
            element.id = Organization.id;
            Organizations.push(element);
          }
        });
      });
    }
    this.setState({ Organizations });
  };

  handleSetOrganization = async e => {
    const { value } = e.target;
    const user = await this.props.firebase.getUserData();
    if (
      permissions.adminPresent() ||
      permissions.hasRole("organization_admin")
    ) {
      const events = this.props.firebase.organizationEventsCollection(value);
      const EventsCollection = await events.get();
      let Events = [];
      EventsCollection.docs.forEach(element => {
        let Event = {};
        const { name } = element.data();
        Event.label = name;
        Event.value = element.id;
        Events.push(Event);
      });
      this.setState({ Options: Events, Events: [], Organization: value });
    } else {
      const events = await this.props.firebase
        .organizationEventsCollection(value)
        .where("auth_user_organization_admin_ids", "array-contains", user.id);
      const EventsCollection = await events.get();

      let Events = [];
      EventsCollection.docs.forEach(element => {
        let Event = {};
        const { name } = element.data();
        Event.label = name;
        Event.value = element.id;
        Events.push(Event);
      });
      this.setState({ Options: Events, Events: [], Organization: value });
    }
    this.state.Role === "organization_admin" && this.handleSubmitRole();
  };
  handleSetRole = ({ target }) => {
    const { value } = target;
    this.state.Role !== "organization_admin"
      ? this.setState({ Role: value, Events: [], Organization: "" })
      : this.setState({ Role: value, Organization: "" });
  };
  handleGetUserOrganizations = async () => {
    const UserData = await this.props.firebase.getUserData();
    return UserData.data().roles.map(role => role.organization);
  };
  componentDidMount() {
    this.handleGetOrganizations();
  }
  handleSubmitRole = () => {
    const { Events, Role, Organization } = this.state;
    const { id } = this.props;
    const data = {
      Events,
      Role,
      Organization,
      id
    };
    this.props.setRoles(data);
  };

  availableRolesForCurrentUser = () => {
    let result = [];
    if (permissions.adminPresent()) {
      result = roleNames.admin;
    } else if (permissions.hasRole("organization_admin")) {
      result = roleNames.organization_admin;
    } else if (permissions.hasRole("organizer")) {
      result = roleNames.organizer;
    }
    return result;
  };
  render() {
    const { disabled } = this.props;

    return (
      <>
        <Row>
          <Col md="4">
            <Label>Role</Label>
            <select
              className="form-control"
              name="permission"
              onChange={this.handleSetRole}
              disabled={disabled}
            >
              <option>Select a role</option>
              {this.availableRolesForCurrentUser().map((roleName, i) => (
                <option key={i} value={roleName}>
                  {roleLabel(roleName)}
                </option>
              ))}
            </select>
          </Col>
          <Col md="4">
            <Label>Organization</Label>
            <OrganizationSelect
              handleSetOrganization={this.handleSetOrganization}
              Organizations={this.state.Organizations}
              Organization={this.state.Organization}
              disabled={disabled}
            />
          </Col>
          {this.state.Role !== "organization_admin" && (
            <Col md="4">
              <label>Events</label>
              <Select
                isMulti
                value={this.state.Events}
                name="Events"
                options={this.state.Options}
                onChange={this.handleChange}
                className="basic-multi-select"
                classNamePrefix="select"
                isDisabled={disabled}
              />
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export default compose(
  withFirebase,
  connect(state => ({
    currentUser: state.currentUser
  }))
)(UserRoles);
