import React from "react";
import { Input, Button } from "reactstrap";
import { Link } from "react-router-dom";

import "moment-timezone";

import { withFirebase } from "../../components/Firebase";
import { connect } from "react-redux";
import { centsToDecimal } from "../../helpers/currency";
import EventsContainer from "../../components/EventsContainer";
import {
  Modal,
  FormGroup,
  Label,
  Progress,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { compose } from "recompose";
import permissions from "../../helpers/permissions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";

import TableHead from "../../components/Admin/TableHead";
import dotsIcon from "../../images/icons/dots.svg";
import "./index.scss";

class PhasesList extends React.Component {
  state = {
    phases: [],
    loading: true,
    alert: null,
    batchSize: 1,
    maxBatchSize: 0,
    eventName: "",
    eventId: ""
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "batchSize")
      this.setState({ [e.target.name]: parseInt(e.target.value) });
  };

  normalizePhaseDoc = doc => {
    const docData = doc.data();

    let normalizedDoc = {
      id: doc.id,
      name: docData.name,
      description: docData.description,
      startsAt: new Date(),
      endsAt: new Date(),
      quantity: docData.quantity,
      soldCount: docData.soldCount,
      fee: docData.fee,
      price: docData.price,
      boxOfficePrice: docData.boxOfficePrice,

      feeDecimal: centsToDecimal(docData.fee),
      priceDecimal: centsToDecimal(docData.price),
      boxOfficePriceDecimal: centsToDecimal(docData.boxOfficePrice),
      ticketTypeId: docData.ticketTypeId,
      eventId: this.props.match.params.eventId,
      ticketTypePhaseStatus: "ACTIVE"
    };
    return normalizedDoc;
  };

  async componentDidMount() {
    const uid = this.props.currentUser.uid;
    permissions.getRolesPermissions(uid).then(async () => {});

    const { organizationId, eventId } = this.props.match.params;

    var self = this;

    var event = await this.props.firebase.getEvent(organizationId, eventId);

    this.setState({
      organizationId: organizationId,
      event: event.data(),
      eventId
    });

    var phasesCollection = await this.props.firebase.phasesCollection(
      organizationId,
      eventId
    );

    phasesCollection.onSnapshot(function(querySnapshot) {
      var phases = [];

      querySnapshot.forEach(function(doc) {
        console.log(doc);
        phases.push(self.normalizePhaseDoc(doc));
      });
      self.setState({ phases });
      // }
    });
  }

  destroy = async (orgId, eventId, id) => {
    try {
      this.props.firebase.deletePhase(orgId, eventId, id).then(record => {
        const phases = [
          ...this.state.phases.filter(r => {
            return r.id !== id;
          })
        ];
        this.setState({ phases });
        this.hideAlert();
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  cancelDestroy() {
    this.hideAlert();
  }

  confirmDestroy = (orgId, eventId, id) => {
    this.setState({
      alert: (
        <DestroyConfirmation
          onConfirm={() => this.destroy(orgId, eventId, id)}
          onCancel={() => this.cancelDestroy()}
        />
      )
    });
  };

  toggleBatchModal = () => {
    this.setState({
      batchModal: !this.state.batchModal
    });
  };

  prepareBatchForPhase(phase) {
    this.setState({
      maxBatchSize: phase.quantity - phase.soldCount,
      selectedPhase: phase
    });
    this.toggleBatchModal();
  }

  onDragEnd = async result => {
    const { eventId, organizationId } = this.props.match.params;
    const { draggableId, destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newSortOrder = Array.from(this.state.phases);

    newSortOrder.splice(source.index, 1);
    newSortOrder.splice(destination.index, 0, {
      ...this.state.phases[source.index],
      id: draggableId
    });

    newSortOrder.map(async (phase, index) => {
      await this.props.firebase.db
        .collection("organizations")
        .doc(organizationId)
        .collection("events")
        .doc(eventId)
        .collection("phases")
        .doc(phase.id)
        .update({ sortOrder: index });
    });

    this.setState({ phases: newSortOrder });
  };

  render() {
    const { eventId, organizationId } = this.props.match.params;
    return (
      <EventsContainer>
        {this.state.alert}

        <div className="section-header">
          <div className="text-right table-actions pull-right">
            {permissions.can("phases", "create") && (
              <Link
                to={`/${organizationId}/events/${eventId}/phases/new`}
                className="btn btn-primary"
              >
                New Phase
              </Link>
            )}
          </div>
        </div>

        <br />

        <Table className="responsive" role="table">
          <TableHead cols={["Phase name", "Availability", ""]} />
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="phasesDroppable">
              {provided => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {this.state.phases.map((record, i) => (
                    <Draggable
                      style={{ display: "table", width: "100%" }}
                      draggableId={record.id}
                      index={i}
                      key={i}
                    >
                      {(provided, snapshot) => (
                        <tr
                          role="row"
                          className="phases-index-row"
                          style={{ width: "100%" }}
                          key={record.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <td
                            style={{
                              width: "25%",
                              background: snapshot.isDragging
                                ? "lightgreen"
                                : "white"
                            }}
                          >
                            {record.name}
                          </td>
                          <td
                            style={{
                              width: "40%",
                              background: snapshot.isDragging
                                ? "lightgreen"
                                : "white"
                            }}
                          >
                            <strong>
                              {record.quantity - record.soldCount} available{" "}
                              {record.quantity - record.soldCount}/
                              {record.quantity}
                            </strong>
                          </td>

                          <td
                            className="text-right full-width"
                            style={{
                              background: snapshot.isDragging
                                ? "lightgreen"
                                : "white"
                            }}
                          >
                            <UncontrolledDropdown
                              direction="left"
                              className="row-options-trigger"
                            >
                              <DropdownToggle tag="span">
                                <img src={dotsIcon} alt="Options" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {permissions.can("phases", "edit") && (
                                  <DropdownItem
                                    tag={Link}
                                    to={`/${organizationId}/events/${eventId}/phases/${record.id}/edit`}
                                  >
                                    Edit
                                  </DropdownItem>
                                )}
                                <DropdownItem divider />
                                {permissions.can("phases", "delete") && (
                                  <DropdownItem
                                    onClick={() =>
                                      this.confirmDestroy(
                                        organizationId,
                                        eventId,
                                        record.id
                                      )
                                    }
                                    className="text-danger"
                                  >
                                    Destroy
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>

        {/* small modal */}
        <Modal
          modalClassName="modal-primary"
          isOpen={this.state.batchModal}
          toggle={this.toggleBatchModal}
        >
          <div className="modal-header justify-content-center">
            Generate tickets in batch
          </div>
          <div className="modal-body">
            <FormGroup>
              <Label for="eventEndAt">Batch size:</Label>
              <Input
                type="number"
                name="batchSize"
                value={this.state.batchSize}
                onChange={this.onChange}
                max={this.maxBatchSize}
                id="batchSize"
              />
            </FormGroup>

            <Progress
              max="100"
              value={this.state.progress}
              barClassName="progress-bar-danger"
            />
          </div>

          <div className="modal-footer">
            <div className="left-side">
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={this.toggleBatchModal}
              >
                Cancel
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                color="primary"
                data-dismiss="modal"
                type="button"
                onClick={this.generateBatch}
              >
                Generar batch »
              </Button>
            </div>
          </div>
        </Modal>
        {/* end small modal */}
      </EventsContainer>
    );
  }
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(PhasesList);
