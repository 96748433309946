import React from "react";
import { Card } from "./Card";
import { Item } from "./Item";
export function Courtesy({ courtesy }) {
  return (
    <>
      <Card title="Courtesy">
        <Item title="Courtesy Name" value={courtesy.courtesyName} />
        <Item title="Code" value={courtesy.courtesyCode} />
        <Item title="Quantity" value={courtesy.quantity || 0} />
      </Card>
      <br />
    </>
  );
}
