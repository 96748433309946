import { globalConstants } from "../constants";

export function global(state = { title: "" }, action) {
  switch (action.type) {
    case globalConstants.SET_TITLE:
      return { title: action.title };
    default:
      return state;
  }
}
