export function getEventRefs(Roles) {
  return Roles.map(role => {
    const info = {};
    info.role = role.Role;
    info.organization = role.Organization;
    info.eventRefs = role.Events.map(event => {
      return `/organizations/${role.Organization}/events/${event.value}`;
    });
    return info;
  });
}

export const roleNames = {
  admin: [
    "box_office",
    "check_in",
    "organization_admin",
    "organizer",
    "seller",
    "teller",
    "analyst"
  ],
  organization_admin: [
    "box_office",
    "check_in",
    "organizer",
    "seller",
    "teller",
    "analyst"
  ],
  organizer: ["box_office", "check_in", "seller", "teller", "analyst"]
};

export function roleLabel(roleName) {
  return {
    box_office: "Box office",
    check_in: "Check-in staff",
    organization_admin: "Organization admin",
    organizer: "Organizer",
    seller: "Seller",
    teller: "Teller",
    analyst: "Analyst"
  }[roleName];
}
