import React from "react";
import { Switch, Route } from "react-router-dom";

import Organizations from "../../../../pages/organizations/index.jsx";
import OrganizationForm from "../../../../pages/organizations/form.jsx";
import Events from "../../../../pages/events/";

import PrivateRoute from "../../../../components/PrivateRoute";

export default function Application() {
  return (
    <Switch>
      <PrivateRoute
        path="/organizations"
        exact
        component={Organizations}
      />
      <PrivateRoute
        exact
        path="/organizations/new"
        component={OrganizationForm}
      />
      <PrivateRoute
        exact
        path="/organizations/:organizationId/edit"
        component={OrganizationForm}
      />
      <PrivateRoute
        exact
        path="/organizations/:organizationId/events"
        component={Events}
      />
    </Switch>
  );
}
