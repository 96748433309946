import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";

import { Table, Breadcrumb, BreadcrumbItem } from "reactstrap";

import { withFirebase } from "../../components/Firebase";

import permissions from "../../helpers/permissions";

import { asyncForEach } from "../../helpers/utils";

import Event from "./_event";
import DestroyConfirmation from "../../components/Admin/DestroyConfirmation";
import TableHead from "../../components/Admin/TableHead";

import "./index.scss";

class Events extends React.Component {
  state = {
    events: [],
    nextToken: null,
    loading: true,
    alert: null
  };

  async componentDidMount() {
    const uid = this.props.currentUser.uid;
    let self = this;

    this.setState({ events: [] });

    const organizationsCollection = await this.props.firebase.db
      .collection("organizations")
      .get();
    let organizations = [];
    organizationsCollection.docs.map(org => {
      organizations[org.id] = org.data().name;
    });
    this.setState({ organizations });

    await permissions.getRolesPermissions(uid);

    if (permissions.adminPresent()) {
      const eventsReferences = this.props.firebase.db
        .collectionGroup("events")
        .orderBy("startsAt", "desc");

      eventsReferences.onSnapshot(function(querySnapshot) {
        var events = [];
        querySnapshot.forEach(function(doc) {
          events.push({ id: doc.id, ...doc.data() });
        });
        self.setState({ events });
      });
    } else {
      let administrableOrganizations = [];
      permissions.roles.forEach(role => {
        if (role.role === "organization_admin") {
          administrableOrganizations.push(role.organization);
        }
      });

      const refreshEvents = async () => {
        var events = [];
        var starting = true;
        await asyncForEach(administrableOrganizations, (orgId, index) => {
          const eventReferences = this.props.firebase.db
            .collectionGroup("events")
            .where("organizationId", "==", orgId);

          eventReferences.onSnapshot(function(querySnapshot) {
            if (!starting) {
              refreshEvents();
            }
            querySnapshot.forEach(function(doc) {
              events.push({ id: doc.id, ...doc.data() });
            });
            self.setState({ events });
            if (index + 1 === administrableOrganizations.length) {
              starting = false;
            }
          });
        });

        const organizerEventReferences = await this.props.firebase.getEventsReferenceOrganizer(
          uid
        );

        organizerEventReferences.onSnapshot(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            events.push({ id: doc.id, ...doc.data() });
          });
          self.setState({ events });
        });

        self.setState({ events: events.sort((a, b) => a.startsAt._seconds - b.startsAt._seconds) });
      };
      refreshEvents();
    }
  }

  destroy = async (organizationId, id) => {
    console.log(id);
    var eventsCollection = await this.props.firebase.eventsCollection(
      organizationId
    );
    eventsCollection
      .doc(id)
      .delete()
      .then(record => {
        const events = [
          ...this.state.events.filter(r => {
            return r.id !== id;
          })
        ];
        this.setState({ events });
        this.hideAlert();
      });
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  cancelDestroy() {
    this.hideAlert();
  }

  confirmDestroy = (organizationId, eventId) => {
    this.setState({
      alert: (
        <DestroyConfirmation
          onConfirm={() => this.destroy(organizationId, eventId)}
          onCancel={() => this.cancelDestroy()}
        />
      )
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.alert}
        <div className="section-header">
          <div className="text-right table-actions pull-right">
            {permissions.can("events", "create") && (
              <Link to={`/events/new`} className="btn btn-primary">
                Create Event
              </Link>
            )}
          </div>

          <Breadcrumb>
            <BreadcrumbItem active>Events</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <Table className="responsive" role="table">
          <TableHead
            cols={["Organization", "Event name", "Starts/Ends", "Status", ""]}
          />
          <tbody>
            {this.state.events.map(record => (
              <Event
                key={record.id}
                record={record}
                confirmDestroy={this.confirmDestroy}
                organizationName={
                  this.state.organizations[record.organizationId]
                }
              />
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

export default compose(
  withFirebase,
  connect(state => ({ currentUser: state.currentUser }))
)(Events);
