import React, { useState, useEffect } from "react";
import { withFirebase } from "../../components/Firebase";

import "moment-timezone";

import { Row, Col } from "reactstrap";

import {
  Form,
  FormGroup,
  Input,
  Label,
  FormActions,
  FormFields,
  FormFeedback,
  SelectFromCollection,
  CheckBox,
  DateTimeInput
} from "../../components/Formica";

import EventsContainer from "../../components/EventsContainer";
import { organization_event_courtesies_path } from "../../helpers/paths";
import {
  generateShortCode,
  calculateFirebaseBatchPages
} from "../../helpers/utils";

import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .required()
    .label("Name"),
  courtesyTicketTypeId: yup
    .string()
    .required()
    .label("Ticket type"),
  code: yup
    .string()
    .required()
    .label("Code"),
  quantity: yup
    .number()
    .required()
    .min(1)
    .label("Quantity"),
  startsAt: yup
    .date()
    .required()
    .label("Start At"),
  endsAt: yup
    .date()
    .when("startsAt", (startsAt, schema) => {
      const validStartsAt = startsAt instanceof Date && !isNaN(startsAt);
      if (!validStartsAt) return;
      return startsAt && schema.min(startsAt);
    })
    .label("End At")
    .required(),
  batchSize: yup
    .number()
    .min(1)
    .when("canGenerateBatch", {
      is: true,
      otherwise: s => s.min(0)
    })
    .required()
    .when("canGenerateBatch", {
      is: true,
      otherwise: s => s.notRequired()
    })
});

const handleFormatData = values => {
  return {
    code: values.code,
    courtesyTicketTypeId: values.courtesyTicketTypeId,
    description: values.description,
    endsAt: values.endsAt,
    name: values.name,
    quantity: values.quantity,
    startsAt: values.startsAt,
    status: values.status,
    redeemedTickets: values.redeemedTickets,
    onHold: values.onHold
  };
};

function CourtesyForm(props) {
  const { organizationId, eventId, courtesyId } = props.match.params;
  const [courtesyTicketTypes, setCourtesyTicketTypes] = useState([]);
  // const [canGenerateBatch, setCanGenerateBatch] = useState(false);
  // const [batchSize, setBatchSize] = useState(0);
  const initialValues = {
    code: generateShortCode(),
    courtesyTicketTypeId: "",
    description: "",
    endsAt: new Date(),
    eventId: eventId,
    generateBatch: false,
    isRoot: true,
    loading: false,
    name: "",
    quantity: 1,
    startsAt: new Date(),
    status: "AVAILABLE",
    onHold: 0,
    redeemedTickets: 0,
    canGenerateBatch: false,
    batchSize: 0
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    setValues
  } = useFormik({
    initialValues,
    onSubmit: async values => {
      let courtesyData = handleFormatData(values);
      const courtesyRef = props.firebase.courtesiesCollection(
        organizationId,
        eventId
      );
      if (courtesyId) {
        await courtesyRef.doc(courtesyId).update(courtesyData);
      } else if (!courtesyId && values.canGenerateBatch && values.batchSize > 0) {
        const courtesyDoc = await courtesyRef.add(courtesyData);
        await generateBatch(courtesyDoc.id, courtesyData);
      } else {
        courtesyData.isRoot = values.isRoot;
        await courtesyRef.add(courtesyData);
      }
      props.history.push(`/${organizationId}/events/${eventId}/courtesies`);
    },
    validationSchema
  });

  const getCourtesyTicketTypes = async () => {
    const courtesyTicketTypesCollection = await props.firebase
      .courtesyTicketTypesCollection(organizationId, eventId)
      .get();

    setCourtesyTicketTypes(
      courtesyTicketTypesCollection.docs.map(ctt => ({
        id: ctt.id,
        ...ctt.data()
      }))
    );
  };

  const loadCourtesy = async () => {
    const courtesyDoc = await props.firebase.getCourtesy(
      organizationId,
      eventId,
      courtesyId
    );

    const {
      code,
      courtesyTicketTypeId,
      description,
      endsAt,
      name,
      quantity,
      startsAt,
      status,
      redeemedTickets,
      onHold
    } = courtesyDoc.data();

    setValues({
      code,
      courtesyTicketTypeId,
      description,
      endsAt: endsAt.toDate(),
      name,
      quantity,
      startsAt: startsAt.toDate(),
      status,
      redeemedTickets: redeemedTickets || 0,
      onHold: onHold || 0
    });
  };

  useEffect(() => {
    getCourtesyTicketTypes();

    if (courtesyId) loadCourtesy();
  }, []);

  const generateBatch = (parentId, courtesy) => {
    let courtesyData = courtesy;
    delete courtesyData.isRoot;
    courtesyData.parentId = parentId;
    let batch = props.firebase.db.batch();
    const batchPages = calculateFirebaseBatchPages(values.batchSize);
    return new Promise(async (resolve, reject) => {
      batchPages.forEach(pageSize => {
        for (let i = 0; i < pageSize; i++) {
          let courtesyRef = props.firebase
            .courtesiesCollection(organizationId, eventId)
            .doc();
          courtesyData.code = generateShortCode();
          batch.set(courtesyRef, courtesyData);
        }
      });
      await batch.commit();
      resolve("Ok");
    });
  };

  return (
    <EventsContainer>
      <Form className="form-horizontal form-compact" onSubmit={handleSubmit}>
        <FormFields>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="courtesyTicketTypeId">Courtesy ticket type</Label>
                <SelectFromCollection
                  name="courtesyTicketTypeId"
                  value={values.courtesyTicketTypeId}
                  onChange={handleChange}
                  placeholder="Form"
                  collection={courtesyTicketTypes}
                  optionLabel="name"
                  optionValue="id"
                  invalid={errors.courtesyTicketTypeId && true}
                  includeBlank
                />
                <FormFeedback>{errors.courtesyTicketTypeId}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="couponName">Name</Label>
                <Input
                  type="text"
                  name="name"
                  value={values.name}
                  className="form-control input-lg"
                  id="couponName"
                  onChange={handleChange}
                  invalid={errors.name && true}
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              rows="5"
              name="description"
              value={values.description}
              id="description"
              onChange={handleChange}
              invalid={errors.description && true}
              placeholder="Courtesy description"
            />
            <FormFeedback>{errors.description}</FormFeedback>
          </FormGroup>

          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="code">Code</Label>
                <Input
                  type="text"
                  name="code"
                  value={values.code}
                  className="form-control text-danger"
                  id="code"
                  onChange={handleChange}
                  invalid={errors.code && true}
                />
                <FormFeedback>{errors.code}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="number"
                  name="quantity"
                  value={values.quantity}
                  className="form-control"
                  id="quantity"
                  onChange={handleChange}
                  invalid={errors.quantity && true}
                />
                <FormFeedback>{errors.quantity}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="minTickets">Redeemed tickets</Label>
                <Input
                  type="number"
                  name="redeemedTickets"
                  disabled
                  value={values.redeemedTickets}
                  className="form-control"
                  id="redeemedTickets"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="expiresAt">Starts at</Label>
                <br />
                <DateTimeInput
                  selected={values.startsAt}
                  onChange={date => setFieldValue("startsAt", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  invalid={errors.startsAt && true}
                />
                <FormFeedback>{errors.startsAt}</FormFeedback>

                {/* <Input
                  type="datetime-local"
                  name="startsAt"
                  value={
                    moment(values.startsAt).format("YYYY-MM-DDTkk:mm:ss") || ""
                  }
                  onChange={handleChange}
                  id="phaseStartsAt"
                  invalid={errors.startsAt && touched.startsAt}
                  onBlur={handleBlur}
                />
                <FormFeedback>{errors.startsAt}</FormFeedback> */}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="expiresAt">ends at</Label>
                <br />
                <DateTimeInput
                  selected={values.endsAt}
                  onChange={date => setFieldValue("endsAt", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  invalid={errors.endsAt && true}
                />
                <FormFeedback>{errors.endsAt}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          {!courtesyId && (
            <section className="form-section with-border-top">
              <CheckBox
                name="generateBatch"
                onChange={() => setFieldValue("canGenerateBatch", !values.canGenerateBatch)}
                checked={values.canGenerateBatch}
                label="Generate batch?"
              />
              {values.canGenerateBatch && (
                <FormGroup>
                  <Label for="batchSize">Batch size</Label>
                  <Input
                    type="number"
                    name="batchSize"
                    value={values.batchSize}
                    className="form-control input-danger"
                    id="batchSize"
                    onChange={handleChange}
                    invalid={errors.batchSize && true}
                  />
                  <small className="form-text text-muted">
                    Specify how many courtesy codes you want to generate.
                  </small>
                  <FormFeedback>{errors.batchSize}</FormFeedback>
                </FormGroup>
              )}
            </section>
          )}
        </FormFields>

        <FormActions
          cancelPath={organization_event_courtesies_path(
            organizationId,
            eventId
          )}
          submitCaption="Save"
        />
      </Form>
    </EventsContainer>
  );
}
export default withFirebase(CourtesyForm);
